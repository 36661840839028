import { Button, ButtonGroup, Grid, TextField } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { hidePageSectionLoader, showPageSectionLoader } from '../../redux/actions';
import emptyValidationCheck from '../../utility/emptyValidationCheck';
import responseErrorManage from '../../utility/responseErrorManage';
import MuiSectionLoader from '../../utilityComponents/MuiSectionLoader/MuiSectionLoader';


const UserProfile = () => {
    const dispatch = useDispatch()
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const permission = useSelector(state => state.authUserPermission);
    const userInfo = useSelector(state => state.loggedinUserInfo);

    let managerInfo = {};


    // get manager info from property id .
    const getManagerInfoFromPropertyId = () => {

        if (permission === "admin") {


        }
        else {
            managerInfo = userInfo;

        }
        setName(managerInfo.name);
        setEmail(managerInfo.email);

    }


    useEffect(() => {
        getManagerInfoFromPropertyId()
    }, [permission])
    // get manager info from property id .

    // update user info 
    const updateUserInfo = () => {


        // validation blocks
        let isValidationFailed = false;
        isValidationFailed = emptyValidationCheck(name, "XYZ", isValidationFailed)
        isValidationFailed = emptyValidationCheck(email, "Manager Email", isValidationFailed)
        if (isValidationFailed) { return "" };
        // validation blocks

        let formData = {
            "name": name,
            "email": email,
        }
        
        if (permission === "admin") {
            formData = {"password": password}
        }

        dispatch(showPageSectionLoader("Updating manger data"))
        axios
            .put(`users/${userInfo.id}`, formData)
            .then(res => {
                dispatch(hidePageSectionLoader())

                console.log("test_userinfo_update_1", res.data);


                if (permission === "admin") {


                }
                else {
                    // now change loacl data . 
                    let tempUserInfo = userInfo;
                    tempUserInfo.name = name;
                    tempUserInfo.email = email;
                    console.log("tempUserInfo", tempUserInfo)
                    dispatch({
                        type: "loggedinUserInfo_update",
                        payload: {}
                    })
                    dispatch({
                        type: "loggedinUserInfo_update",
                        payload: tempUserInfo
                    })
                     // now change loacl data . 


                }

               
                toast.success("Manager Info updated successfully.")

            })
            .catch(errors => {
                alert('error.')
                responseErrorManage(errors)
            })
            .finally(f => {
                dispatch(hidePageSectionLoader())
            })
    }

    // update user info 

    return (
        <MuiSectionLoader>
            <div className='property-settings'>
                <Box className='edit-box'>
                    <Grid container>
                        <Grid item
                            xs={12}
                            md={12}>
                            <h2 className="edit-box-title">
                                Update Manager Info
                            </h2>
                        </Grid>
                        <Grid item
                            xs={12}
                            md={4}>
                            <div style={{ width: "100%", marginTop: '20px' }}>
                                <Grid container sx={{
                                    alignItems: 'flex-end'
                                }}>
                                    <Grid item xs={12} md={12}>
                                        <p style={{ margin: '0' }}><span style={{ color: '#3899ec', fontSize: '20px' }}>*</span> Manager Name</p>
                                        <TextField
                                            size="small"
                                            id="outlined-helperText"
                                            label=""
                                            defaultValue="Vila Lako"
                                            name='name'
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            sx={{
                                                width: '95%'
                                            }}
                                            className="full-width-input-field"
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <p style={{ margin: '0' }}>Manager Email</p>
                                        <TextField
                                            size="small"
                                            id="outlined-helperText"
                                            label=""
                                            name='email'
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            type="email"
                                            sx={{
                                                width: '95%'
                                            }}
                                            className="settings-phone-number full-width-input-field"
                                            required
                                        />
                                    </Grid>
                                </Grid>
                                {permission === "admin" ? <Grid container sx={{
                                    alignItems: 'flex-end'
                                }}>
                                    <Grid item xs={12} md={12}>
                                        <p style={{ margin: '0' }}><span style={{ color: '#3899ec', fontSize: '20px' }}>*</span> Password</p>
                                        <TextField
                                            size="small"
                                            id="outlined-helperText"
                                            label=""
                                            name='password'
                                            value={password}
                                            onChange={(e)=>setPassword(e.target.value)}
                                            type="password"
                                            sx={{
                                                width: '95%'
                                            }}
                                            className="full-width-input-field"
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>

                                    </Grid>
                                </Grid>
                                : <></>}
                            </div>
                        </Grid>
                    </Grid>
                </Box>
                <div>
                    <Grid container className="edit-room-bottom">
                        <Grid xs={12} sm={6} item><p><span style={{ color: '#3899ec' }}>*</span><small> Indicates mandatory fields</small></p></Grid>
                        <Grid className='edit-room-bottom-btns' xs={12} sm={6} item sx={{ textAlign: 'end' }}>
                            <ButtonGroup
                                orientation="vertical"
                                aria-label="vertical outlined button group"
                            >
                                <Button
                                    className='room-typy-topbar-btn preview-btn'
                                    sx={{
                                        borderRadius: '20px',
                                        backgroundColor: '#fff',
                                        border: "none",
                                        padding: '6px 28px',
                                        textTransform: 'capitalize',
                                        fontSize: '16px',
                                        color: '#3899ec',
                                        marginRight: '20px'
                                    }}
                                >cancel</Button>
                            </ButtonGroup>
                            <ButtonGroup
                                orientation="vertical"
                                aria-label="vertical contained button group"
                                variant="contained"
                                sx={{ boxShadow: 'none' }}
                            >
                                <Button
                                    onClick={() => updateUserInfo()}
                                    className='room-typy-topbar-btn create-new-btn'
                                    sx={{
                                        borderRadius: '20px',
                                        backgroundColor: '#3899ec',
                                        border: "none",
                                        padding: '6px 28px',
                                        textTransform: 'capitalize',
                                        fontSize: '16px'
                                    }}
                                >
                                    save
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </MuiSectionLoader>
    );
};

export default UserProfile;