export const loggedInUser = (status) => {
    return {
        type: "LOGGEDIN",
        payload: status
    }
}
export const showPageSectionLoader = (message="") => {
    return {
        type: "update_pageSectionLoader",
        payload: {"status":true, "message":message}
    }
}
export const hidePageSectionLoader = () => {
    return {
        type: "update_pageSectionLoader",
        payload: {"status":false, "message":""}
    }
}

export const toggleDrawer = (status) => {
    return {
        type: "toggle-sidebar",
        payload: status
    }
}