import { Box, Button, ButtonGroup, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import InnerPagesTop from "../../components/Dashboard/InnerPagesTop";
import { hidePageSectionLoader, showPageSectionLoader } from "../../redux/actions";
import '../../styles/pages/Reservation.css';
import { convertJsToMySqlDateFormat } from "../../utility/dateConversion";
import emptyValidationCheck from "../../utility/emptyValidationCheck";
import MuiSectionLoader from "../../utilityComponents/MuiSectionLoader/MuiSectionLoader";


function createData(
    name,
    quantity,
    price,
    tax,
    amount
) {
    return { name, quantity, price, tax, amount };
}


const rows = [
    createData('103 Standard Double Room', '3 night(s)', '€50', '0%', '€150'),
];


const EditReservation = () => {
    const [rooms, setRooms] = useState([])
    console.log("test_room_101", rooms);
    const [reservationStatus, setReservationStatus] = useState('');
    const [adultsValue, setAdultsValue] = useState('');
    const [kidsValue, setKidsValue] = useState('');
    const [selectedRoom, setSelectedRoom] = useState('');
    const [guestId, setGuestId] = useState('');
    const [guestFirstName, setGuestFirstName] = useState('');
    const [guestLastName, setGuestLastName] = useState('');
    const [guestPhoneNumber, setGuestPhoneNumber] = useState('');
    const [guestCountry, setGuestCountry] = useState('');
    const [guestEmail, setGuestEmail] = useState('');
    const [note, setNote] = useState('');
    const [costPerNight, setCostPerNight] = useState('');
    const [roomId, setRoomId] = useState('');
    const [totalNights, setTotalNights] = useState('');

    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [initialStartDate, setInitialStartDate] = useState('')
    const [initialEndDate, setInitialEndDate] = useState('')

    const [totalPrice, setTotalPrice] = useState('')
    const currentPropertyId = useSelector(state => state.currentPropertyId);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [roomAvailAbleQty, setRoomAvailAbleQty] = useState(0);
    useEffect(() => {
        if (selectedRoom.length > 1) {
            for (const room of rooms) {
                if (room.room_name === selectedRoom) {
                    setCostPerNight(room.room_per_night_price)
                    setRoomId(room.id)
                }
            }
        }
    }, [selectedRoom])

    useEffect(() => {
        getTotalNights()
    }, [startDate, endDate])

    const getTotalNights = () => {
        if (startDate && endDate) {
            const nightStarts = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
            const nightEnd = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
            const difference = Math.abs(nightEnd - nightStarts);
            const nights = difference / (1000 * 3600 * 24)
            setTotalNights(nights)
        }
    }

    console.log("test_101_cost_per_night", totalNights)

    useEffect(() => {
        calculateTotalPrice()
    }, [totalNights, costPerNight])

    const calculateTotalPrice = () => {
        const price = totalNights * costPerNight;
        setTotalPrice(price)
    }


    // get room list
    useEffect(() => {
        // axios.get('room').then(res => {
        //     console.log("property_data_edit_page_101", res.data);
        //     setRooms(res.data)
        // })
    }, [])



    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })


    // const handleChange = (e) => {
    //     console.log("test_101_date", e.target.value)
    //     setForm({ ...form, [e.target.name]: e.target.value })
    //     console.log(form)
    // }


    const [form, setForm] = useState({
        reservation_status: '',
        check_in_date: startDate,
        check_out_date: endDate,
        adults: '',
        kids: '',
        room_name: '',
        room_id: '',
        cost_per_night: costPerNight,
        first_name: '',
        last_name: '',
        phone: '',
        country: '',
        email: '',
        note: '',
        // property_time_zone_id: '1',
        // property_measurement_units: 'text',
        // property_weekend: 'des',
        // property_primary_language_id: '0',
        // property_check_in_time: '2:00',
        // property_check_out_time: '2:00',
        // property_min_night: '0',
        // property_max_night: '0',
        errors: [],
    });
    const { id } = useParams();

    const onSubmit = (e) => {
        e.preventDefault();

        // validation blocks
        let isValidationFailed = false;
        isValidationFailed = emptyValidationCheck(reservationStatus, "Reservation Status", isValidationFailed)
        isValidationFailed = emptyValidationCheck(startDate, "Check In Date", isValidationFailed)
        isValidationFailed = emptyValidationCheck(endDate, "Check Out Date", isValidationFailed)
        isValidationFailed = emptyValidationCheck(adultsValue, "Adults", isValidationFailed)
        // isValidationFailed = emptyValidationCheck(kidsValue, "Kids", isValidationFailed)
        isValidationFailed = emptyValidationCheck(selectedRoom, "Select Room", isValidationFailed)
        isValidationFailed = emptyValidationCheck(guestId, "Guest ID", isValidationFailed)
        isValidationFailed = emptyValidationCheck(guestFirstName, "First Name", isValidationFailed)
        isValidationFailed = emptyValidationCheck(guestLastName, "Last Name", isValidationFailed)
        isValidationFailed = emptyValidationCheck(guestPhoneNumber, "Phone", isValidationFailed)
        isValidationFailed = emptyValidationCheck(guestCountry, "Country", isValidationFailed)
        isValidationFailed = emptyValidationCheck(guestEmail, "Email", isValidationFailed)
        // isValidationFailed = emptyValidationCheck(note, "Notes",isValidationFailed )
        if (isValidationFailed) { return "" };
        // validation blocks



        const startDateFormat = `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`;
        const endDateFormat = `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`;
        console.log(form)
        const formData = {
            reservation_status: reservationStatus,
            check_in_date: startDateFormat,
            check_out_date: endDateFormat,
            adults: adultsValue,
            kids: kidsValue,
            room_name: selectedRoom,
            room_id: roomId,
            cost_per_night: costPerNight,
            guest_id: guestId,
            first_name: guestFirstName,
            last_name: guestLastName,
            phone: guestPhoneNumber,
            country: guestCountry,
            email: guestEmail,
            note: note,
            total_nights: totalNights,
            errors: [],
        }
        dispatch(showPageSectionLoader("Reservation Updating..."))
        axios.put(`reservation/${id}`, formData).then(res => {
            console.log("property_data_101", res.data);
            dispatch(hidePageSectionLoader())

        }).catch(e => {
            alert('error.')
        })
            .finally(f => {
                navigate(-1)
                dispatch(hidePageSectionLoader())
                Toast.fire({
                    icon: 'success',
                    title: 'Property Settings Updated'
                });
            })

    }

    const [countryList, setCountryList] = useState([
        { "name": "Afghanistan", "code": "AF" },
        { "name": "Åland Islands", "code": "AX" }
    ]);

    const pageInfo = { title: 'Edit Reservation', btn1: 'cancel', btn2: 'save', onClick: onSubmit };


    console.log("test_101_calendar", startDate)

    // dispatch

    const requestCountryList = () => {
        axios.get('https://shafiqsuhag.github.io/roobsoft-json/country-name-and-code.json', {
            transformRequest: (data, headers) => {
                delete headers.common['Authorization'];
                return data;
            }
        })
            .then(response => {
                setCountryList(response.data)
            })
            .catch(error => {
                return error;
            })
    }
    //


    useEffect(() => {

        requestCountryList()

    }, [])

    useEffect(() => {
        if(initialEndDate){ 
            checkReservationAvailabilty(startDate, endDate)
        }
        
        getTotalNights()


    }, [startDate, endDate, currentPropertyId, initialEndDate])

    const checkReservationAvailabilty = (checkinDate, checkOutDate) => {
        if (checkinDate && checkOutDate && currentPropertyId && id) {
            console.log("checkinDate_checkOutDate", checkinDate, checkOutDate)
            const checkinDatePhp = convertJsToMySqlDateFormat(checkinDate);
            const checkOutDatePhp = convertJsToMySqlDateFormat(checkOutDate);
            // const checkinDatePhp = new Date(checkinDate).toISOString().slice(0, 19).replace("T", " ");
            // const checkOutDatePhp = new Date(checkOutDate).toISOString().slice(0, 19).replace("T", " ");
            console.log("checkinDate_checkOutDate_php", checkinDatePhp, checkOutDatePhp,)
            dispatch(showPageSectionLoader("Check rooms availablity..."))
            let editingMode = 'yes'
            console.log("test_103", initialEndDate,'-', convertJsToMySqlDateFormat(initialEndDate))
            if (convertJsToMySqlDateFormat(initialStartDate) === checkinDatePhp && convertJsToMySqlDateFormat(initialEndDate) === checkOutDatePhp) {
                console.log("test_201- yes", convertJsToMySqlDateFormat(initialStartDate), checkinDatePhp, convertJsToMySqlDateFormat(initialEndDate), checkOutDatePhp)
            }
            else {
                editingMode = 'no'
                console.log("test_201- no", convertJsToMySqlDateFormat(initialStartDate), checkinDatePhp, convertJsToMySqlDateFormat(initialEndDate), checkOutDatePhp)
            }

            axios
                // .get("/json/reservation_list2.json",{baseURL: 'http://localhost:3000/'})
                // .get("/json/reservation_list.json",{baseURL: 'http://localhost:3000/'})
                // .get(`/check-rooms-availablity/${checkinDatePhp}/${checkOutDatePhp}/${currentPropertyId}`)
                .get(`/check-rooms-availablity/${checkinDatePhp}/${checkOutDatePhp}/${currentPropertyId}/${id}/${editingMode}`)
                // .get("/check-rooms-availablity/2022-06-15/2022-06-15/1")
                .then(response => {
                    dispatch(hidePageSectionLoader())
                    console.log("getReservationListForMobile", response.data)
                    setRooms(response.data)
                    setRoomAvailAbleQty(response.data.length)
                })
                .catch(errors => {

                })
                .finally(() => {
                    dispatch(hidePageSectionLoader())

                })
        }

    }


    useEffect(() => {
        dispatch(showPageSectionLoader())
        axios.get(`reservation/${id}`).then(res => {

            const data = res.data.data;
            // setRoomInfo(res.data.data)
            console.log("reservation_data_edit_page_101", data);
            setAdultsValue(data.adults)

            const startDateString = data.check_in_date;
            const startDateStringArray = startDateString.split("-")
            const startYear = startDateStringArray[0]
            const startMonth = startDateStringArray[1]
            const startDate = startDateStringArray[2]
            const startDateObject = new Date(startYear, startMonth - 1, startDate)
            console.log("start_date_object", startDateObject)
            setStartDate(startDateObject)
            setInitialStartDate(startDateObject)

            const endDateString = data.check_out_date;
            const endDateStringArray = endDateString.split("-")
            const endDateObject = new Date(endDateStringArray[0], endDateStringArray[1] - 1, endDateStringArray[2])
            console.log("end_date_object", endDateObject)
            setEndDate(endDateObject)
            setInitialEndDate(endDateObject)
            console.log("tes_201_",endDateString,'1-',endDateStringArray,'2-',endDateObject)

            setCostPerNight(data.cost_per_night)
            setGuestCountry(data.country)
            setGuestEmail(data.email)
            setGuestId(data.guest_id)
            setGuestFirstName(data.first_name)
            setGuestLastName(data.last_name)
            setKidsValue(data.kids)
            setAdultsValue(data.adults)
            setNote(data.note)
            setReservationStatus(data.reservation_status)
            setGuestPhoneNumber(data.phone)
            setSelectedRoom(data.room_name)
            setTotalNights(data.total_nights)
            setRoomId(data.room_id)
            dispatch(hidePageSectionLoader())
        })
    }, [])
    return (
        <MuiSectionLoader>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <div className='edit-room'>
                    <InnerPagesTop
                        pageInfo={pageInfo}
                    ></InnerPagesTop>
                    <section>
                        <Box className='edit-box'>
                            <Grid
                                container
                                sx={{
                                    alignItems: 'center'
                                }}
                            >
                                <Grid
                                    xs={12}
                                    md={5}
                                    item
                                >
                                    <h2 className="edit-box-title">
                                        Reservation Status
                                    </h2>
                                </Grid>
                                <Grid
                                    xs={12}
                                    md={7}
                                    item
                                >
                                    <Grid lg={6} md={6} sm={6}>
                                        <FormControl fullWidth>

                                            <InputLabel id="demo-simple-select-label">Checked In</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="bed type"
                                                size="small"
                                                sx={{
                                                    width: '100%'
                                                }}
                                                name='reservation_status'
                                                value={reservationStatus}
                                                onChange={(e) => setReservationStatus(e.target.value)}
                                            >
                                                <MenuItem value={"Checked In"}>Checked In</MenuItem>
                                                <MenuItem value={"Reserved"}>Reserved</MenuItem>
                                                <MenuItem value={"Checked Out"}>Checked Out</MenuItem>
                                                <MenuItem value={"Not Available"}>Not Available</MenuItem>
                                                <MenuItem value={"Cancelled"}>Cancelled</MenuItem>
                                            </Select>
                                        </FormControl>

                                    </Grid>

                                </Grid>
                            </Grid>
                            <Grid
                                lg={2}
                                md={2}
                                sm={12}
                                item
                            >

                            </Grid>
                        </Box>
                        <Box className='edit-box'>
                            <Grid
                                container
                                sx={{
                                    alignItems: 'flex-start'
                                }}
                                className="room-details-container"
                            >
                                <Grid
                                    xs={12}
                                    md={5}
                                    item
                                >
                                    <h2 className="edit-box-title">
                                        Booking Details
                                    </h2>
                                </Grid>
                                <Grid
                                    xs={12}
                                    md={7}
                                    item
                                >
                                    <div>
                                        <Grid container spacing={2}>
                                            <Grid
                                                item
                                                xs={12}
                                                md={12}
                                            >
                                                <div>
                                                    <Grid spacing={2} container>
                                                        <Grid item xs={6} md={6}>
                                                            <DatePicker
                                                                label="Check In"
                                                                value={startDate}
                                                                onChange={(newValue) => {
                                                                    setStartDate(newValue);
                                                                }}
                                                                name='check_in_date'
                                                                renderInput={(params) => <TextField {...params} />}
                                                                sx={{ width: '100%' }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6} md={6}>
                                                            <DatePicker
                                                                label="Check Out"
                                                                value={endDate}
                                                                onChange={(newValue) => {
                                                                    setEndDate(newValue);
                                                                }}
                                                                renderInput={(params) => <TextField {...params} />}
                                                                sx={{ width: '100%' }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                md={12}
                                            >
                                                <div
                                                >
                                                    <Grid container>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            md={6}
                                                        >
                                                            <div className="reservation-age-level"
                                                                style={{ display: 'flex' }}
                                                            >
                                                                <TextField
                                                                    required
                                                                    type="number"
                                                                    id="outlined-required"
                                                                    label="Adults"
                                                                    defaultValue="Hello World"
                                                                    name='adults'
                                                                    value={adultsValue}
                                                                    onChange={(e) => setAdultsValue(e.target.value)}
                                                                    size="small"
                                                                    sx={{ marginRight: '25px', width: '100%' }}
                                                                />
                                                                <TextField
                                                                    required
                                                                    type="number"
                                                                    id="outlined-required"
                                                                    label="Kids"
                                                                    defaultValue="Hello World"
                                                                    size="small"
                                                                    name='kids'
                                                                    value={kidsValue}
                                                                    onChange={(e) => setKidsValue(e.target.value)}
                                                                    sx={{ marginLeft: '25px', width: '100%' }}
                                                                />
                                                            </div>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            md={6}
                                                        ></Grid>
                                                    </Grid>
                                                </div>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                md={12}
                                            >
                                                <div>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} md={6}>

                                                            <FormControl fullWidth>
                                                                <p>Select Room | {roomAvailAbleQty} rooms available</p>
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    label="bed type"
                                                                    name='room_bed_type_id'
                                                                    onChange={(e) => setSelectedRoom(e.target.value)}
                                                                    size="small"
                                                                    value={selectedRoom}
                                                                    sx={{
                                                                        width: '100%'
                                                                    }}
                                                                >
                                                                    {
                                                                        rooms.map((item, index) => <MenuItem key={index} value={item.room_name}>{item.room_name}</MenuItem>)
                                                                    }
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>

                                                            {/* <FormControl fullWidth>
                                                            <InputLabel id="demo-simple-select-label">103 Standard Double Room</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                label="bed type"
                                                                name='room_bed_type_id'
                                                                size="small"
                                                                sx={{
                                                                    width: '100%'
                                                                }}
                                                            >
                                                                <MenuItem value={10}>Checked In</MenuItem>
                                                                <MenuItem value={20}>Reserved</MenuItem>
                                                                <MenuItem value={30}>Checked Out</MenuItem>
                                                                <MenuItem value={30}>Not Available</MenuItem>
                                                                <MenuItem value={30}>Cancelled</MenuItem>
                                                            </Select>
                                                        </FormControl> */}
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>

                            </Grid>
                        </Box>


                        <Box
                            className='edit-box'
                        >
                            <Grid
                                container
                            // sx={{
                            //     alignItems: 'flex-start'
                            // }}
                            >
                                <Grid
                                    xs={12}
                                    md={5}
                                    item
                                >
                                    <h2 className="edit-box-title">
                                        Guest Details
                                    </h2>
                                </Grid>
                                <Grid
                                    xs={12}
                                    md={7}
                                    item
                                    sx={{
                                        // display: 'flex',
                                    }}
                                    className='guest_details_container'
                                >
                                    <div style={{ width: '100%' }}>
                                        <Grid container spacing={2}>
                                            <Grid xs={12} md={12}>
                                                <div>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} md={2}>
                                                            <TextField
                                                                required
                                                                id="outlined-required"
                                                                label="Guest ID"
                                                                sx={{ width: '100%' }}
                                                                size="small"
                                                                className="guest_info_field"
                                                                value={guestId}
                                                                onChange={(e) => setGuestId(e.target.value)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={5}>
                                                            <TextField
                                                                required
                                                                id="outlined-required"
                                                                label="First Name"
                                                                sx={{ width: '100%' }}
                                                                size="small"
                                                                className="guest_info_field"
                                                                value={guestFirstName}
                                                                onChange={(e) => setGuestFirstName(e.target.value)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={5}>
                                                            <TextField
                                                                required
                                                                id="outlined-required"
                                                                label="Last Name"
                                                                sx={{ width: '100%' }}
                                                                size="small"
                                                                className="guest_info_field"
                                                                value={guestLastName}
                                                                onChange={(e) => setGuestLastName(e.target.value)}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </Grid>
                                            <Grid sx={{ marginTop: '30px' }} xs={12} md={12}>
                                                <div>
                                                    {/* <ul>
                                                    {
                                                        countryList.map(countryItem => {
                                                            return <li>{countryItem?.name}</li>
                                                        })
                                                    }
                                                </ul> */}
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                required
                                                                id="outlined-required"
                                                                label="Phone"
                                                                sx={{ width: '100%' }}
                                                                size="small"
                                                                className="guest_info_field"
                                                                value={guestPhoneNumber}
                                                                onChange={(e) => setGuestPhoneNumber(e.target.value)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <FormControl className="guest_info_field" fullWidth>
                                                                <InputLabel id="demo-simple-select-label">Country</InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    label="bed type"
                                                                    name='room_bed_type_id'
                                                                    size="small"
                                                                    value={guestCountry}
                                                                    onChange={(e) => setGuestCountry(e.target.value)}
                                                                    sx={{
                                                                        width: '100%',
                                                                    }}

                                                                >
                                                                    {
                                                                        countryList.map(item => <MenuItem value={item.name}>{item.name}</MenuItem>)
                                                                    }

                                                                    {/* {
                                                                    countryList.map(countryItem => {
                                                                        return <MenuItem value={countryItem?.code}>{countryItem?.name}</MenuItem>
                                                                    })
                                                                } */}
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </Grid>
                                            <Grid xs={12} sx={{ marginTop: '20px', }} md={12}>
                                                <TextField
                                                    required
                                                    id="outlined-required"
                                                    label="Email"
                                                    sx={{ width: '100%' }}
                                                    size="small"
                                                    value={guestEmail}
                                                    onChange={(e) => setGuestEmail(e.target.value)}
                                                />
                                                <FormControlLabel control={<Checkbox />} label="Send your guest a confirmation email" />
                                                <textarea
                                                    placeholder="Note"
                                                    value={note}
                                                    onChange={(e) => setNote(e.target.value)}
                                                    id=""
                                                    style={{
                                                        width: '100%',
                                                        borderColor: '#c0b8b8',
                                                        borderRadius: '5px',
                                                        padding: '20px 30px'
                                                    }}
                                                    rows="8"
                                                >

                                                </textarea>
                                            </Grid>
                                        </Grid>
                                    </div>

                                </Grid>
                            </Grid>
                        </Box>
                        <Box
                            className='edit-box'
                        >
                            <Grid
                                container
                                sx={{
                                    alignItems: 'flex-start'
                                }}
                            >
                                <Grid
                                    xs={12}
                                    item
                                >
                                    <h2 className="edit-box-title">
                                        <var style={{ fontStyle: 'normal' }}>Payment Details</var>
                                    </h2>

                                </Grid>
                                <Grid
                                    xs={12}
                                    item
                                >
                                    <TableContainer className="responsive-table-two" sx={{ width: '100%', overflowX: 'auto' }} component={Paper}>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{ fontWeight: '600' }}>Service Name</TableCell>
                                                    <TableCell sx={{ fontWeight: '600' }} align="right">Quantity</TableCell>
                                                    <TableCell sx={{ fontWeight: '600' }} align="right">Price</TableCell>
                                                    <TableCell sx={{ fontWeight: '600' }} align="right">Amount</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {selectedRoom}
                                                    </TableCell>
                                                    <TableCell align="right">{
                                                        totalNights && <span>{totalNights} night's</span>
                                                    }</TableCell>
                                                    <TableCell align="right">{costPerNight}</TableCell>
                                                    <TableCell align="right">€{totalPrice}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <div>
                                        <Grid container>
                                            <Grid item xs={6} md={6}>
                                                <h2>Total</h2>
                                            </Grid>
                                            <Grid item xs={6} md={6}>
                                                <h2 style={{ textAlign: 'end' }}>€{totalPrice}</h2>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>
                        </Box>
                        <div>
                            <Grid container className="edit-room-bottom">
                                <Grid xs={12} sm={6} item><p><span style={{ color: '#3899ec' }}>*</span><small> Indicates mandatory fields</small></p></Grid>
                                <Grid className='edit-room-bottom-btns' xs={12} sm={6} item sx={{ textAlign: 'end' }}>
                                    <ButtonGroup
                                        orientation="vertical"
                                        aria-label="vertical outlined button group"
                                    >
                                        <Button
                                            className='room-typy-topbar-btn preview-btn'
                                            sx={{
                                                borderRadius: '20px',
                                                backgroundColor: '#fff',
                                                border: "none",
                                                padding: '6px 28px',
                                                textTransform: 'capitalize',
                                                fontSize: '16px',
                                                color: '#3899ec',
                                                marginRight: '20px'
                                            }}
                                        >cancel</Button>
                                    </ButtonGroup>
                                    <ButtonGroup
                                        orientation="vertical"
                                        aria-label="vertical contained button group"
                                        variant="contained"
                                        sx={{ boxShadow: 'none' }}
                                    >
                                        <Button
                                            onClick={(e) => onSubmit(e)}
                                            className='room-typy-topbar-btn create-new-btn'
                                            sx={{
                                                borderRadius: '20px',
                                                backgroundColor: '#3899ec',
                                                border: "none",
                                                padding: '6px 28px',
                                                textTransform: 'capitalize',
                                                fontSize: '16px'
                                            }}
                                        >
                                            save
                                        </Button>
                                    </ButtonGroup>
                                </Grid>
                            </Grid>
                        </div>
                    </section>
                </div>
            </LocalizationProvider>
        </MuiSectionLoader>
    );
};

export default EditReservation;