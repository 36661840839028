import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import { CalendarPicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { hidePageSectionLoader, showPageSectionLoader } from '../../redux/actions';
import MuiSectionLoader from '../../utilityComponents/MuiSectionLoader/MuiSectionLoader';

const ReservationListMobile = () => {

    // others
    // others //

    // redux
    const dispatch = useDispatch();
    // redux //

    // state mangement
    const [date, setDate] = useState();
    const [reservations, setReservations] = useState([])
    // state mangement //
    // method mangement 
    const loadReservationList = () => {
        dispatch(showPageSectionLoader())
        axios
            .get(`reservation`)
            .then(res => {
                setReservations(res.data)
                dispatch(hidePageSectionLoader())
            })
            .catch(errors => {
                console.log("errors", errors)
            })
    }
    const convertStartDate = (newDate) => {
        const dateArray = newDate.split('-')
        const date = new Date(dateArray[0], dateArray[1], dateArray[2])
        const checkInDate = `${date.getDate()} ${months[date.getMonth() - 1]}`
        return checkInDate
    }
    const convertEndDate = (newDate) => {
        const dateArray = newDate.split('-')
        const date = new Date(dateArray[0], dateArray[1], dateArray[2])
        const checkInDate = `${date.getDate()} ${months[date.getMonth() - 1]},${date.getFullYear()}`
        return checkInDate
    }
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    // method mangement //

    // useEffect
    useEffect(() => {
        loadReservationList()
    }, [])
    // useEffect//





  

    

    

    return (
        <MuiSectionLoader>
            <div>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Box>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <CalendarPicker date={date} onChange={(newDate) => setDate(newDate)} />
                            </Grid>
                            <Grid item xs={12}>
                                <div>
                                    <Grid container>
                                        {
                                            reservations.map((item, index) => <Grid key={index} item xs={12} sx={{ padding: '0 10px' }}>
                                                <Box sx={{ backgroundColor: '#f1f1f1', padding: '5px 20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderLeft: '2px solid #66bb6a', borderRadius: '3px' }}>
                                                    <Box sx={{ width: '70%' }}>
                                                        <Box>
                                                            <h4 style={{ margin: '0' }}>{item.room_name}</h4>
                                                        </Box>

                                                        <Box>
                                                            <p style={{ margin: '0', color: '#797676' }}>{convertStartDate(item.check_in_date)} - {convertEndDate(item.check_out_date)}</p>
                                                        </Box>
                                                    </Box>
                                                    <Box sx={{ width: '30%', textAlign: 'end', display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                                                        <img style={{ width: '70px', height: '70px', borderRadius: '150%' }} src="/images/6.jpg" alt="reservation-room-img" />
                                                    </Box>
                                                </Box>
                                            </Grid>)
                                        }
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    </Box>
                </LocalizationProvider>
            </div>
        </MuiSectionLoader>
    );
};

export default ReservationListMobile;