import { Button, Paper, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { hidePageSectionLoader, showPageSectionLoader } from '../../../redux/actions';
import MuiSectionLoader from '../../../utilityComponents/MuiSectionLoader/MuiSectionLoader';


const PropertyList = () => {
  const [properties, setProperties] = useState([]);
  const dispatch = useDispatch();

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      fontWeight: '600'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  useEffect(() => {
    dispatch(showPageSectionLoader("Property List Loading..."))
    axios.get(`property`).then(res => {
      console.log("property_property_list_page_101", res.data);
      setProperties(res.data)
      dispatch(hidePageSectionLoader())
    })
  }, [])
  return (
    <MuiSectionLoader>
      <div>
        
        <h2 style={{ marginLeft: '10px' }}>Property List</h2>
        <TableContainer className="responsive-table" sx={{width: '100%', overflowX: 'auto'}} component={Paper}>
          {/* <div className="table-container"> */}
            <Table sx={{ minWidth: 300 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>ID</StyledTableCell>
                  <StyledTableCell align="left">Property Name</StyledTableCell>
                  <StyledTableCell align="left">Phone</StyledTableCell>
                  <StyledTableCell align="left">Email</StyledTableCell>
                  <StyledTableCell align="left">Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {properties.map((row) => (
                  <StyledTableRow key={row.id}>
                    <StyledTableCell component="th" scope="row">
                      {row.id}
                      {console.log("rowid", row.id)}
                    </StyledTableCell>
                    <StyledTableCell align="left">{row.property_name}</StyledTableCell>
                    <StyledTableCell align="left">{row.property_phone}</StyledTableCell>
                    <StyledTableCell align="left">{row.property_email}</StyledTableCell>
                    <StyledTableCell align="left"><Link style={{ textDecoration: 'none' }} to={`/dashboard/edit-property/${row.id}`}><Button variant="contained">Edit</Button></Link></StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          {/* </div> */}
        </TableContainer>
      </div>
    </MuiSectionLoader>
  );
};

export default PropertyList;