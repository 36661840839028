import React from 'react';
import DashboardWidget from '../components/Dashboard/DashboardWidget';
import Booking from './Booking/Booking';

const Dashboard = () => {
    return (
        <div>
            Dashboard Page 
            <Booking/>
            <DashboardWidget/>
        </div>
    );
};

export default Dashboard;