import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import BaseUrl from '../../utility/ApiBaseUrl';
const SelectProperty = () => {
    const dispatch = useDispatch();

    const currentPropertyId = useSelector(state => state.currentPropertyId);
    const [property, setProperty] = useState(0);
    const [propertyList, setPropertyList] = useState([ ]);

    const handleChange = (event: SelectChangeEvent) => {
        setProperty(event.target.value);
        console.log(event.target.value)
        dispatch({
            type: "current_property_id_update",
            payload: event.target.value
          })
    };

    const getPropertyList = () => {
        axios
            .get(BaseUrl() + 'property')
            .then((response) => {
                console.log("property_list", response.data)
                setPropertyList( response.data)
            })
            .catch((errors) => {
                console.log(errors)
            })
            .finally(() => {

            })
    }

    useEffect(() => {
        getPropertyList()

    }, [])

    useEffect(()=>{
        setProperty(currentPropertyId)
    },[currentPropertyId])

    return (
        <div>
            <FormControl className='select-property-field' sx={{ m: 1, minWidth: 200 }} size="small">
                <InputLabel sx={{ color: '#fff' }} id="demo-select-small">Property</InputLabel>
                <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={property}
                    label="Age"
                    onChange={handleChange}
                >
                    <MenuItem value={0}>
                        <em>None</em>
                    </MenuItem>
                    {
                        propertyList.map((propertyItem, index) => <MenuItem key={index} value={propertyItem.id}> {propertyItem.property_name}  </MenuItem>)
                    }


                </Select>
            </FormControl>
        </div>
    );
};

export default SelectProperty;