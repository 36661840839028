import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { useNavigate } from 'react-router-dom';
import '../../styles/pages/RoomTypes.css';

const InnerPagesTop = ({pageInfo}) => {
    const navigate = useNavigate();
    return (
        <Grid 
            container
            className="room-types-topbar"
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}
        >
            <Grid
            item
            >
                <h1 className="room-type-heading">
                    {pageInfo.title}
                </h1>
            </Grid>
            <Grid 
            item
            >
                <Box
                    sx={{
                        display: 'flex',
                        '& > *': {
                            m: 2,
                        },
                    }}
                >
                    <ButtonGroup
                        orientation="vertical"
                        aria-label="vertical outlined button group"
                        className='pages-top-btn-group'
                    >
                        {
                            pageInfo.btn1 && <Button
                            onClick={()=>navigate(-1)}
                            className='room-typy-topbar-btn preview-btn'
                            sx={{
                                borderRadius: '20px',
                                backgroundColor: '#fff',
                                border: "none",
                                padding: '6px 28px',
                                textTransform: 'capitalize',
                                fontSize: '16px',
                                color: '#3899ec'
                            }}
                        >{pageInfo?.btn1}</Button>
                        }
                        
                    </ButtonGroup>
                    <ButtonGroup
                        orientation="vertical"
                        aria-label="vertical contained button group"
                        variant="contained"
                        sx={{ boxShadow: 'none' }}
                        className='pages-top-btn-group'
                    >
                        <Button
                            onClick={pageInfo?.onClick}
                            className='room-typy-topbar-btn create-new-btn'
                            sx={{
                                borderRadius: '20px',
                                backgroundColor: '#3899ec',
                                border: "none",
                                padding: '6px 18px',
                                textTransform: 'capitalize',
                                fontSize: '16px'
                            }}
                        >
                            {pageInfo.btn2}
                        </Button>
                    </ButtonGroup>
                </Box>
            </Grid>
        </Grid>
    );
};

export default InnerPagesTop;