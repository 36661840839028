import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { jsdateToUnixTimestamp } from '../../utility/dateConversion';

const ReservationPopUp = ({ setShowPopup, setFirstClick, setSecondClick, clickedItems, mouseOverItems, startDate, endDate, selectedRowId }) => {

    const clearSelectedColumns = () => {
        console.log("selectedRowId", selectedRowId)
        const rowArray = selectedRowId.split("_")
        // const startId = parseInt(`${startArray[0]}${startArray[1]}`)

        // column 1 - 12 
        // rowno - dynamic 
        // if(startArray[1] <= endingArry[1]){
        for (let j = 1; j <= 12; j++) {
            // console.log("test_2nd_run")

            const effectedId = `${rowArray[0]}_${j}`
            document.getElementById(effectedId).style.backgroundColor = "white";
            // blockRef.current(effectedId).style.backgroundColor = "red";


        }
        // }
    }
    const handleClick = () => {
        clearSelectedColumns()

        setShowPopup(false)
        setFirstClick(false)
        setSecondClick(false)
        // document.querySelector(".date-square").classList.remove('pink')
        mouseOverItems.map(item => item.classList.remove('selected'))
        clickedItems.map(item => item.classList.remove('selected'))
    }

    const navigate = useNavigate();
    const handleNavigateToReservation = (e) => {
        e.preventDefault();
        // let startDateParse = Date.parse(startDate);
        const startDateTimestamp = jsdateToUnixTimestamp(startDate)
        const endDateTimestamp = jsdateToUnixTimestamp(endDate)
        // const timeStampToDate = new Date(startDateTimestamp * 1000);
        // const timeStampToDate = unixTimestampToJsdate(startDateTimestamp)


        // console.log("tempStartDate_tempEndDate_before", (startDateTimestamp), endDateTimestamp)
        // const tempStartDate = `${startDate.getDate()}/${startDate.getMonth() + 1}/${startDate.getFullYear()}`
        // const tempEndDate = `${endDate.getDate()}/${endDate.getMonth() + 1}/${endDate.getFullYear()}`
        // console.log("tempStartDate_tempEndDate", tempStartDate, tempEndDate)
        navigate(`/dashboard/reservation/${startDateTimestamp}/${endDateTimestamp}`)
    }
    return (
        <div className="reservation-popup">
            <div className="reservation-popup-container">
                <div style={{ textAlign: 'end' }} className="hide-popup"><span onClick={() => handleClick()} style={{ cursor: 'pointer' }}><CloseIcon /></span></div>
                <div>
                    <p>
                        From: {
                            `${startDate.getDate()}/${startDate.getMonth() + 1}/${startDate.getFullYear()}`
                        }
                    </p>
                    <p>
                        To: {
                            `${endDate.getDate()}/${endDate.getMonth() + 1}/${endDate.getFullYear()}`
                        }
                    </p>
                </div>
                <div className="reservation-btn">
                    {/* <span><CalendarTodayIcon /></span><span><Link to="/dashboard/reservation/1/1">Create Reservation</Link></span> */}
                    <span><CalendarTodayIcon /></span><a onClick={handleNavigateToReservation} href="#"  >Create Reservation</a>
                </div>
                {/* <div className="reservation-btn">
                    <span><AttachMoneyIcon /></span><span><Link to="/">Add Quick Rate</Link></span>
                </div>
                <div className="reservation-btn">
                    <span><BrowserNotSupportedIcon /></span><span><Link to="/">Set As Not Available</Link></span>
                </div> */}
            </div>
        </div>
    );
};

export default ReservationPopUp;