// const initialState = false;
export const drawerStatus = (state=null, action) =>{
    switch (action.type){
        case "update_drawerStatus" : return action.payload;
        default : return state;
    }
}

export const pageSectionLoader2 = (state={"status":false, "message":""}, action) =>{
    switch (action.type){
        case "update_pageSectionLoader" : return action.payload;
        default : return state;
    }
}