import Box from '@mui/material/Box';
import axios from 'axios';
import { useEffect, useState } from 'react';
import MyComponent from 'react-fullpage-custom-loader';
import { useSelector } from 'react-redux';
import '../../../styles/pages/RoomTypes.css';
import BaseUrl from '../../../utility/ApiBaseUrl';
import InnerPagesTop from './InnerPagesTop';
import RoomListItem from './RoomListItem';


const RoomList = () => {
    const currentPropertyId = useSelector(state => state.currentPropertyId);
    const [roomList, setRoomList] = useState([]);

    const [loading, seLoading] = useState(false);
    // load rooms data
    useEffect(() => {

        loadRoomList();
    }, []);
    useEffect(() => {

        loadRoomList();
    }, [currentPropertyId]);
    // Handlers--start

    const handleRefreshButton = () => {
        loadRoomList();
    }
    // Handle--ENd
    const tokenValue = useSelector(state => state.tokenValue)
    // functions -
    const config = {
        headers: { Authorization: `Bearer ${tokenValue}` }
    }
    const loadRoomList = () => {
      
        // console.log("token_vaule", tokenValue)
        // axios.defaults.headers.common['Authorization'] = `Bearer ${tokenValue}`;
        seLoading(true);
        axios.get(BaseUrl() + `room-list-by-propertyid/${currentPropertyId}`,config).then(res => {
            setRoomList(res.data.data)

            if (res.data.data) {
                seLoading(false)
            }
        }).catch(e => {
            alert('Server error reload again');
            seLoading(false)
        })
    }


    const pageInfo = { title: 'Room Types', btn1: 'refresh', btn2: '+ Create New', btn2Link: "/room-create" };
    return (
        <>

            {loading && <MyComponent sentences={'.'} />}

            <section className='room-types-page'>
                <div className="room-types-container">
                    <InnerPagesTop
                        handleRefreshButton={handleRefreshButton}
                        pageInfo={pageInfo}
                    >
                    </InnerPagesTop>
                    <Box>
                        <Box className="room-list">
                            {
                                roomList.map(room => <RoomListItem
                                    key={room.room}
                                    room={room}
                                />)
                            }
                        </Box>
                    </Box>
                </div>
            </section>
        </>
    );
};

export default RoomList;