

const activityLogTableNaming = (subject_type) => {
    // subject_type
    switch (subject_type) {
        case 'App\\Models\\Cin':
            return  "Company"; 
        case 'App\\Models\\User':
            return  "User"; 
        default:
            return subject_type; 
    }
 
};

export default activityLogTableNaming;
