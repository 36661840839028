import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
// import '../styles/pages/RoomTypes.css';
import '../../../styles/pages/RoomTypes.css';
import BaseUrl from '../../../utility/ApiBaseUrl';

import { AiOutlineEdit } from "react-icons/ai";
import { ImageApiUrl } from '../../../utility/master';

const RoomListItem = ({ room }) => {
    const { room_units, room_details, id, room_photo, room_name } = room;
    console.log("room_reservation_check", room)


    const deleteRoom = room_id => {
        // const reserved = true
        // if(reserved){
        //     Swal.fire("Rooms has active reservations in calendar")
        // }else{
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
    
                    console.log('room_id_77', room_id)
    
                    axios.delete(BaseUrl() + 'room/' + room_id).then(res => {
    
                        if (res.data.msg) {
                            Swal.fire(
                                'Deleted!',
                                'Room has been deleted.',
                                'success',
                            )
    
                            window.location.reload();
                        }
    
                        else {
    
                            Swal.fire(
                                'Error!',
                                'Server error try again.',
                                'error',
                            )
    
                        }
    
                    })
    
                }
            })
        // }



        
    }
    return (
        <>
            <Grid
                className='room-grid-list-item'
                container
            >
                <Grid
                    item
                    xs={12}
                    md={12}
                    lg={6}
                >
                    <div>
                        <Grid
                            container
                            spacing={2}
                            className="room-list-content"
                        >
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={4}
                                lg={4}
                            >
                                <Box sx={{
                                    width: "200px",
                                    margin: "20px"
                                }}>
                                    {

                                        room_photo ? <img width="200px" className='room-list-img' src={`${ImageApiUrl()}${room_photo}`} alt="" /> : <img width="200px" className='room-list-img' src={`/images/404-image.jpg`} alt="" />
                                    }

                                </Box>

                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={8}
                                lg={8}
                            >
                                <div className='room-list-info'>
                                    <Link to={`/${id}`}>{room_details}</Link>
                                    <p> {room_name} </p>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={12}
                    lg={6}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "end"
                    }}
                    className="room-list-icons"
                >
                    <div className='room-list-icon-container'>
                        {/* <span className='room-list-icon'>
                            <Link to={`/dashboard/edit-room/${id}`}>
                                <ModeEditOutlineOutlinedIcon />
                            </Link>
                        </span> */}
                        <Link style={{fontSize: '27px'}} to={`/dashboard/edit-room/${id}`}>
                            <span className='room-list-icon'>
                                <AiOutlineEdit />
                            </span>
                        </Link>

                        <span onClick={() => deleteRoom(id)} className='room-list-icon'>
                            <DeleteOutlineOutlinedIcon />
                        </span>

                        {/* <span className='room-list-drag-icon'>
                            <DragIndicatorOutlinedIcon />
                        </span> */}
                    </div>
                </Grid>
            </Grid>
        </>
    );
};

export default RoomListItem;