

const master = () => {
    const url = "https://dbs.wixotel.com"
    // const url = "http://room-management-server.test"
    
    return url; 
};

export const clientSideUrl = () => {
    // const url = "http://localhost:3000/"
    const url = "https://wixotel.com/"
    // const url = "https://hotel-management-ten.vercel.app/"
    
    return url; 
}

export const ImageApiUrl = () => {
    
    return `${master()}/public/public/uploads/`
    // return `${master()}/public/uploads/`
   
};

export default master;