import React from 'react';
// 
import { BiLoaderAlt } from "react-icons/bi";
import { useSelector } from 'react-redux';
import "./MuiSectionLoader.css";

const MuiSectionLoader = ({ children }) => {

    // 

    const pageSectionLoader = useSelector(state => state.pageSectionLoader2);

    //
    const drawerStatus = useSelector(state => state.drawerStatus)
    return (


        <div style={{ position: "relative", minHeight: "100vh" }}>
            {pageSectionLoader.status && <div className="mui-section-loader" style={drawerStatus ? { marginLeft: "240px" } : { marginLeft: "0" }}>
                <BiLoaderAlt className="full-rotate " style={{ fontSize: "70px" }} />
                <h1>{pageSectionLoader.message}</h1>
            </div>}

            {children}
        </div>

    );
};

export default MuiSectionLoader;