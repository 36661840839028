import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import MailIcon from "@mui/icons-material/Mail";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import {useDispatch} from 'react-redux'
import { toggleDrawer } from "../../redux/actions";

const MenuLink = ({menuList ,authUserPermission }) => {
    console.log("menuList-1", menuList)
    const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
    const [currentToggleIndex, setCurrentToggleIndex] = useState(null);
    const submenuOpen = {
      display: "block",
    };
  
    const submenuClosed = {
      display: "none",
    };
    const handleSubMenuToggle = (index) => {
        console.log(index);
        setCurrentToggleIndex(index);
        setIsSubmenuOpen(!isSubmenuOpen);
      };

      const dispatch = useDispatch();
    return (
        <>
            <List>
                {menuList.map((item, mainMenuindex) => (
                    <>
                        {item?.permission === authUserPermission ? <></> : <Link
                         onClick={()=> dispatch(toggleDrawer(false))}
                            style={{ textDecoration: "none", color: '#595656' }}
                            to={item.menuLink}
                            key={mainMenuindex}

                        >
                            <ListItem
                                onClick={() => {
                                    return handleSubMenuToggle(mainMenuindex);
                                }}
                                button
                            >
                                {/* <ListItemIcon>

                {
                  item.icon && <i className={item.icon}></i>
                }
              
              </ListItemIcon> */}
                                <ListItemIcon>
                                    {
                                        item.icon && item.icon
                                    }
                                </ListItemIcon>

                                <ListItemText primary={item.menuLabel} />

                                {/* {item.menuLabel} */}
                                {/* {item.subMenu && isSubmenuOpen ? <span><ArrowDropUpIcon /></span> : <span><ArrowDropDownIcon /></span>} */}
                                {item.subMenu && (
                                    <span>
                                        {item.subMenu && isSubmenuOpen ? (
                                            <span>
                                                <ArrowDropUpIcon />
                                            </span>
                                        ) : (
                                            <span>
                                                <ArrowDropDownIcon />
                                            </span>
                                        )}
                                    </span>
                                )}
                            </ListItem>
                            <div>
                                {item.subMenu &&
                                    item.subMenu.map((item, index) => {
                                        return (
                                            <div
                                                style={
                                                    isSubmenuOpen === true &&
                                                        currentToggleIndex === mainMenuindex
                                                        ? submenuOpen
                                                        : submenuClosed
                                                }
                                                key={index}
                                            >
                                                <Link style={{
                                                    textDecoration: 'none',
                                                    padding: '10px 30px 10px 60px',
                                                    backgroundColor: '#F0F4F7',
                                                    width: '100%',
                                                    display: 'inline-block',
                                                    color: '#595656',
                                                    fontSize: '15px',


                                                }} to={`${item.subMenuLink}`}>
                                                    <span style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'space-around'
                                                    }}>
                                                        <span>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</span>
                                                        <span>{item.subMenuLabel}</span>
                                                    </span>
                                                </Link>
                                            </div>
                                        );
                                    })}
                            </div>
                        </Link>}

                    </>
                ))}
            </List>
        </>
    );
};

export default MenuLink;