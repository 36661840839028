import { Box } from '@mui/system';
import React from 'react';
import { Link } from 'react-router-dom';

const NotPermitted = () => {
    return (
        <div>
            <Box sx={{textAlign: 'center'}}>
                <p style={{marginTop: '60px'}}>You have no permission to access this page. Backe to
                    <Link to="/">Home</Link></p>
            </Box>
        </div>
    );
};

export default NotPermitted;