import { Box, Button, ButtonGroup, Grid, TextField } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import InnerPagesTop from '../components/Dashboard/InnerPagesTop';
import { hidePageSectionLoader, showPageSectionLoader } from '../redux/actions';
import '../styles/pages/EditRoom.css';
import emptyValidationCheck from '../utility/emptyValidationCheck';
import responseErrorManage from '../utility/responseErrorManage';
import MuiSectionLoader from '../utilityComponents/MuiSectionLoader/MuiSectionLoader';

const PropertyEdit = () => {
    // shafiq code . start // 
    const { id } = useParams();
    // const [propertyId, setPropertyId] = useState('')
    const [propertyName, setPropertyName] = useState('')
    const [propertyEmail, setPropertyEmail] = useState('')
    const [propertPhone, setPropertPhone] = useState('')
    const [propertyCityStateCountry, setPropertyCityStateCountry] = useState('')
    const [propertyStreetAddress, setPropertyStreetAddress] = useState('')
    const [propertyZipCode, setPropertyZipCode] = useState('')

    const [managerName, setManagerName] = useState('')
    const [managerEmail, setManagerEmail] = useState('')
    const [managerPassword, setManagerPassword] = useState('')
    const [managerInitialEmail, setManagerInitialEmail] = useState('')

    const dispatch = useDispatch();


    // get user info from redux . 
    const currentPropertyId = useSelector(state => state.currentPropertyId);
    const authUserPermission = useSelector(state => state.authUserPermission);



    // shafiq code . end // 




    useEffect(() => {
        getPropertyDetails()
        getPropertyOwnerDetails()
    }, [currentPropertyId])
    useEffect(() => {
        getPropertyDetails()
        getPropertyOwnerDetails()
    }, [])

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })


    const getPropertyOwnerDetails = () => {
        let propertyId = id;
        if (authUserPermission === 'admin') {
            propertyId = currentPropertyId;
        }
        axios.get(`property/manager-details/${propertyId}`).then(res => {
            console.log("manager_info", res.data)

            setManagerEmail(res.data.data.email)
            setManagerName(res.data.data.name)
            setManagerInitialEmail(res.data.data.email)


        }).catch(errors => {
            responseErrorManage(errors)
        })
            .finally(f => {


            })

    }



    const onSubmit = (e) => {
        e.preventDefault();

        // validation blocks
        let isValidationFailed = false;
        isValidationFailed = emptyValidationCheck(propertyName, "Property Name", isValidationFailed)
        isValidationFailed = emptyValidationCheck(propertyEmail, "Email Address", isValidationFailed)
        isValidationFailed = emptyValidationCheck(propertPhone, "Phone Number", isValidationFailed)
        isValidationFailed = emptyValidationCheck(propertyCityStateCountry, "City, State, Country", isValidationFailed)
        isValidationFailed = emptyValidationCheck(propertyStreetAddress, "Street Address", isValidationFailed)
        isValidationFailed = emptyValidationCheck(propertyZipCode, " Zip Code / Postal Code", isValidationFailed)
        // isValidationFailed = emptyValidationCheck(name, "Manager Name", isValidationFailed)
        // isValidationFailed = emptyValidationCheck(email, "Manager Email", isValidationFailed)
        // isValidationFailed = emptyValidationCheck(password, "Password",isValidationFailed )
        if (isValidationFailed) { return "" };
        // validation blocks

        // prepareTheData
        const formData = {
            property_name: propertyName,
            property_email: propertyEmail,
            property_phone: propertPhone,
            property_city_state_country: propertyCityStateCountry,
            property_street_address: propertyStreetAddress,
            property_zip_code: propertyZipCode,

        }
        // prepareTheData //
        // updateUserInfo()

        // console.log(form)
        let propertyId = id;
        if (authUserPermission === 'admin') {
            propertyId = currentPropertyId;
        }
        dispatch(showPageSectionLoader("Property Updating..."))
        axios.put(`property/${propertyId}`, formData).then(res => {
            console.log("property_data_101", res.data);
            dispatch(hidePageSectionLoader())

        }).catch(e => {
            alert('error.')
        })
            .finally(f => {
                dispatch(hidePageSectionLoader())
                Toast.fire({
                    icon: 'success',
                    title: 'Property Settings Updated'
                });
            })
    }

    const getPropertyDetails = () => {
        let propertyId = id;
        if (authUserPermission === 'admin') {
            propertyId = currentPropertyId;
        }
        dispatch(showPageSectionLoader())
        axios.get(`property/${propertyId}`)
            .then(res => {
                console.log("property_data_edit_page_101", res.data.data);
                // setRoomInfo(res.data.data)
                setPropertyName(res.data.data.property_name)
                setPropertyEmail(res.data.data.property_email)

                setPropertPhone(res.data.data.property_phone)
                setPropertyCityStateCountry(res.data.data.property_city_state_country)
                setPropertyStreetAddress(res.data.data.property_street_address)
                setPropertyZipCode(res.data.data.property_zip_code)

                // setName(res.data.data.name)
                // setEmail(res.data.data.email)

                dispatch(hidePageSectionLoader())
            })
            .catch(errors => {
                dispatch(hidePageSectionLoader())
                responseErrorManage(errors)
            })
    }
    // load user and room data
    // useEffect(() => {

    //     getPropertyDetails()

    // }, [])

    // console.log("roomInfo_test_101", roomInfo)
    // load time data
    const [timeZoneData, setTimeZoneData] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const res = await fetch('/json/time.json');
            const data = await res.json();
            setTimeZoneData(data);
            console.log("time data 101", data)
        }
        fetchData();
    }, []);

    const updateManagerInfo = () => {
        let propertyId = id;
        if (authUserPermission === 'admin') {
            propertyId = currentPropertyId;
        }
        let mangerFormData = { 
           
            "name": managerName
        }
        if(managerPassword !== '') {
            mangerFormData['password'] = managerPassword;
        }
        if(managerInitialEmail !== managerEmail){
            mangerFormData['email'] = managerEmail;
        }
        dispatch(showPageSectionLoader("Manager info updating..."))
        axios.put(`property/manger-info-update/${propertyId}`, mangerFormData).then(res => {
            console.log("property_data_101", res.data);
            dispatch(hidePageSectionLoader())
            Toast.fire({
                icon: 'success',
                title: 'Manager Info updated'
            });

        }).catch(errors => {
            alert('error.')
            responseErrorManage(errors)
        })
            .finally(f => {
                dispatch(hidePageSectionLoader())
               
            })
    }
    const pageInfo = { title: 'Property Settings', btn1: 'cancel', btn2: 'save', onClick: onSubmit };
    return (
        <MuiSectionLoader>
            <div className='property-settings'>
                <InnerPagesTop
                    pageInfo={pageInfo}
                ></InnerPagesTop>
                <section >
                    <Box className='edit-box'>
                        <Grid container>
                            <Grid item
                                lg={3}
                                md={3}
                                sm={12}>
                                <h2 className="edit-box-title">
                                    Business Info
                                </h2>
                                <p className='edit-box-subtitle'>Your contact info will appear in guest’s booking confirmation email.</p>
                            </Grid>
                            <Grid item
                                xs={12}
                                md={7}>
                                <div style={{ width: "100%" }}>
                                    <Grid container sx={{
                                        alignItems: 'flex-end'
                                    }}>
                                        <Grid item xs={12} md={8}>
                                            <p style={{ margin: '0' }}><span style={{ color: '#3899ec', fontSize: '20px' }}>*</span> Property Name</p>
                                            <TextField
                                                size="small"
                                                id="outlined-helperText"
                                                label=""
                                                // defaultValue="Vila Lako"
                                                name='property_name'
                                                // defaultValue={roomInfo.property_name}
                                                value={propertyName}
                                                onChange={(e) => setPropertyName(e.target.value)}
                                                sx={{
                                                    width: '95%'
                                                }}
                                                className="full-width-input-field"
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            {/* <p style={{ margin: '0' }}>Property Type</p> */}
                                            {/* <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Hotel</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                size="small"
                                                name='property_type'
                                                value={form.property_type}
                                                defaultValue="Hotel"
                                                onChange={handleChange}
                                                sx={{
                                                    width: '90%'
                                                }}
                                                className="property-type-select"
                                            >
                                                <MenuItem value={10}>Single</MenuItem>
                                                <MenuItem value={20}>Queen</MenuItem>
                                                <MenuItem value={30}>King</MenuItem>
                                            </Select>
                                        </FormControl> */}
                                        </Grid>
                                    </Grid>
                                    <Grid container sx={{
                                        alignItems: 'flex-end'
                                    }}>
                                        <Grid item xs={12} md={8}>
                                            <p style={{ margin: '0' }}><span style={{ color: '#3899ec', fontSize: '20px' }}>*</span> Email Address</p>
                                            <TextField
                                                size="small"
                                                id="outlined-helperText"
                                                label=""
                                                name='property_email'
                                                value={propertyEmail}
                                                onChange={(e) => setPropertyEmail(e.target.value)}
                                                sx={{
                                                    width: '95%'
                                                }}
                                                className="full-width-input-field"
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <p style={{ margin: '0' }}>Phone Number</p>
                                            <TextField
                                                size="small"
                                                id="outlined-helperText"
                                                label=""
                                                name='property_phone'
                                                value={propertPhone}
                                                onChange={(e) => setPropertPhone(e.target.value)}
                                                sx={{
                                                    width: '90%'
                                                }}
                                                className="settings-phone-number full-width-input-field"
                                                required
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box className='edit-box'>
                        <Grid container>
                            <Grid item
                                lg={3}
                                md={3}
                                sm={12}>
                                <h2 className="edit-box-title">
                                    Address
                                </h2>
                            </Grid>
                            <Grid item
                                lg={7}
                                md={7}
                                sm={12}>
                                <div>
                                    <Grid item xs={12} md={12}>
                                        <p style={{ margin: '0' }}><span style={{ color: '#3899ec', fontSize: '20px' }}>*</span> City, State, Country</p>
                                        <TextField
                                            size="small"
                                            id="outlined-helperText"
                                            label=""
                                            name='property_city_state_country'
                                            value={propertyCityStateCountry}
                                            onChange={(e) => setPropertyCityStateCountry(e.target.value)}
                                            sx={{
                                                width: '100%'
                                            }}
                                        />
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12} md={7}>
                                            <p style={{ margin: '0' }}><span style={{ color: '#3899ec', fontSize: '20px' }}>*</span> Street Address</p>
                                            <TextField
                                                size="small"
                                                id="outlined-helperText"
                                                label=""
                                                name='property_street_address'
                                                value={propertyStreetAddress}
                                                onChange={(e) => setPropertyStreetAddress(e.target.value)}
                                                sx={{
                                                    width: '95%'
                                                }}
                                                className="settings-street-address"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={5}>
                                            <p style={{ margin: '0' }}>Zip Code / Postal Code</p>
                                            <TextField
                                                size="small"
                                                id="outlined-helperText"
                                                label=""
                                                name='property_zip_code'
                                                value={propertyZipCode}
                                                onChange={(e) => setPropertyZipCode(e.target.value)}
                                                sx={{
                                                    width: '100%'
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    </Box>

                    {/* <Box className='edit-box'>
                    <Grid container>
                        <Grid item
                            xs={12}
                            md={3}
                        >

                            <h2 className="edit-box-title">
                                Regional Settings
                            </h2>
                            <p className='edit-box-subtitle'>Choose the settings relevant to your region.</p>
                        </Grid>
                        <Grid item
                            lg={7}
                            md={7}
                            sm={12}>
                            <div>
                                <Grid container>
                                    <Grid item xs={12} md={8}>
                                        <p style={{ margin: '0' }}><span style={{ color: '#3899ec', fontSize: '20px' }}>*</span> Time Zone</p>
                                        <FormControl fullWidth>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                size="small"
                                                sx={{
                                                    width: '90%'
                                                }}
                                                name="property_time_zone_id"
                                                value={form.property_time_zone_id}
                                                onchange={handleChange}
                                                className="time-zone-select"
                                            >
                                                {
                                                    timeZoneData.map((item, index) => <MenuItem
                                                        key={index} id={item.id} >{item.time}</MenuItem>)
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <p style={{ margin: '0' }}>Measurement Units</p>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Square feet</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                size="small"
                                                sx={{
                                                    width: '100%'
                                                }}
                                                name="property_measurement_units"
                                                value={form.property_measurement_units}
                                                onChange={(e)=>handleChange(e)}
                                            >
                                                <MenuItem value={10}>Square feet</MenuItem>
                                                <MenuItem value={20}>Square metre</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                            </div>
                            <div>
                                <Grid container>
                                    <Grid item lg={8} md={8} sm={12}>
                                        <p style={{ margin: '0' }}>Your Weekend Nights Are:</p>
                                        <FormGroup sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between'
                                        }}>
                                            <FormControlLabel control={<Checkbox />} label="Thu" />
                                            <FormControlLabel control={<Checkbox />} label="Fri" />
                                            <FormControlLabel control={<Checkbox defaultChecked />} label="Sat" />
                                            <FormControlLabel control={<Checkbox defaultChecked />} label="Sun" />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <p style={{ margin: '0' }}><span style={{ color: '#3899ec', fontSize: '20px' }}>*</span> Primary Language</p>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">English</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                size="small"
                                                name="property_primary_language_id"
                                                value={form.property_primary_language_id}
                                                onchange={handleChange}
                                                sx={{
                                                    width: '100%'
                                                }}
                                            >
                                                <MenuItem value={10}>English</MenuItem>
                                                <MenuItem value={20}>Spanish</MenuItem>
                                                <MenuItem value={20}>Hindi</MenuItem>
                                                <MenuItem value={20}>French</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </Box> */}
                    {/* <Box className='edit-box'>
                    <Grid container>
                        <Grid item
                            xs={12}
                            md={3}>
                            <h2 className="edit-box-title">
                                Length of Stay
                            </h2>
                            <p className='edit-box-subtitle'>What are your stay requirements?</p>
                        </Grid>
                        <Grid item
                            xs={12}
                            md={7}>
                            <div>
                                <Grid container>
                                    <Grid xs={12} md={4}>
                                        <p style={{ margin: '0' }}>Check-In Time</p>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">02:00 PM</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                size="small"
                                                sx={{
                                                    width: '95%'
                                                }}
                                                name="property_check_in_time"
                                                value={form.property_check_in_time}
                                                onchange={handleChange}
                                                className="full-width-input-field"
                                            >
                                                <MenuItem value={10}>02:00 PM</MenuItem>
                                                <MenuItem value={20}>03:00 PM</MenuItem>
                                                <MenuItem value={20}>04:00 PM</MenuItem>
                                                <MenuItem value={20}>05:00 PM</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <p style={{ margin: '0' }}>Check-Out Time</p>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">02:00 PM</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                size="small"
                                                name="property_check_out_time"
                                                value={form.property_check_out_time}
                                                onchange={handleChange}
                                                sx={{
                                                    width: '100%'
                                                }}
                                            >
                                                <MenuItem value={10}>02:00 PM</MenuItem>
                                                <MenuItem value={20}>03:00 PM</MenuItem>
                                                <MenuItem value={20}>04:00 PM</MenuItem>
                                                <MenuItem value={20}>05:00 PM</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid xs={12} md={2}>
                                        <p style={{ margin: '0' }}>Min Nights</p>
                                        <TextField
                                            size="small"
                                            id="outlined-number"
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="property_min_night"
                                            value={form.property_min_night}
                                            onchange={handleChange}
                                            sx={{
                                                width: '90%',
                                            }}
                                            className="full-width-input-field"
                                        />
                                    </Grid>
                                    <Grid xs={12} md={2}>
                                        <p style={{ margin: '0' }}>Max Nights</p>
                                        <TextField
                                            size="small"
                                            id="outlined-number"
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="property_max_night"
                                            value={form.property_max_night}
                                            onchange={handleChange}
                                            sx={{
                                                width: '90%',
                                            }}
                                            className="full-width-input-field"
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </Box> */}
                    <div>
                        <Grid container className="edit-room-bottom">
                            <Grid xs={12} sm={6} item><p><span style={{ color: '#3899ec' }}>*</span><small> Indicates mandatory fields</small></p></Grid>
                            <Grid className='edit-room-bottom-btns' xs={12} sm={6} item sx={{ textAlign: 'end' }}>
                                <ButtonGroup
                                    orientation="vertical"
                                    aria-label="vertical outlined button group"
                                >
                                    <Button
                                        className='room-typy-topbar-btn preview-btn'
                                        sx={{
                                            borderRadius: '20px',
                                            backgroundColor: '#fff',
                                            border: "none",
                                            padding: '6px 28px',
                                            textTransform: 'capitalize',
                                            fontSize: '16px',
                                            color: '#3899ec',
                                            marginRight: '20px'
                                        }}
                                    >cancel</Button>
                                </ButtonGroup>
                                <ButtonGroup
                                    orientation="vertical"
                                    aria-label="vertical contained button group"
                                    variant="contained"
                                    sx={{ boxShadow: 'none' }}
                                >
                                    <Button
                                        className='room-typy-topbar-btn create-new-btn'
                                        onClick={onSubmit}
                                        sx={{
                                            borderRadius: '20px',
                                            backgroundColor: '#3899ec',
                                            border: "none",
                                            padding: '6px 28px',
                                            textTransform: 'capitalize',
                                            fontSize: '16px'
                                        }}
                                    >
                                        save
                                    </Button>
                                </ButtonGroup>
                            </Grid>
                        </Grid>
                    </div>

                </section>
                {
                    authUserPermission==='admin' ? 
                    <section>
                    <Box className='edit-box'>
                        <Grid container>
                            <Grid item
                                lg={3}
                                md={3}
                                sm={12}>
                                <h2 className="edit-box-title">
                                    Manager Info
                                </h2>
                            </Grid>
                            <Grid item
                                xs={12}
                                md={9}>
                                <div style={{ width: "100%" }}>
                                    <Grid container sx={{
                                        alignItems: 'flex-end'
                                    }}>
                                        <Grid item xs={12} md={3}>
                                            <p style={{ margin: '0' }}><span style={{ color: '#3899ec', fontSize: '20px' }}>*</span> Manager Name 2</p>
                                            <TextField
                                                size="small"
                                                id="outlined-helperText"
                                                label=""
                                                defaultValue="Vila Lako"
                                                name='name'
                                                value={managerName}
                                                onChange={(e) => setManagerName(e.target.value)}
                                                sx={{
                                                    width: '95%'
                                                }}
                                                className="full-width-input-field"
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <p style={{ margin: '0' }}>Manager Email</p>
                                            <TextField
                                                size="small"
                                                id="outlined-helperText"
                                                label=""
                                                name='email'
                                                value={managerEmail}
                                                onChange={(e) => setManagerEmail(e.target.value)}
                                                type="email"
                                                sx={{
                                                    width: '90%'
                                                }}
                                                className="settings-phone-number full-width-input-field"
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <p style={{ margin: '0' }}>Manager Password</p>
                                            <TextField
                                                size="small"
                                                id="outlined-helperText"
                                                label=""
                                                autoComplete='off'
                                                name='password'
                                                value={managerPassword}
                                                onChange={(e) => setManagerPassword(e.target.value)}
                                                type="password"
                                                sx={{
                                                    width: '90%'
                                                }}
                                                className="settings-phone-number full-width-input-field"
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <ButtonGroup
                                                orientation="vertical"
                                                aria-label="vertical contained button group"
                                                variant="contained"
                                                sx={{ boxShadow: 'none' }}
                                            >
                                                <Button
                                                    className='room-typy-topbar-btn create-new-btn'
                                                    onClick={updateManagerInfo}
                                                    sx={{
                                                        borderRadius: '20px',
                                                        backgroundColor: '#3899ec',
                                                        border: "none",
                                                        padding: '6px 28px',
                                                        textTransform: 'capitalize',
                                                        fontSize: '16px'
                                                    }}
                                                >
                                                    save
                                                </Button>
                                            </ButtonGroup>
                                        </Grid>

                                    </Grid>

                                </div>
                            </Grid>
                        </Grid>
                    </Box>
                </section>
                :""
                }
               
            </div>
        </MuiSectionLoader>
    );
};

export default PropertyEdit;