const muiDateFiled = () => {

}

export const bdDateFormat = (newDate) => {
    const newTempDate = new Date(newDate);
    const tempDate =
        (newTempDate.getDate() < 10 ? `0${newTempDate.getDate()}/` : `${newTempDate.getDate()}/`) +
        (newTempDate.getMonth() < 10 ? `0${newTempDate.getMonth() + 1}/` : `${newTempDate.getMonth() + 1}/`) +
        newTempDate.getFullYear();
    return tempDate;
}

export const convertJsToMySqlDateFormat= (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

export const laravelToJsDateFormat = (serverDate) => {
    const newTempDate = new Date(serverDate);
    const newDate =
        (newTempDate.getMonth() < 10
            ? `0${newTempDate.getMonth() + 1}/`
            : `${newTempDate.getMonth() + 1}/`) +
        (newTempDate.getDate() < 10
            ? `0${newTempDate.getDate()}/`
            : `${newTempDate.getDate()}/`) +
        newTempDate.getFullYear();
    return newDate;
}


export const jsdateToUnixTimestamp = (jsDate) =>{
    let jsDateParse = Date.parse(jsDate);
    return jsDateParse / 1000
}
export const unixTimestampToJsdate = (unixTimestamp) =>{
    return  new Date(unixTimestamp * 1000);; 
}
export default muiDateFiled; 