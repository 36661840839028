import { toast } from 'react-toastify';

const responseErrorManage = (errors) => {
    console.log("Hanlde_responseErrorManage", errors,errors.response.data.message , "-end" );
    if (errors.response.data.message !== "") {
        toast.error(errors.response.data.message); // unauthenticated
    } else {
        // console.log(errors.response.data, "data", typeof errors.response.data)
    }
    if (errors.response.status === 400) {
        // request filed missing
        const requiredFiledsMsg = errors.response.data.errorMsg;
        console.log("responseErrorManage_inside400Status",requiredFiledsMsg)
        
        if(typeof requiredFiledsMsg === "string"){
            toast.warning(requiredFiledsMsg)
        }else{
            console.log("responseErrorManage_inside400Status_notstring", typeof requiredFiledsMsg)
            for (const errorTitle in requiredFiledsMsg) {
                console.log(`looping_${errorTitle}: ${requiredFiledsMsg[errorTitle]}`, typeof requiredFiledsMsg[errorTitle]);
                // const itemErros = requiredFiledsMsg[errorTitle]; // i m not sure 
                // itemErros.map(error => toast.warning(error)); // i m not sure 
                // toast(requiredFiledsMsg[errorTitle])
                // toast.warning("The email has already been taken.")
                toast.warning(`${requiredFiledsMsg[errorTitle]}`)
            }
        }
        
    }
    
    console.log("Hanlde_responseErrorManage_end" );
};

export default responseErrorManage;