import React from 'react';

const DashboardWidget = () => {
    return (
        <div>
            Content from widget
        </div>
    );
};

export default DashboardWidget;