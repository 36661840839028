import React, { useRef } from "react";
import CrudDataList from "../../components/common/CrudDataList/CrudDataList";
import CrudCreate from "./CrudCreate";

const UserManagement = () =>{
    // config
    const crudBaseUrl = "/users"; // changeHere 
    const refreshDataList = useRef();
    const loadItemDetails = useRef();
    // config //
  
    // datatable
    const columnList = [
      // changeColumnName
      {
        name: "ID",
        selector: (row) => `${row.id}`,
      },
      {                                                             // changeHere
        name: "Name",
        selector: (row) => row.name,
        sortable: true,
      },
      {                                                             // changeHere
        name: "Email",
        selector: (row) => row.email,
        sortable: true,
      },
    
    ];
    // datatable //
  
    // call from createForm after save/update to datalist to reload list
    const handleRefreshDataList = () => {
      refreshDataList.current.getDataList();
    };
    // call from createForm after save/update to datalist to reload list//
  
    // call from datatable by edit button  to creatForm to load itemDetails
    const handleEdit = (item) => {
      loadItemDetails.current.getItemDetails(crudBaseUrl + "/" + item.id);
    };
    // call from datatable by edit button  to creatForm to load itemDetails //
  
    return (
      <>
        {/* //                                                                                 changeHere */}
        <h1 className="data-management-page-title" style={{
          marginLeft: '20px'
        }}>Add User</h1>
  
        {/* Create Form  */}
        <CrudCreate
          handleRefreshDataList={handleRefreshDataList}
          crudBaseUrl={crudBaseUrl}
          ref={loadItemDetails}
        />
        {/* Create Form  */}
        {/* List Data  / Delete data */}
        <CrudDataList
          localKeyName="dentrino-medicine-dose"
          localItemStrcture={{ label: "dose_english", value: "id", label_2: "dose_bangla" }}
          dataListSource={crudBaseUrl}
          columnList={columnList}
          handleEdit={handleEdit}
          ref={refreshDataList}
        ></CrudDataList>
        {/* List Data  / Delete data */}
        {/* Edit Data  */}
        <div></div>
      </>
    );
  };
export default UserManagement;