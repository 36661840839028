import axios from "axios";
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import useAuth from "./hooks/useAuth";
import DashboardLayout from "./layouts/Dashboard/DashboardLayout";
import ActivityLog from "./pages/ActivityLog/ActivityLog";
import CalenderRooms from "./pages/CalenderRooms";
import CreateRoom from "./pages/CreateRoom";
import Dashboard from "./pages/Dashboard";
import NotPermitted from "./pages/DefaultPages/NotPermitted/NotPermitted";
import Login from "./pages/Login";
// import PropertyEdit from "./pages/propertyEdit";
// import PropertyEdit from "./pages/propertyEdit";
import PropertyEdit from "./pages/PropertyEditManage";
import PropertyList from "./pages/PropertyManagement/PropertyList/PropertyList";
import PropertySettings from "./pages/PropertySettings";
import EditReservation from "./pages/Reservation/EditReservation";
import Reservation from "./pages/Reservation/Reservation";
import ReservationList from "./pages/Reservation/ReservationList";
import ReservationListMobile from "./pages/Reservation/ReservationListMobile";
import RoomCreate from "./pages/RoomManagement/RoomCreate/RoomCreate";
import RoomEdit from "./pages/RoomManagement/RoomEdit/RoomEdit";
import RoomEditTwo from "./pages/RoomManagement/RoomEdit/RoomEditTwo";
import RoomList from "./pages/RoomManagement/RoomList/RoomList";
import PasswordChange from "./pages/UserManagement/PasswordChange";
import UserManagement from "./pages/UserManagement/UserManagement";
import UserProfile from "./pages/UserManagement/UserProfile";
import './styles/responsive.css';
import master from "./utility/master";



function App() {
  const dispatch = useDispatch();
  // axios.defaults.baseURL = `http://room-management-server.test/api/`;
  // axios.defaults.baseURL = `https://ss.hotel-management.rooberp.com/api/`;
  axios.defaults.baseURL = `${master()}/api/`;
  axios.defaults.headers.post['Accept'] = 'application/json"';
  axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    console.log("response_interceptors_response", response)
    return response;
  }, function (error) {
    console.log("response_interceptors_error", error)
    console.log("response_interceptors_error_2", error.response.status, error.response.data.message)
    if (error.response.status === 401 && error.response.data.message === "Unauthenticated.") {
      // logout 
      handleLogoutOnClik()
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  });
  const handleLogoutOnClik = () => {
    localStorage.clear();
    // logout();
    dispatch({
      type: "login_status_update",
      payload: false
    })
    dispatch({
      type: "loggedinUserInfo_update",
      payload: {}
    })
    dispatch({
      type: "authUserPermission_update",
      payload: ""
    })
    dispatch({
      type: "tokenValue_update",
      payload: false
    })
    //     alert("hello")

    // dispatch(loggedInUser(true))
    // setOpen(false);
  };



  const tokenValue = useSelector(state => state.tokenValue)
  const permission = useSelector(state => state.authUserPermission)
  console.log("token_vaule", tokenValue)
  axios.defaults.headers.common['Authorization'] = `Bearer ${tokenValue}`;
  // useEffect(() => {
  // console.log("tokenValue-101", tokenValue)
  // axios.defaults.headers.common['Authorization'] = `Bearer ${tokenValue}`;
  // },[tokenValue])

  // let isLoggedIn = useAuthHookRedux()
  let isLoggedIn = useAuth()
  // const permission = 'admin'; 
  return (
    <div >
      <ToastContainer />
      <Routes>

        <Route path="/login" element={<Login />}> </Route>
        <Route path="/dashboard" element={<DashboardLayout />}>
          {/* <Route path="/" element={<PrivateOutlet />}> */}
          <Route path="home" element={<ReservationList />} />
          <Route path="calender" element={<Dashboard />} />
          {/* <Route path="room-types" element={permission==='admin' ? <RoomTypes /> : <Navigate to="/no-permission" />  } /> */}
          <Route path="edit-room/:id" element={<RoomEditTwo />} />
          <Route path="no-permission" element={<NotPermitted />}> </Route>
          <Route path="property-settings" element={<PropertySettings />} />
          <Route path="edit-property/:id" element={<PropertyEdit />} />
          <Route path="edit-reservation/:id" element={<EditReservation />} />
          <Route path="user-profile" element={<UserProfile />} />
          <Route path="password-change" element={<PasswordChange />} />
          <Route path="property-list" element={<PropertyList />} />
          <Route path="reservation-list" element={<ReservationList />} />
          <Route path="reservation-list-mobile" element={<ReservationListMobile />} />
          <Route path="calender-rooms" element={<CalenderRooms />} />

          <Route path="reservation" element={<Reservation />}>
            <Route path=":sdate/:edate" element={<Reservation />} />
          </Route>
          <Route path="room-create" element={<RoomCreate />} />
          <Route path="room-list" element={<RoomList />} />
          <Route path="test" element={<CreateRoom />} />
          <Route path="user-management" element={permission === 'admin' ? <UserManagement /> : <Navigate to="/dashboard/no-permission" />} />
          <Route path="activity-log" element={permission === 'admin' ? <ActivityLog /> : <Navigate to="/dashboard/no-permission" />} />
        </Route>
        <Route
          path="*"
          element={<Navigate to={isLoggedIn ? "/dashboard/home" : "/login"} />}
        />


        {/* <Route path="/" element={<PrivateRoute> <Homepage /></PrivateRoute>} />
        <Route path="/dashboard" element={<PrivateRoute> <Dashboard /></PrivateRoute>} />
        <Route path="/dashboard/room-types" element={<PrivateRoute><RoomTypes /> </PrivateRoute>} />
        <Route path="/dashboard/edit-room" element={<PrivateRoute><EditRoom /> </PrivateRoute>} />
        <Route path="/dashboard/property-settings" element={<PrivateRoute><PropertySettings /></PrivateRoute>} />
        <Route path="/dashboard/calender-rooms" element={<PrivateRoute><CalenderRooms /></PrivateRoute>} />
        <Route path="/dashboard/admin" element={<PrivateRoute><Admin /></PrivateRoute>} />
        <Route path="/dashboard/booking" element={<PrivateRoute><Booking /></PrivateRoute>} />
        <Route path="/dashboard/room-create" element={<PrivateRoute><RoomCreate /></PrivateRoute>} />
        <Route path="/dashboard/room-list" element={<PrivateRoute> <RoomList /></PrivateRoute>} />
        <Route path="/dashboard/test" element={<PrivateRoute> <CreateRoom /></PrivateRoute>} />
        <Route path="/dashboard/activity-log" element={<PrivateRoute> <ActivityLog /></PrivateRoute>} /> */}

      </Routes>
    </div>
  );
}

export default App;
