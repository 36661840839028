import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Grid } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CalenderRoomItem from '../components/CalenderRooms/CalenderRoomItem';
import ReservationPopUp from '../components/CalenderRooms/ReservationPopUp';
import ReservedPopup from '../components/CalenderRooms/ReservedPopup';
import InnerPagesTop from '../components/Dashboard/InnerPagesTop';
import { hidePageSectionLoader, showPageSectionLoader } from '../redux/actions';
import '../styles/pages/CalencerRooms.css';
import { convertJsToMySqlDateFormat } from '../utility/dateConversion';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

const CalenderRooms = () => {
    const currentPropertyId = useSelector(state => state.currentPropertyId);
    const [calendarPickerOpen, setCalendarPickerOpen] = useState(false);
    const [todayDate, setTodayDate] = useState(new Date());
    const [firstClick, setFirstClick] = useState(false);
    const [secondClick, setSecondClick] = useState(false);
    const [showPopUp, setShowPopup] = useState(false)
    const [showReservedPopup, setShowReservedPopup] = useState(false)
    const [clickedItems, setClickedItems] = useState([])
    const [reservedData, setReservedData] = useState({})
    const [mouseOverItems, setMouseOverItems] = useState([])
    const [startDate, setStartDate] = useState({})
    const [endDate, setEndDate] = useState({})
    const navigate = useNavigate();

    const [selectedRowId, setSelectedRowId] = useState(0); 
    const handleReservationNavigate = ()=>{
        navigate('/dashboard/reservation')
    }
    const dispatch = useDispatch();

    const pageInfo = { title: 'Reservation Calendar', btn2: '+ New Reservation' , onClick:handleReservationNavigate};
    const [rooms, setRooms] = useState([]);
    const rooms_old = [
        { title: 'Family Suite 101', subTitle: 'Family Suite 101', rowId: "a", reserved: [{ reservation_owner: 'Mr A', start_from: '14-6-2022', check_out_date: '20-6-2022 ', status: 'reserved'}] },
        { title: 'Triple Room 102 1', subTitle: 'Triple Room 102', rowId:  "b", reserved: [{reservation_owner: 'Mr B', start_from: '15-6-2022',check_out_date: '20-6-2022 ', status: 'checked-in'}, {reservation_owner: 'Mr D', start_from: '21-6-2022',check_out_date: '30-6-2022 ', status: 'checked-in'}]},
        { title: 'Single Room 201', subTitle: 'Single Room 201', rowId:  "c", reserved: [{ reservation_owner: 'Mr C', start_from: '14-6-2022',check_out_date: '25-6-2022 ', status: 'checked-out'}]},
        { title: 'Double Room 202', subTitle: 'Double Room 202', rowId:  "d"},
        { title: 'Junior Suite 203 1', subTitle: 'Junior Suite 203', rowId:  "e"},
        { title: 'Junior Suite 204 1', subTitle: 'Junior Suite 204', rowId:  "f"},
        { title: 'Classic Triple Room 205', subTitle: 'Classic Triple Room 205', rowId:  "g"},
        { title: 'Main Suite 206', subTitle: 'Main Suite 206', rowId:  "h"},
        { title: 'Alpin Room 301 1', subTitle: 'Alpin Room 301', rowId:  "i"},
        { title: 'Alpin Room 302 1', subTitle: 'Alpin Room 302', rowId:  "j"},
        { title: 'Alpin Room 303 1', subTitle: 'Alpin Room 303', rowId:  "k"},
    ]


    const getReservationListForMobile = (todayDate) => {
        const start_date = new Date(todayDate);
        if(todayDate){
            start_date.setDate(start_date.getDate() - 2)
        }
        

        const startDatePhp = convertJsToMySqlDateFormat(start_date)
        console.log("start_date_php_change",todayDate)
        console.log("start_date_php_change2",startDatePhp)

        dispatch(showPageSectionLoader("Getting Data..."))
        axios
        // .get("/json/reservation_list2.json",{baseURL: 'http://localhost:3000/'})
        // .get("/json/reservation_list.json",{baseURL: 'http://localhost:3000/'})

        .get(`/reservation-list-by-date-by-room/${startDatePhp}/${currentPropertyId}`)
        .then(response=>{
            dispatch(hidePageSectionLoader())
            console.log("getReservationListForMobile",response.data )
            setRooms(response.data )
        })
        .catch(errors=>{
            dispatch(hidePageSectionLoader())
        })
        .finally(()=>{

        })
    }

    useEffect(()=>{
        getReservationListForMobile(todayDate)
    },[todayDate])

    const minDate = new Date('2020-01-01T00:00:00.000');
    const maxDate = new Date('2034-01-01T00:00:00.000');

    console.log("clicked_items_test", clickedItems)
    console.log("hover_test", mouseOverItems)

    const [date, setDate] = React.useState(new Date());

    // calender dates
    
    const [calendarDate, setCalenderDate] = useState(new Date())
    useEffect(()=>{
        const displayDate = new Date(todayDate)
        displayDate.setDate(displayDate.getDate()-2)
        setCalenderDate(displayDate)
    }, [todayDate])

    const datePrevOne = new Date(todayDate)
    const datePrevTwo = new Date(todayDate)
    const dateTwo = new Date(todayDate)
    const dateThree = new Date(todayDate)
    const dateFour = new Date(todayDate)
    const dateFive = new Date(todayDate)
    const dateSix = new Date(todayDate)
    const dateSeven = new Date(todayDate)
    const dateEight = new Date(todayDate)
    const dateNine = new Date(todayDate)
    const dateTen = new Date(todayDate)

        datePrevOne.setDate(datePrevOne.getDate() - 1)
        datePrevTwo.setDate(datePrevTwo.getDate() - 2)
        dateTwo.setDate(dateTwo.getDate()+1);
        dateThree.setDate(dateThree.getDate()+2);
        dateFour.setDate(dateFour.getDate()+3);
        dateFive.setDate(dateFive.getDate()+4);
        dateSix.setDate(dateSix.getDate()+5);
        dateSeven.setDate(dateSeven.getDate()+6);
        dateEight.setDate(dateEight.getDate()+7);
        dateNine.setDate(dateNine.getDate()+8);
        dateTen.setDate(dateTen.getDate()+9);


    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const months = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

//   calendar function
const nextCalender = () => {
    const nextDate = new Date(todayDate);
    nextDate.setDate(nextDate.getDate()+12)
    setTodayDate(nextDate)
}

const prevCalender = () => {
    const prevDate = new Date(todayDate);
    prevDate.setDate(prevDate.getDate()-12)
    setTodayDate(prevDate)
}

const handlePickDate = date => {
    setTodayDate(date);
    setCalendarPickerOpen(false)
}

    return (
        <>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box sx={{
                padding: '40px 40px',
                backgroundColor: '#F0F4F7', //#F0F4F7 #992900
                maxWidth: '100vw',
                overflow: 'hidden',
                position: 'relative'
            }}
            className='calender-rooms'
            >
                <InnerPagesTop
                    pageInfo={pageInfo}
                ></InnerPagesTop>
                <section>
                    <div className="calender-room-container">
                        <Box container className="calender-day">
                            <Box item>
                                <span onClick={()=> prevCalender()} className='calender-left-arrow'><ArrowBackIosNewIcon /></span>
                                <h4 style={{
                                    textAlign: 'center',
                                    fontWeight: '400',
                                }}> {
                                    `${calendarDate.getDate()} ${months[calendarDate.getMonth()]}, ${calendarDate.getFullYear()}`
                                } <span onClick={()=> setCalendarPickerOpen(!calendarPickerOpen)}><CalendarMonthOutlinedIcon /></span></h4>
                                {
                                    calendarPickerOpen && <div className="calnendar-container">
                                    <Calendar onChange={(selected) => handlePickDate(selected)} value={todayDate} />
                                </div>
                                }
                                <span onClick={()=> nextCalender()} className='calender-right-arrow'><ArrowForwardIosIcon /></span>
                            </Box>
                        </Box>
                        <Box className='calender-dates'>
                            <Grid container>
                                {/* blank space generator before done */}
                                <Grid sx={{
                                    borderRight: '1px solid #b6c1cd;',
                                }} item lg={3} md={3}>

                                </Grid>
                                <Grid item lg={9} md={9}>
                                    <div>
                                        {/*  Dates & Days Start*/}
                                        <Grid container>
                                            <Grid className='date-item' item lg={1} md={1}>
                                                <h6 className="calender-top-day">
                                                    {
                                                        days[datePrevTwo.getDay()].slice(0,3)
                                                    }
                                                </h6>
                                                <span className='calender-top-date'>{
                                                    datePrevTwo.getDate()
                                                }</span>
                                            </Grid>
                                            <Grid className='date-item' item lg={1} md={1}>
                                                <h6 className="calender-top-day">
                                                    {
                                                        days[datePrevOne.getDay()].slice(0,3)
                                                    }
                                                </h6>
                                                <span className='calender-top-date'>{
                                                    datePrevOne.getDate()
                                                }</span>
                                            </Grid>
                                            <Grid className='date-item' item lg={1} md={1}>
                                                <h6 className="calender-top-day">
                                                    {
                                                        days[todayDate.getDay()].slice(0,3)
                                                    }
                                                </h6>
                                                <span className='calender-top-date'>{
                                                    todayDate.getDate()
                                                }</span>
                                            </Grid>
                                            <Grid className='date-item' item lg={1} md={1}>
                                                <h6 className="calender-top-day">
                                                {
                                                        days[dateTwo.getDay()].slice(0,3)
                                                    }
                                                </h6>
                                                <span className='calender-top-date'>
                                                {
                                                    dateTwo.getDate()
                                                }
                                                </span>
                                            </Grid>
                                            <Grid className='date-item' item lg={1} md={1}>
                                                <h6 className="calender-top-day"> {
                                                        days[dateThree.getDay()].slice(0,3)
                                                    }</h6>
                                                <span className='calender-top-date'>{
                                                    dateThree.getDate()
                                                }</span>
                                            </Grid>
                                            <Grid className='date-item' item lg={1} md={1}>
                                                <h6 className="calender-top-day">{
                                                        days[dateFour.getDay()].slice(0,3)
                                                    }</h6>
                                                <span className='calender-top-date'>{
                                                    dateFour.getDate()
                                                }</span>
                                            </Grid>
                                            <Grid className='date-item' item lg={1} md={1}>
                                                <h6 className="calender-top-day">{
                                                        days[dateFive.getDay()].slice(0,3)
                                                    }</h6>
                                                <span className='calender-top-date'>{
                                                    dateFive.getDate()
                                                }</span>
                                            </Grid>
                                            <Grid className='date-item' item lg={1} md={1}>
                                                <h6 className="calender-top-day">{
                                                        days[dateSix.getDay()].slice(0,3)
                                                    }</h6>
                                                <span className='calender-top-date'>{
                                                    dateSix.getDate()
                                                }</span>
                                            </Grid>
                                            <Grid className='date-item' item lg={1} md={1}>
                                                <h6 className="calender-top-day">{
                                                        days[dateSeven.getDay()].slice(0,3)
                                                    }</h6>
                                                <span className='calender-top-date'>{
                                                    dateSeven.getDate()
                                                }</span>
                                            </Grid>
                                            <Grid className='date-item' item lg={1} md={1}>
                                                <h6 className="calender-top-day">
                                                {
                                                        days[dateEight.getDay()].slice(0,3)
                                                    }
                                                </h6>
                                                <span className='calender-top-date'>{
                                                    dateEight.getDate()
                                                }</span>
                                            </Grid>
                                            <Grid className='date-item' item lg={1} md={1}>
                                                <h6 className="calender-top-day">{
                                                        days[dateNine.getDay()].slice(0,3)
                                                    }</h6>
                                                <span className='calender-top-date'>{
                                                    dateNine.getDate()
                                                }</span>
                                            </Grid>
                                            <Grid className='date-item' item lg={1} md={1}>
                                                <h6 className="calender-top-day">{
                                                        days[dateTen.getDay()].slice(0,3)
                                                    }</h6>
                                                <span className='calender-top-date'>{
                                                    dateTen.getDate()
                                                }</span>
                                            </Grid>
                                        </Grid>
                                        {/*  Dates & Days end*/}
                                    </div>
                                </Grid>
                            </Grid>
                        </Box>
                        <>
                            {
                                rooms.map((room, index) => <CalenderRoomItem
                                    key={index}
                                    room={room}
                                    setShowPopup={setShowPopup}
                                    firstClick={firstClick}
                                    secondClick={secondClick}
                                    setFirstClick={setFirstClick}
                                    setSecondClick={setSecondClick}
                                    setClickedItems={setClickedItems}
                                    setMouseOverItems={setMouseOverItems}
                                    mouseOverItems={mouseOverItems}
                                    setShowReservedPopup={setShowReservedPopup}
                                    setReservedData={setReservedData}
                                    todayDate={todayDate}
                                    setStartDate={setStartDate}
                                    setEndDate={setEndDate}
                                    roomIndex={index}
                                    setSelectedRowId ={setSelectedRowId}
                                />)
                            }
                        </>
                    </div>
                    {/* <div style={{display: 'none'}} className="mobile-calender">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Grid container>
                                <Grid item xs={12} md={6}>
                                    <CalendarPicker date={date} onChange={(newDate) => setDate(newDate)} />
                                </Grid>
                            </Grid>
                        </LocalizationProvider>
                    </div> */}
                </section>
                {
                    showPopUp && <ReservationPopUp
                     setFirstClick={setFirstClick}
                     clickedItems={clickedItems}
                    setSecondClick={setSecondClick}
                    mouseOverItems={mouseOverItems}
                     setShowPopup={setShowPopup}
                     startDate={startDate}
                     endDate={endDate}
                     selectedRowId={selectedRowId}
                     />
                }
                {
                    showReservedPopup && <ReservedPopup 
                    reservedData={reservedData}
                    setShowReservedPopup={setShowReservedPopup}
                    />
                }
            </Box>
            </LocalizationProvider>
        </>
    );
};

export default CalenderRooms;