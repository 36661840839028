import LockIcon from '@mui/icons-material/Lock';
import PersonIcon from '@mui/icons-material/Person';
import { Box, Button, FormControl, IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import axios from 'axios';
import { useState } from 'react';
import MyComponent from 'react-fullpage-custom-loader';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

import logo from '../images/wixotel-log-black.png';
// import logo from '../images/demo-logo.png';
// import { Redirect} from "react-router-dom";
import '../styles/pages/Login.css';


const Login = () => {
    
    const isLoggedIn = useSelector(state => state.userLoginStatus);
    const dispatch = useDispatch();
    let navigate = useNavigate();
    
    
    // const navigate = useNavigate();

    const userLoginStatus = useSelector((state) => state.userLoginStatus)
    // console.log("user login state: 101 : from redux", userLoginStatus);

    // const history = useHistory();

    const [forgetPass, setForgetPass] = useState(false);
    const [loading, setLoading] = useState(false);


    const [form, setForm] = useState({
        email: '',
        password: '',
        errors: [],
    });

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    const handleChange = (e) => {

        setForm({ ...form, [e.target.name]: e.target.value })
        // console.log(form)
    }

    const _onSubmit = (e) => {
        e.preventDefault();
        alert("shafiq suhag")
        dispatch({ type: "login_status_update", payload: true });
        navigate("/dashboard");

    }
    const onSubmit = (e) => {

        e.preventDefault();

        setLoading(true);
        axios.post('/login', form)
            .then(res => {
                setLoading(false);



                console.log("test_102_data", res.data)
                console.log("test_102_status", res.status)
                if (res.status >= 200 && res.status < 3000) {
                    // console.log("cehck_case_1")
                    // localStorage.setItem("sohag", "hi2")

                    Toast.fire({
                        icon: 'success',
                        title: 'Login Success'
                    });
                    // console.log("cehck_case_2")
                    // localStorage token & user update 
                    // set token to localStorage
                    const token = res.data.token;
                    // localStorage.setItem("token_value", token);
                    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                    // console.log("cehck_case_3")
                    // set user to localStorage
                    const userInfo = res.data.user;
                    // localStorage.setItem("user", JSON.stringify(userInfo));
                    // localStorage.setItem("isLoggedIn", true);
                    // console.log("cehck_case_4")
                    
                    // Get user permission from json objet 
                    if (JSON.stringify(userInfo) !== "{}") {
                        dispatch({
                            type: "login_status_update",
                            payload: true
                        })
                        // console.log("cehck_case_5")
                        dispatch({
                            type: "tokenValue_update",
                            payload: token
                        })
                        // console.log("cehck_case_6", userInfo)
                        dispatch({
                            type: "loggedinUserInfo_update",
                            payload: userInfo
                        })
                        console.log("loggedinUserInfo_update- ", userInfo.permission)
                        dispatch({
                            type: "current_property_id_update",
                            payload: userInfo.property_id
                        })
                        dispatch({
                            type: "authUserPermission_update",
                            payload: userInfo.permission
                        })
                    }else{
                        dispatch({
                            type: "authUserPermission_update",
                            payload: ""
                        })
                    }
                    // console.log("cehck_case_8")
                    // permission based dashboard routing. 
                    if(userInfo.permission=== "admin"){
                        navigate("/dashboard/property-list");    
                    }else{
                        navigate("/dashboard/calender-rooms");
                    }
                    


                }
                else {
                    // localStorage.setItem("isLoggedIn", true);
                    // login failed
                    Toast.fire({
                        icon: 'error',
                        title: 'Email or password is incorrect'
                    });
                }
            })
            .catch(error => {
                setLoading(false);
                console.log("test_103_error", error);
                Toast.fire({
                    icon: 'error',
                    title: 'Email or password is incorrect'
                });
            })
    }

    return (
        <section
            className='login-page'
        >
            {loading && <MyComponent sentences={'wait checking'} />}
            <Box className='login-container'>
                <Box className="login-form-container">
                    <Box sx={{ textAlign: 'center' }}>
                        <img style={{
                            width: '200px',
                            marginBottom: '40px'
                        }}
                            src={logo}
                            alt="logo"
                        ></img>
                    </Box>
                    <form onSubmit={onSubmit}>
                        <FormControl style={{
                            marginBottom: '30px'
                        }} className='login-input'>
                            <OutlinedInput
                                value={form.email} name='email' onChange={handleChange}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <IconButton
                                            edge="start"
                                        >
                                            <PersonIcon />
                                        </IconButton>
                                    </InputAdornment>
                                }
                                placeholder="Email Address"
                                required
                            />
                        </FormControl>
                        <FormControl style={{
                            marginBottom: '20px'
                        }} className='login-input'>
                            <OutlinedInput value={form.password} required name='password' type="password" onChange={handleChange}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <IconButton
                                            edge="start"
                                        >
                                            <LockIcon />
                                        </IconButton>
                                    </InputAdornment>
                                }
                                placeholder="Password"
                            />
                        </FormControl>
                        {/* <Box className='login-form-checkbox'>
                            <FormControlLabel className='login-checkbox' control={<Checkbox />} label="Keep Me Logged In" />
                        </Box> */}
                        <FormControl style={{
                            marginBottom: '30px',
                            background: '#007DC0',
                            padding: '4px 0px'
                        }} className='login-input'>
                            <Button type="submit" style={{
                                color: '#fff',
                                fontSize: '20px',
                                fontWeight: '600'
                            }}>Log In  </Button>
                        </FormControl>
                    </form>
                    {/* <Box>
                        <span onClick={() => setForgetPass(!forgetPass)} style={{
                            color: '#fff',
                            textDecoration: 'none',
                            textTransform: 'uppercase',
                            cursor: 'pointer'
                        }}> Forgot Password? </span>
                    </Box> */}
                    {
                        forgetPass && <Box sx={{
                            marginTop: '60px'
                        }}>
                            <FormControl style={{
                                marginBottom: '30px'
                            }} className='login-input'>
                                <OutlinedInput
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <IconButton
                                                edge="start"
                                            >
                                                <PersonIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    placeholder="Your Account Email"
                                />
                            </FormControl>
                            <Button
                                style={{
                                    color: '#fff',
                                    fontSize: '16px',
                                    fontWeight: '400',
                                    background: '#007dc0',
                                    borderRadius: '0',
                                    padding: '12px 20px'
                                }}>Get Password Reset Link</Button>
                        </Box>
                    }
                </Box>
            </Box>
        </section>
    );
};

export default Login;