import { toast } from 'react-toastify';
const emptyValidationCheck = (valueName, labelName, previousValidationStatus) => {
    if(valueName==="" || !valueName){
        toast.warning(labelName+" field can not be empty")
        return true;
    }
    if(previousValidationStatus){
       
        return true; 
    }
    return false; 
}

export default emptyValidationCheck;