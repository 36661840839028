import AccountCircle from "@mui/icons-material/AccountCircle";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import SelectProperty from "./SelectProperty";


const TopBar = () => {
  const dispatch = useDispatch();

  const userInfo = useSelector(state => state.loggedinUserInfo);
  const currentPropertyId = useSelector(state => state.currentPropertyId);
  const authUserPermission = useSelector(state => state.authUserPermission);
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);


  const handleLogoutOnClik = () => {
    localStorage.clear();
    // logout();
    dispatch({
      type: "login_status_update",
      payload: false
    })
    dispatch({
      type: "loggedinUserInfo_update",
      payload: {}
    })
    dispatch({
      type: "authUserPermission_update",
      payload: ""
    })
    dispatch({
      type: "tokenValue_update",
      payload: false
    })
    //     alert("hello")

    // dispatch(loggedInUser(true))
    // setOpen(false);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClearCache = () => {
    localStorage.clear();
    window.location.reload(true);
    setAnchorEl(null);
    // alert("Local cached clear"); 

  }


  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6" noWrap component="div">
              {process.env.REACT_APP_APPNAME}
            </Typography>
            {/* <Typography className="site-name-heading" variant="subtitle2" noWrap component="div" sx={{  color: "#f7e2e2" }}>
              Hotel Management
            </Typography> */}
            <img width="200px" className='room-list-img' src={`/images/white-logo.png`} alt="" />
            {/* <img width="200px" className='room-list-img' src={`/images/wixotel-log.png`} alt="" /> */}
            {
              authUserPermission === 'admin' ? <Box className="select-property-desktop" >
                <SelectProperty />
              </Box> : <></>
            }

          </Box>

          {/* right side profile  */}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography className="user-name" variant="subtitle" noWrap component="div">
              Welcome, {window.innerWidth < 601 && <br />}{userInfo?.name}
            </Typography>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClearCache}>Clear Cache</MenuItem>
              <MenuItem onClick={handleLogoutOnClik}>Logout</MenuItem>
            </Menu>
          </Box>
          {/* right side profile  */}
        </Box>


        {/* {
          authUserPermission === 'admin' ? <Box className="select-property-mobile" sx={{ marginLeft: '-10px' }}>
            <SelectProperty />
          </Box> :
            <></>
        } */}

      </Box>

    </>
  );
};

export default TopBar;
