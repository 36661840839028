import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { Box, Button, ButtonGroup, Grid, ImageListItem, InputAdornment, TextField } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import { SpinnerCircular } from 'spinners-react';
import Swal from 'sweetalert2';
import { hidePageSectionLoader, showPageSectionLoader } from '../../../redux/actions/index';
import '../../../styles/pages/EditRoom.css';
import BaseUrl from '../../../utility/ApiBaseUrl';
import MuiSectionLoader from '../../../utilityComponents/MuiSectionLoader/MuiSectionLoader';


// uploader styles
// const thumbsContainer = {
//     display: 'flex',
//     flexDirection: 'row',
//     flexWrap: 'wrap',
//     marginTop: 16
// };

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};



const RoomCreate = () => {

    // others hooks
    // others hooks //


    // redux 
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const currentPropertyId = useSelector(state => state.currentPropertyId);
    // redux //

    // useState Declarations
    const [bed, setBed] = useState([])
    const [amenities, setAmenities] = useState([])
    const [isAnybedTypeQtySelected, setIsAnybedTypeQtySelected] = useState(true)
    const [loading, seLoading] = useState(false);
    const [inputAmenities, setIntupamenities] = useState([])
    const [amenitiesData, setAmenitiesData] = useState([]);
    const [files, setFiles] = useState([]);
    const [selectedAminites, setSelectedAminites] = useState([]);

    const [form, setForm] = useState({
        room_name: '',
        // room_units: '',
        room_max_guests: '',
        room_max_kids: '',

        bedtype_single: '',
        bedtype_queen: '',
        bedtype_king: '',
        bedtype_twin: '',
        bedtype_super_king: '',
        room_size: '',
        amenities: [],
        room_photo: '',
        room_description: '',
        room_per_night_price: '',
        errors: [],
        property_id: currentPropertyId
    });
    // useState Declarations //


    // methods 
    const Toast = Swal.mixin({
        toast: true,
        position: 'center',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value })
        // console.log(form)
    }
    const onSubmit = (e) => {

        console.log("test_files_101", files)
        // return ""
        // Check required fields -- start
        // checkRequiredField(form.room_name, "ROOM NAME")
        if (checkAllRequiredFields()) {
            return "";
        }
        // return console.log("helo");
        // Check required fields -- end

        let data = new FormData();
        data.append('room_name', form.room_name);
        // data.append('room_units', form.room_units);
        data.append('room_max_guests', form.room_max_guests);
        data.append('room_max_kids', form.room_max_kids);
        // data.append('room_bed_type_id', form.room_bed_type_id);
        data.append('bedtype_single', form.bedtype_single);
        data.append('bedtype_queen', form.bedtype_queen);
        data.append('bedtype_king', form.bedtype_king);
        data.append('bedtype_twin', form.bedtype_twin);
        data.append('bedtype_super_king', form.bedtype_super_king);
        data.append('room_size', form.room_size);
        data.append('room_amenities', JSON.stringify(selectedAminites));
        data.append('room_description', form.room_description);
        data.append('room_per_night_price', form.room_per_night_price);
        data.append('property_id', form.property_id);
        // data.append('room_photo', document.getElementById('file').files[0])
        if (files.length > 0) {
            data.append('room_photo', files[0], files[0].name)

        }



        e.preventDefault();
        console.log(form)
        seLoading(true);
        dispatch(showPageSectionLoader())
        axios.post(BaseUrl() + 'room', data).then(res => {
            seLoading(false);
            console.log(res.data);
            dispatch(hidePageSectionLoader())

            // clear form data 
            setForm({
                room_name: '',
                room_units: '',
                room_max_guests: '',
                room_max_kids: '',

                bedtype_single: '',
                bedtype_queen: '',
                bedtype_king: '',
                bedtype_twin: '',
                bedtype_super_king: '',
                room_size: '',
                amenities: [],
                room_photo: '',
                room_description: '',
                room_per_night_price: '',
            })
            // clear form data 
            Toast.fire({
                icon: 'success',
                title: 'Room Added'
            });
            navigate(-1)

        }).catch(e => {
            seLoading(false);
            alert('error.')
            navigate(-1)
        })
            .finally(f => {

                dispatch(hidePageSectionLoader())
                seLoading(false);


            })
    }
    const checkAllRequiredFields = () => {
        let isExistRequiredFiledsError = false;
        if (!form.room_name) {
            isExistRequiredFiledsError = true;
        }
        // else if (!form.room_units) {
        //     isExistRequiredFiledsError = true;
        // }
        else if (!form.room_max_guests) {
            isExistRequiredFiledsError = true;
        }
        // else if (!form.room_max_kids) {
        //     isExistRequiredFiledsError = true;
        // }
        else if (!form.room_size) {
            isExistRequiredFiledsError = true;
        }
        else if (!form.room_per_night_price) {
            isExistRequiredFiledsError = true;
        }
        setIsAnybedTypeQtySelected(true);
        if (!form.bedtype_single && !form.bedtype_queen && !form.bedtype_king && !form.bedtype_twin && !form.bedtype_super_king) {
            // alert("no bedtype data given")
            setIsAnybedTypeQtySelected(false)
            isExistRequiredFiledsError = true;
        }




        if (isExistRequiredFiledsError) {

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please fillup all required* fields marked as red.',
            })
        }

        return isExistRequiredFiledsError;
    }
    // methods //

    // useEffect
    useEffect(() => {

        axios.get(BaseUrl() + 'amenities').then(res => {
            setAmenities(res.data)
            console.log('api amenities', res.data)
            console.log('api useState amenities', amenities)
        })

        axios.get(BaseUrl() + 'bed').then(res => {
            setBed(res.data)
            console.log('api beds', res.data)
        })

    }, []);
    useEffect(() => {
        const fetchData = async () => {
            const res = await fetch('/json/amenities.json');
            const data = await res.json();
            setAmenitiesData(data);
            console.log("amenities data 101", data)
        }
        fetchData();
    }, []);
    // useEffect //





    // const pageInfo = { title: 'Create Your Room 2', btn1: 'cancel', btn2: 'save' };









    // load amenities data







    //  Trash Data
    // let tem_data = [];

    // let unique_data = [];

    // const getAmenities = (amenitie) => {

    //     tem_data.push(amenitie.id);

    //     // Remove duplicates data

    //     tem_data.forEach((c) => {
    //         if (!unique_data.includes(c)) {
    //             unique_data.push(c);
    //         }
    //     });

    //     console.log('test_101', tem_data);
    //     console.log('unique_data ', unique_data);

    //     if(tem_data.length ){

    //         let finddata = tem_data.find(item => item === amenitie.id);

    //        if(finddata){
    //         alert('yes', finddata)
    //        }
    //     }
    // }
    //  Trash Data



    // const checkRequiredField = (stateData, fieldTitle) => {
    //     if (!stateData) {
    //         alert(`${fieldTitle}* field is required`)
    //         return false;
    //     }
    //     return true;

    // }


    // uploader functions

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/*': []
        },
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }
    });

    const thumbs = files.map(file => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img
                    src={file.preview}
                    style={img}
                    // Revoke data uri after image is loaded
                    onLoad={() => { URL.revokeObjectURL(file.preview) }}
                    alt=""
                />
            </div>
        </div>
    ));

    // useEffect(()=>{
    //     files.map(file => (
    //         selectedPhotos.push(file.preview),
    //         console.log("file preview", file.preview)
    //     ));
    //     setRoomPhotos(selectedPhotos)
    // }, [files])

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => files.forEach(file => URL.revokeObjectURL(file.preview));
    }, []);


    // const selectedAminites = [2, 4, 7]

    const handleChecked = (item, isItemRemoveFromArray) => {

        //  setAmenitiesData([])

        const tempData = [...amenitiesData];
        tempData.map(tempDataItem => {
            return tempDataItem.id === item.id ? tempDataItem.checked = !tempDataItem.checked : ""
        })
        setAmenitiesData(tempData)

        const tempSelectedAminites = [...selectedAminites];
        if (isItemRemoveFromArray) {
            const findIndex = tempSelectedAminites.findIndex(element => element === item.id);
            if (findIndex) {
                tempSelectedAminites.push(item.id)
            }
        }
        else {
            console.log("tempSelectedAminites", tempSelectedAminites, item.id)
            const findIndex = tempSelectedAminites.findIndex(element => element === item.id);
            // console.log("findIndex", findIndex, item.id)
            tempSelectedAminites.splice(findIndex, 1);

        }
        setSelectedAminites(tempSelectedAminites)
    }
    // console.log("the files", files)
    return (
        <>
            <MuiSectionLoader>
                {/* {loading && <CustomLoader sentences={'.'} />} */}

                <div className='edit-room'>
                    {/* <InnerPagesTop
                pageInfo={pageInfo}
            ></InnerPagesTop> */}


                    <section className="page__header">
                        <Grid
                            container
                            className="room-types-topbar"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Grid
                                item
                            >
                                <h1 className="room-type-heading">
                                    Create Your Room
                                </h1>
                            </Grid>
                            <Grid
                                item
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        '& > *': {
                                            m: 2,
                                        },
                                    }}
                                >
                                    {
                                        !loading ? <>
                                            <ButtonGroup
                                                orientation="vertical"
                                                aria-label="vertical outlined button group"
                                                className='pages-top-btn-group'
                                            >
                                                {
                                                    <Link to="/dashboard/room-list"> <Button
                                                        className='room-typy-topbar-btn preview-btn'
                                                        sx={{
                                                            borderRadius: '20px',
                                                            backgroundColor: '#fff',
                                                            border: "none",
                                                            padding: '6px 28px',
                                                            textTransform: 'capitalize',
                                                            fontSize: '16px',
                                                            color: '#3899ec'
                                                        }}
                                                    >ROOM LIST</Button></Link>
                                                }

                                            </ButtonGroup>
                                            <ButtonGroup
                                                orientation="vertical"
                                                aria-label="vertical contained button group"
                                                variant="contained"
                                                sx={{ boxShadow: 'none' }}
                                                className='pages-top-btn-group'
                                            >
                                                <Button
                                                    onClick={onSubmit}
                                                    className='room-typy-topbar-btn create-new-btn'
                                                    sx={{
                                                        borderRadius: '20px',
                                                        backgroundColor: '#3899ec',
                                                        border: "none",
                                                        padding: '6px 18px',
                                                        textTransform: 'capitalize',
                                                        fontSize: '16px'
                                                    }}
                                                >
                                                    SAVE
                                                </Button>
                                            </ButtonGroup>
                                        </> : <SpinnerCircular enabled={loading} />
                                    }

                                </Box>
                            </Grid>
                        </Grid>

                    </section>

                    <section>
                        <Box className='edit-box'>
                            <Grid container sx={{ alignItems: 'flex-start' }} className="room-details-container" >
                                <Grid xs={12} md={3} item  >
                                    <h2 className="edit-box-title">
                                        Room Details
                                    </h2>
                                    <p className='edit-box-subtitle'>Let’s start with the basics.</p>
                                </Grid>
                                <Grid xs={12} md={7} item  >
                                    <div>

                                        <Grid container columnSpacing={2} >
                                            <Grid xs={12} md={8}>
                                                <TextField
                                                    error={!form.room_name}
                                                    required
                                                    // helperText={!form.room_name && "Filed is required."}
                                                    size="small"
                                                    name='room_name'
                                                    value={form.room_name}
                                                    onChange={handleChange}
                                                    id="outlined-required"
                                                    label="Name this room"
                                                    defaultValue="Family Suite 101"
                                                    sx={{ width: '90%', }}
                                                    className="name-this-room full-width-input-field"
                                                />
                                            </Grid>
                                            {/* <Grid xs={12} md={4}>
                                                <TextField
                                                    error={!form.room_units}
                                                    required
                                                    size="small"
                                                    name='room_units'
                                                    value={form.room_units}
                                                    onChange={handleChange}
                                                    id="outlined-number"
                                                    label="Room Units *"
                                                    type="number"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    className="room_units"
                                                    sx={{
                                                        width: '95%',
                                                    }}
                                                />
                                            </Grid> */}
                                        </Grid>
                                    </div>
                                    <div style={{ marginTop: '20px' }}>
                                        <Grid
                                            container
                                            columnSpacing={2}
                                        >
                                            <Grid xs={12} md={4}>
                                                <TextField
                                                    error={!form.room_max_guests}
                                                    // helperText={!form.room_max_guests && "Filed is required."}
                                                    size="small"
                                                    id="outlined-number"
                                                    label="Max Guests *"
                                                    name='room_max_guests'
                                                    value={form.room_max_guests}
                                                    onChange={handleChange}
                                                    type="number"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    sx={{
                                                        width: '80%',
                                                    }}
                                                    className="max-guests"
                                                />
                                            </Grid>
                                            <Grid xs={12} md={4}>
                                                <TextField
                                                    error={!form.room_max_kids}
                                                    // helperText={!form.room_max_kids && "Filed is required."}
                                                    size="small"
                                                    id="outlined-number"
                                                    label="Max Kids *"
                                                    name='room_max_kids'
                                                    value={form.room_max_kids}
                                                    onChange={handleChange}
                                                    type="number"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    sx={{
                                                        width: '80%',
                                                    }}
                                                    className="max-kids"
                                                />
                                            </Grid>
                                            <Grid xs={12} md={4}>
                                                <TextField
                                                    error={!form.room_size}
                                                    // helperText={!form.room_size && "Filed is required."}
                                                    size="small"
                                                    id="outlined-number"
                                                    label="Room Size *"
                                                    type="number"
                                                    name='room_size'
                                                    value={form.room_size}
                                                    onChange={handleChange}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    sx={{
                                                        width: '80%',
                                                    }}
                                                    className="room-size full-width-input-field"
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="start"><var>ft<sup>2</sup></var></InputAdornment>,
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div style={{ marginTop: '20px' }}>
                                        <Grid
                                            container
                                            columnSpacing={2}
                                        >
                                            <Grid xs={12} md={4}>
                                                <TextField
                                                    error={!form.room_per_night_price}
                                                    // helperText={!form.room_max_guests && "Filed is required."}
                                                    required
                                                    size="small"
                                                    id="outlined-number"
                                                    label="Price per night"
                                                    name="room_per_night_price"
                                                    value={form.room_per_night_price}
                                                    onChange={handleChange}
                                                    type="number"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    sx={{
                                                        width: '80%',
                                                    }}
                                                    className="max-guests"
                                                />
                                            </Grid>

                                        </Grid>
                                    </div>
                                </Grid>
                                <Grid
                                    lg={2}
                                    md={2}
                                    sm={12}
                                    item
                                >

                                </Grid>
                            </Grid>
                        </Box>
                        <Box className='edit-box'>
                            <Grid
                                container
                                sx={{
                                    alignItems: 'flex-start'
                                }}
                            >
                                <Grid
                                    lg={3}
                                    md={3}
                                    sm={12}
                                    item
                                >
                                    <h2 className="edit-box-title">
                                        Beds
                                    </h2>
                                    <p className='edit-box-subtitle'>List all the beds in this room.</p>
                                </Grid>
                                <Grid
                                    lg={7}
                                    md={7}
                                    sm={12}
                                    item
                                >
                                    <div>
                                        <p style={{ color: "red" }}>
                                            * Minimum one type bed quantity should be provide.
                                        </p>
                                        <Grid
                                            className='bed-row-one'
                                            container
                                            columnSpacing={2}
                                            sx={{
                                                alignItems: "flex-end",
                                                marginBottom: '30px'
                                            }}
                                        >

                                            <Grid lg={6} md={6} sm={6}>
                                                <TextField sx={{ my: 1 }} size="small" type="number" id="outlined-basic" label="Single Bed Qty" variant="outlined" name="bedtype_single" value={form.bedtype_single} onChange={handleChange} />
                                                <TextField sx={{ my: 1 }} size="small" type="number" id="outlined-basic" label="Queen Bed Qty" variant="outlined" name="bedtype_queen" value={form.bedtype_queen} onChange={handleChange} />
                                                <TextField sx={{ my: 1 }} size="small" type="number" id="outlined-basic" label="King Bed Qty" variant="outlined" name="bedtype_king" value={form.bedtype_king} onChange={handleChange} />
                                            </Grid>
                                            <Grid lg={3} md={3} sm={3} rowSpacing={1}>
                                                <TextField sx={{ my: 1 }} size="small" type="number" id="outlined-basic" label="Twin Bed Qty" variant="outlined" name="bedtype_twin" value={form.bedtype_twin} onChange={handleChange} />
                                                <TextField sx={{ my: 1 }} size="small" type="number" id="outlined-basic" label="Super King Bed Qty" variant="outlined" name="bedtype_super_king" value={form.bedtype_super_king} onChange={handleChange} />
                                            </Grid>
                                        </Grid>

                                    </div>
                                </Grid>
                                <Grid
                                    lg={2}
                                    md={2}
                                    sm={12}
                                    item
                                >

                                </Grid>
                            </Grid>
                        </Box>
                        <Box className='edit-box'>
                            <Grid
                                container
                                sx={{
                                    alignItems: 'flex-start'
                                }}
                            >
                                <Grid
                                    lg={5}
                                    md={5}
                                    sm={12}
                                    item
                                >
                                    <h2 className="edit-box-title">
                                        Amenities One
                                    </h2>
                                    <p className='edit-box-subtitle'>Check all the features your room has to offer.</p>
                                    {/* <p>{selectedAminites}</p> */}
                                </Grid>
                                <Grid
                                    lg={7}
                                    md={7}
                                    sm={12}
                                    item
                                >
                                    <div>

                                        {/* <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                                        {amenitiesOne.map(item => (
                                            <>
                                                
                                                <div key={item.id} style={{ display: 'flex', gap: '8px' }} >
                                                    <input type="checkbox" onChange={() => getAmenities(item)} id="horns" name="horns" />
                                                    <label for="horns">{item.amenities_name}</label>
                                                </div>
                                            </>
                                        ))
                                        }
                                    </div> */}

                                        <Grid
                                            container
                                        >
                                            {/* ameniities new */}
                                            {

                                                <Grid container spacing={1}>
                                                    {
                                                        amenitiesData.map((item, index) => <Grid key={index} item xs={12} md={4}>
                                                            {
                                                                item.checked === false ?
                                                                    <FormControlLabel control={<Checkbox onChange={() => handleChecked(item, true)} checked={false} />} label={item.label} />
                                                                    :
                                                                    <FormControlLabel control={<Checkbox onChange={() => handleChecked(item, false)} checked={true} />} label={item.label} />
                                                            }
                                                        </Grid>)
                                                    }
                                                </Grid>
                                            }
                                        </Grid>

                                    </div>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box
                            className='edit-box'
                        >
                            <Grid
                                container
                                sx={{
                                    alignItems: 'flex-start'
                                }}
                            >
                                <Grid
                                    lg={5}
                                    md={5}
                                    sm={12}
                                    item
                                >
                                    <h2 className="edit-box-title">
                                        Photos
                                    </h2>
                                    <p style={{
                                        paddingRight: '40%'
                                    }} className='edit-box-subtitle'>Showcase your rooms with high quality photos.</p>
                                </Grid>
                                <Grid
                                    lg={7}
                                    md={7}
                                    sm={12}
                                    item
                                    sx={{
                                        display: 'flex',
                                    }}
                                >

                                    <ImageListItem>


                                        {
                                            files.length >= 1 ? thumbs : <img
                                                src='/images/img-gallery.jpg'
                                                alt=''
                                                loading="lazy"
                                                className='room-photo'
                                            />
                                        }

                                    </ImageListItem>
                                    <div> <span className='room-photo add-room-photo'>
                                        <AddOutlinedIcon />
                                        <div className="container thumb-img-container">
                                            <div {...getRootProps({ className: 'dropzone' })}>
                                                <input {...getInputProps()} />
                                                {/* <p>Drag</p> */}
                                            </div>
                                            {/* <aside style={thumbsContainer}>
                                        {thumbs}
                                    </aside> */}
                                        </div>
                                    </span>

                                    </div>

                                    {/* <input type="file" id="file" /> */}
                                </Grid>
                            </Grid>
                            {/* <p style={{
                        margin: '0',
                        textAlign: 'end',
                        color: '#9e9898'
                    }}><small>1/10</small></p> */}
                        </Box>
                        <Box
                            className='edit-box'
                        >
                            <Grid
                                container
                                sx={{
                                    alignItems: 'flex-start'
                                }}
                            >
                                <Grid
                                    lg={5}
                                    md={5}
                                    sm={12}
                                    item
                                >
                                    <h2 className="edit-box-title">
                                        Description
                                    </h2>
                                    <p style={{
                                        color: '#3899ec'
                                    }}>Get ideas</p>
                                    <p style={{
                                        paddingRight: '40%'
                                    }} className='edit-box-subtitle description-subtitle'>Describe your room. What makes it special?</p>
                                </Grid>
                                <Grid
                                    lg={7}
                                    md={7}
                                    sm={12}
                                    item
                                    sx={{
                                        display: 'flex',
                                    }}
                                    className='room_description-box-container'
                                >
                                    <textarea
                                        name='room_description'
                                        value={form.room_description}
                                        onChange={handleChange}
                                        id=""
                                        style={{
                                            width: '100%',
                                            borderColor: '#c0b8b8',
                                            borderRadius: '5px'
                                        }}
                                        rows="8"
                                    >

                                    </textarea>
                                </Grid>
                            </Grid>
                            {/* <p style={{
                        margin: '0',
                        textAlign: 'end',
                        color: '#9e9898'
                    }}><small>0/2500</small></p> */}
                        </Box>
                        {/* <Box
                    className='edit-box'
                >
                    <Grid
                        container
                        sx={{
                            alignItems: 'flex-start'
                        }}
                    >
                        <Grid
                            xs={12}
                            md={5}
                            item
                        >
                            <h2 className="edit-box-title">
                                <var style={{ fontStyle: 'normal' }}>Price<sub><small style={{ marginLeft: '10px' }}>(before tax)</small></sub></var>
                            </h2>
                            <p style={{
                                paddingRight: '40%'
                            }} className='edit-box-subtitle room_description-subtitle'>How do you want to charge for this room?</p>
                        </Grid>
                        <Grid
                            xs={12}
                            md={7}
                            item
                            sx={{
                                display: 'flex',
                            }}
                        >
                            <div style={{ width: '100%' }}>
                                <Grid container style={{ width: '100%' }}>
                                    <Grid xs={12} md={4}>
                                        <p>Price per night *</p>
                                        <TextField
                                            
                                            // helperText={!form.room_per_night_price && "Filed is required."}
                                            size="small"
                                            error={!form.room_per_night_price}
                                            name="room_per_night_price"
                                            value={form.room_per_night_price}
                                            onChange={handleChange}
                                            id="outlined-start-adornment"
                                            sx={{ width: '90%' }}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">€</InputAdornment>,
                                            }}
                                            className='full-width-input-field'
                                        />
                                    </Grid>

                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                 
                </Box> */}
                        <div>
                            <Grid container className="edit-room-bottom">
                                <Grid xs={12} sm={6} item><p><span style={{ color: '#3899ec' }}>*</span><small> Indicates mandatory fields</small></p></Grid>
                                <Grid className='edit-room-bottom-btns' xs={12} sm={6} item sx={{ textAlign: 'end' }}>
                                    {
                                        !loading ? <>
                                            <ButtonGroup
                                                orientation="vertical"
                                                aria-label="vertical outlined button group"
                                            >
                                                <Button
                                                    className='room-typy-topbar-btn preview-btn'
                                                    sx={{
                                                        borderRadius: '20px',
                                                        backgroundColor: '#fff',
                                                        border: "none",
                                                        padding: '6px 28px',
                                                        textTransform: 'capitalize',
                                                        fontSize: '16px',
                                                        color: '#3899ec',
                                                        marginRight: '20px'
                                                    }}
                                                >cancel</Button>
                                            </ButtonGroup>
                                            <ButtonGroup
                                                orientation="vertical"
                                                aria-label="vertical contained button group"
                                                variant="contained"
                                                sx={{ boxShadow: 'none' }}
                                            >
                                                <Button onClick={onSubmit}
                                                    className='room-typy-topbar-btn create-new-btn'
                                                    sx={{
                                                        borderRadius: '20px',
                                                        backgroundColor: '#3899ec',
                                                        border: "none",
                                                        padding: '6px 28px',
                                                        textTransform: 'capitalize',
                                                        fontSize: '16px'
                                                    }}
                                                >
                                                    SAVE
                                                </Button>
                                            </ButtonGroup>
                                        </> : <SpinnerCircular enabled={loading} />
                                    }



                                </Grid>
                            </Grid>
                        </div>
                    </section>
                </div>
            </MuiSectionLoader>
        </>
    );
};

export default RoomCreate;