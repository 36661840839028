import React, { useRef } from "react";
import CrudDataList from "../../components/common/CrudDataList/CrudDataList";
import activityLogTableNaming from "../../utility/activityLogTableNaming";
import { bdDateFormat } from "../../utility/dateConversion";

const ActivityLog = () =>{
    // config
    const crudBaseUrl = "/log"; // changeHere
    const refreshDataList = useRef();
    const loadItemDetails = useRef();
    // config //
  
    // datatable
    const columnList = [
      // changeColumnName
      {
        name: "ID",
        selector: (row) => `${row.id}`,
      },
      {                                                             // changeHere
        name: "Description",
        selector: (row) => row.description,
        sortable: true,
      },
      
      {                                                             // changeHere
        name: "DB Table",
        selector: (row) =>  activityLogTableNaming( row.subject_type)  ,
        sortable: true,
      },
      {                                                             // changeHere
        name: "Effected Row ID",
        selector: (row) => row.subject_id,
        sortable: true,
      },
      {                                                             // changeHere
        name: "Event",
        selector: (row) => row.event,
        sortable: true,
      },
      {                                                             // changeHere
        name: "Action User ID",
        selector: (row) => row.causer_id,
        sortable: true,
      },
     
      {                                                             // changeHere
        name: "Logged At",
        selector: (row) =>  bdDateFormat(row.updated_at ),
        sortable: true,
      },
    
    ];
    // datatable //
  
    // call from createForm after save/update to datalist to reload list
    const handleRefreshDataList = () => {
      refreshDataList.current.getDataList();
    };
    // call from createForm after save/update to datalist to reload list//
  
    // call from datatable by edit button  to creatForm to load itemDetails
    const handleEdit = (itemId) => {
      loadItemDetails.current.getItemDetails(crudBaseUrl + "/" + itemId);
    };
    // call from datatable by edit button  to creatForm to load itemDetails //
  
    return (
      <>
        {/* //                                                                                 changeHere */}
        <h1>Activity Log</h1>
  
        {/* Create Form  */}
        {/* <CrudCreate
          handleRefreshDataList={handleRefreshDataList}
          crudBaseUrl={crudBaseUrl}
          ref={loadItemDetails}
        /> */}
        {/* Create Form  */}
        {/* List Data  / Delete data */}
        <CrudDataList
          actionButton={{"delete":false, "edit":false }}
          localKeyName="dentrino-medicine-taketime"
          localItemStrcture={{ label: "timetake_english", value: "id", label_2: "timetake_bangla" }}
          dataListSource={crudBaseUrl}
          columnList={columnList}
          handleEdit={handleEdit}
          ref={refreshDataList}
        ></CrudDataList>
        {/* List Data  / Delete data */}
        {/* Edit Data  */}
        <div></div>
      </>
    );
  };
export default ActivityLog;