import { combineReducers } from 'redux';
import { currentProperty, currentPropertyId } from "./adminSetting";
import { drawerStatus, pageSectionLoader2 } from "./pageLoader";
import { authUserPermission, loggedinUserInfo, tokenValue, userLoginStatus } from "./userAuthReducer";
import drawerToggle from './drawerToggle';

const rootReducer = combineReducers({
    userLoginStatus,loggedinUserInfo, authUserPermission, tokenValue, currentProperty, 
    drawerStatus ,pageSectionLoader2, currentPropertyId, drawerToggle
})

export default rootReducer;