import React, { useEffect, useState } from "react";

import './booking.css'

const Booking = () => {

    const [selectRoom, setselectRoom] = useState([]);
   
    const rooms = [
      {
        "id": "1",
        "name": "Room"
      },
      {
        "id": "2",
        "name": "Room"
      },
      {
        "id": "3",
        "name": "Room"
      },
      {
        "id": "4",
        "name": "Room"
      },
       {
        "id": "5",
        "name": "Room"
      },
       {
        "id": "6",
        "name": "Room"
      },
      {
        "id": "7",
        "name": "Room"
      },
      {
        "id": "8",
        "name": "Room"
      },
      {
        "id": "9",
        "name": "Room"
      },
      {
        "id": "10",
        "name": "Room"
      },
      {
        "id": "11",
        "name": "Room"
      },
      {
        "id": "12",
        "name": "Room"
      }
  ]

  // onClick={() => clickSelect(item)

   const  selectItem = (item) =>{

    let tem_data = [];
        
    item.target.classList.toggle("pink");

    const id = item.target.getAttribute("data-id");

    tem_data.push(id)

    setselectRoom(id)

    console.log('data id', id)

    console.log('tem_data', tem_data)
     
   }



   const clickSelect = (item) =>{
    
    let tem_data = [];
        
    item.target.classList.toggle("blue");

    const id = item.target.getAttribute("data-id");

    tem_data.push(id)

    setselectRoom(id)

    console.log('data id', id)

    console.log('tem_data', tem_data)
   }


    return (

        <>
            <div className='section__padding section-my'>

              <div className="grid">
                {rooms.map( (item, index) => {

                   return(
                        <div onClick={clickSelect} onMouseOver={selectItem}  key={index} data-id={item.id} className="box__item">Room-id -{item.id}
                        </div>
                    )

                })}
               </div>

               {selectRoom && <div className="total">Last Selected Room No. {selectRoom}</div>}

            </div>

        </>
    );
};

export default Booking;