import { Box, Button, Grid, Paper, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CalendarPicker, DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { hidePageSectionLoader, showPageSectionLoader } from '../../redux/actions';
import { ImageApiUrl } from '../../utility/master';

import MuiSectionLoader from '../../utilityComponents/MuiSectionLoader/MuiSectionLoader';
import CalenderRooms from '../CalenderRooms';


const ReservationList = () => {

  // others
  // others //

  // redux 
  const currentPropertyId = useSelector(state => state.currentPropertyId);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // redux //

  // state mangement
  const [date, setDate] = useState();
  const [reservations, setReservations] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [pages, setPages] = useState(2);
  const [pageNumber, setPageNumber] = useState(0)
  const [reservedUnit, setReservedUnit] = useState(0)
  const [freeUnit, setFreeUnit] = useState(0)
  const [totalRooms, setTotalRooms] = useState(0)
  // state mangement //

  // method mangement 
  const getReservationListByDate = () => {
    const startDateFormat = `${selectedDate.getFullYear()}-${selectedDate.getMonth() + 1}-${selectedDate.getDate()}`;
    dispatch(showPageSectionLoader("Reservation List Loading..."))
    let spliceStartData = 0;

    if (pageNumber === 1) {
      spliceStartData = 0
    } else {
      spliceStartData = 5 * (pageNumber - 1)
    }
    console.log("newdate", selectedDate, startDateFormat)
    axios
      .get(`reservation-list-by-date/${startDateFormat}/${currentPropertyId}`)
      .then(res => {
        console.log("property_property_list_page_101", res.data);
        setReservedUnit(res.data.data.length)
        const countPages = res.data.data.length / 50;
        setPages(Math.ceil(countPages));
        console.log("totalPages", countPages)
        const splicedData = res.data.data.splice(0, spliceStartData);
        console.log("spliced_data_101", splicedData)
        setReservations(res.data.data)
        dispatch(hidePageSectionLoader())
      })
      .catch(errors => {
        console.log("errors", errors)
      })
  }
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const convertStartDate = (newDate) => {
    const dateArray = newDate.split('-')
    const date = new Date(dateArray[0], dateArray[1], dateArray[2])
    const checkInDate = `${date.getDate()} ${months[date.getMonth() - 1]}`
    return checkInDate
  }
  const convertEndDate = (newDate) => {
    const dateArray = newDate.split('-')
    const date = new Date(dateArray[0], dateArray[1], dateArray[2])
    const checkInDate = `${date.getDate()} ${months[date.getMonth() - 1]},${date.getFullYear()}`
    return checkInDate
  }

  const getTotalRooms = () => {
    // dispatch(showPageSectionLoader("Room List loading..."))
    axios.get(`total-room-count/${currentPropertyId}`).then(res => {
      // const totalRooms = res.data.data; 
      setTotalRooms(res.data.data)

    }).catch(errors => {
      // dispatch(hidePageSectionLoader())
      // responseErrorManage(errors)

    })
  }

  // method mangement //

  // useEffect
  useEffect(() => {

    getReservationListByDate();
  }, [pageNumber, selectedDate, currentPropertyId])


  useEffect(() => {
    // endDate.setDate(endDate.getDate() + 1)
    getTotalRooms()
  }, [])
  useEffect(() => {
    const calcFreeUint = totalRooms - reservedUnit
    setFreeUnit(calcFreeUint)
  }, [totalRooms, reservedUnit])
  // useEffect //

  // styles 
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      fontWeight: '600'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))
  // styles //

  return (
    <MuiSectionLoader>
      <div>
        <CalenderRooms />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <div className='reservation-list-desktop' style={{ display: "none" }}>
            <Grid sx={{ alignItems: 'center' }} container spacing={2} >
              <Grid item xs={12} sm={6}>
                <h2 style={{ marginLeft: '10px' }}>Reservation List</h2>
              </Grid>
              <Grid item xs={.5} sm={1} md={3}>

              </Grid>
              <Grid item xs={11.5} sm={5} md={3}>
                <DatePicker
                  sx={{ display: 'flex' }}
                  label="Check In Date"
                  value={selectedDate}
                  onChange={(newValue) => {
                    setSelectedDate(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Grid>
            </Grid>
            <TableContainer className="responsive-table" sx={{ width: '100%', overflowX: 'auto' }} component={Paper}>
              <Table sx={{ minWidth: 300, maxWidth: '100%' }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>ID</StyledTableCell>
                    <StyledTableCell align="left">Room Name</StyledTableCell>
                    <StyledTableCell align="left">Check In Date</StyledTableCell>
                    <StyledTableCell align="left">Check Out Date</StyledTableCell>
                    <StyledTableCell align="left">Total Nights</StyledTableCell>
                    <StyledTableCell align="left">Status</StyledTableCell>
                    <StyledTableCell align="left">Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reservations.map((row) => (
                    <StyledTableRow key={row.id}>
                      <StyledTableCell component="th" scope="row">
                        {row.id}
                        {console.log("rowid", row.id)}
                      </StyledTableCell>
                      <StyledTableCell align="left">{row.room_name}</StyledTableCell>
                      <StyledTableCell align="left">{row.check_in_date}</StyledTableCell>
                      <StyledTableCell align="left">{row.check_out_date}</StyledTableCell>
                      <StyledTableCell align="left">{row.total_nights}</StyledTableCell>
                      <StyledTableCell align="left">{row.reservation_status}</StyledTableCell>
                      <StyledTableCell align="left"><Link style={{ textDecoration: 'none' }} to={`/dashboard/edit-reservation/${row.id}`}><Button variant="contained">Edit</Button></Link></StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

          </div>
          <div className='reservation-list-mobile'>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <CalendarPicker
                    date={selectedDate}
                    value={selectedDate}
                    onChange={(newValue) => {
                      setSelectedDate(newValue);
                    }} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <div style={{paddingLeft:"22px"}}>
                    Reserved({reservedUnit})-Free({freeUnit})
                  </div>

                </Grid>
                <Grid item xs={12} md={6}>
                  <div>
                    <Grid container>
                    {
                          console.log("reservation_list_reservatoin",reservations)
                        }
                      {
                         
                        reservations.map((item, index) => <Grid key={index} item xs={12} sx={{ padding: '0 10px' }}>
                          {
                            console.log("reservation_list",item)
                          }
                          <Box sx={{ backgroundColor: '#f1f1f1', padding: '5px 20px' ,marginBottom:'8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderLeft:   `${item.reservation_status === "Reserved" ? "2px solid #E3963E" : item.reservation_status === "Checked In" ? "2px solid #228B22" : "2px solid #A52A2A"}`, borderRadius: '3px' }}>
                            <Box sx={{ width: '70%' }}>
                              <Box>
                                <h4 style={{ margin: '0' }}>{item.room_name}</h4>
                              </Box>

                              <Box>
                                <p style={{ margin: '0', color: '#797676' }}>{convertStartDate(item.check_in_date)} - {convertEndDate(item.check_out_date)}</p>
                              </Box>
                            </Box>

                            <Box sx={{ width: '30%', textAlign: 'end', display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                              <Link style={{ textDecoration: 'none' }} to={`/dashboard/edit-reservation/${item.id}`}>

                                {
                                  item?.room?.room_photo ? <img style={{ width: '70px', height: '70px', borderRadius: '150%' }} src={`${ImageApiUrl()}${item?.room?.room_photo}`} alt="" /> : <img style={{ width: '70px', height: '70px', borderRadius: '150%' }} src="/images/404-image.jpg" alt="reservation-room-img" />
                                }
                              </Link>
                            </Box>
                          </Box>
                        </Grid>)
                      }
                    </Grid>
                  </div>
                  {/* <Stack sx={{ padding: '40px 0px' }} spacing={2}>
                    <Pagination onChange={(e, value) => setPageNumber(value)} count={pages} variant="outlined" shape="rounded" />
                  </Stack> */}
                </Grid>
              </Grid>
            </Box>
          </div>
          {/* <Stack sx={{ padding: '40px 0px' }} spacing={2}>
            <Pagination onChange={(e, value) => setPageNumber(value)} count={pages} variant="outlined" shape="rounded" />
          </Stack> */}
        </LocalizationProvider>
      </div>

    </MuiSectionLoader>
  );
};

export default ReservationList;