import { Box, Button, ButtonGroup, Grid, TextField } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import InnerPagesTop from '../components/Dashboard/InnerPagesTop';
import { hidePageSectionLoader, showPageSectionLoader } from '../redux/actions';
import '../styles/pages/EditRoom.css';
import emptyValidationCheck from '../utility/emptyValidationCheck';
import responseErrorManage from '../utility/responseErrorManage';
import MuiSectionLoader from '../utilityComponents/MuiSectionLoader/MuiSectionLoader';

const PropertySettings = () => {
    
    const [item, setItem] = React.useState('');
    const dispatch = useDispatch();

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    const [form, setForm] = useState({
        property_name: '',
        property_email: '',
        property_phone: '',
        property_city_state_country: '',
        property_street_address: '',
        property_zip_code: '',
        name: '',
        email: '',
        password: '',
        // property_time_zone_id: '1',
        // property_measurement_units: 'text',
        // property_weekend: 'des',
        // property_primary_language_id: '0',
        // property_check_in_time: '2:00',
        // property_check_out_time: '2:00',
        // property_min_night: '0',
        // property_max_night: '0',
        errors: [],
    });

    const handleChange = (e) => {
        console.log("test_101", e.target.value)
        setForm({ ...form, [e.target.name]: e.target.value })
        console.log(form)
    }

    const onSubmit = (e) => {
        e.preventDefault();

          // validation blocks
          let isValidationFailed = false; 
          isValidationFailed = emptyValidationCheck(form.property_name, "Property Name",isValidationFailed )
          isValidationFailed = emptyValidationCheck(form.property_email, "Email Address",isValidationFailed )
          isValidationFailed = emptyValidationCheck(form.property_phone, "Phone Number",isValidationFailed )
          isValidationFailed = emptyValidationCheck(form.property_city_state_country, "City, State, Country",isValidationFailed )
          isValidationFailed = emptyValidationCheck(form.property_street_address, "Street Address",isValidationFailed )
          isValidationFailed = emptyValidationCheck(form.property_zip_code, " Zip Code / Postal Code",isValidationFailed )
          isValidationFailed = emptyValidationCheck(form.name, "Manager Name",isValidationFailed )
          isValidationFailed = emptyValidationCheck(form.email, "Manager Email",isValidationFailed )
          isValidationFailed = emptyValidationCheck(form.password, "Password",isValidationFailed )
          if(isValidationFailed){return ""}; 
          // validation blocks

        console.log(form)
        dispatch(showPageSectionLoader("Saving Property settings..."))
        axios.post( 'property', form).then(res => {
            console.log("property_data_101",res.data);

            dispatch(hidePageSectionLoader())
            Toast.fire({
                icon: 'success',
                title: 'New Property Saved'
            });
            setForm({
                property_name: '',
                property_email: '',
                property_phone: '',
                property_city_state_country: '',
                property_street_address: '',
                property_zip_code: '',
                name: '',
                email: '',
                password: '',
            })

        }).catch(errors => {
            dispatch(hidePageSectionLoader())
            // alert('error.')
            responseErrorManage(errors)
            
        })
            .finally(f => {
                dispatch(hidePageSectionLoader())
                
            })
    }


    

    // load time data
    const [timeZoneData, setTimeZoneData] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const res = await fetch('/json/time.json');
            const data = await res.json();
            setTimeZoneData(data);
            console.log("time data 101", data)
        }
        fetchData();
    }, []);

    const pageInfo = { title: 'New Property', btn1: 'cancel', btn2: 'save', onClick: onSubmit };
    return (
        <MuiSectionLoader>
        <div className='property-settings'>
            <InnerPagesTop
                pageInfo={pageInfo}
            ></InnerPagesTop>
            <section >
                <Box className='edit-box'>
                    <Grid container>
                        <Grid item
                            lg={3}
                            md={3}
                            sm={12}>
                            <h2 className="edit-box-title">
                                Business Info
                            </h2>
                            <p className='edit-box-subtitle'>Your contact info will appear in guest’s booking confirmation email.</p>
                        </Grid>
                        <Grid item
                            xs={12}
                            md={7}>
                            <div style={{ width: "100%" }}>
                                <Grid container sx={{
                                    alignItems: 'flex-end'
                                }}>
                                    <Grid item xs={12} md={8}>
                                        <p style={{ margin: '0' }}><span style={{ color: '#3899ec', fontSize: '20px' }}>*</span> Property Name</p>
                                        <TextField
                                            size="small"
                                            id="outlined-helperText"
                                            label=""
                                            defaultValue="Vila Lako"
                                            name='property_name'
                                            value={form.property_name}
                                            onChange={handleChange}
                                            sx={{
                                                width: '95%'
                                            }}
                                            className="full-width-input-field"
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        {/* <p style={{ margin: '0' }}>Property Type</p> */}
                                        {/* <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Hotel</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                size="small"
                                                name='property_type'
                                                value={form.property_type}
                                                defaultValue="Hotel"
                                                onChange={handleChange}
                                                sx={{
                                                    width: '90%'
                                                }}
                                                className="property-type-select"
                                            >
                                                <MenuItem value={10}>Single</MenuItem>
                                                <MenuItem value={20}>Queen</MenuItem>
                                                <MenuItem value={30}>King</MenuItem>
                                            </Select>
                                        </FormControl> */}
                                    </Grid>
                                </Grid>
                                <Grid container sx={{
                                    alignItems: 'flex-end'
                                }}>
                                    <Grid item xs={12} md={8}>
                                        <p style={{ margin: '0' }}><span style={{ color: '#3899ec', fontSize: '20px' }}>*</span> Email Address</p>
                                        <TextField
                                            size="small"
                                            id="outlined-helperText"
                                            label=""
                                            name='property_email'
                                            value={form.property_email}
                                            onChange={handleChange}
                                            sx={{
                                                width: '95%'
                                            }}
                                            className="full-width-input-field"
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <p style={{ margin: '0' }}>Phone Number</p>
                                        <TextField
                                            size="small"
                                            id="outlined-helperText"
                                            label=""
                                            name='property_phone'
                                            value={form.property_phone}
                                            onChange={handleChange}
                                            sx={{
                                                width: '90%'
                                            }}
                                            className="settings-phone-number full-width-input-field"
                                            required
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </Box>
                <Box className='edit-box'>
                    <Grid container>
                        <Grid item
                            lg={3}
                            md={3}
                            sm={12}>
                            <h2 className="edit-box-title">
                                Address
                            </h2>
                        </Grid>
                        <Grid item
                            lg={7}
                            md={7}
                            sm={12}>
                            <div>
                                <Grid item xs={12} md={12}>
                                    <p style={{ margin: '0' }}><span style={{ color: '#3899ec', fontSize: '20px' }}>*</span> City, State, Country</p>
                                    <TextField
                                        size="small"
                                        id="outlined-helperText"
                                        label=""
                                        name='property_city_state_country'
                                        value={form.property_city_state_country}
                                        onChange={handleChange}
                                        sx={{
                                            width: '100%'
                                        }}
                                    />
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12} md={7}>
                                        <p style={{ margin: '0' }}><span style={{ color: '#3899ec', fontSize: '20px' }}>*</span> Street Address</p>
                                        <TextField
                                            size="small"
                                            id="outlined-helperText"
                                            label=""
                                            name='property_street_address'
                                            value={form.property_street_address}
                                            onChange={handleChange}
                                            sx={{
                                                width: '95%'
                                            }}
                                            className="settings-street-address"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={5}>
                                        <p style={{ margin: '0' }}>Zip Code / Postal Code</p>
                                        <TextField
                                            size="small"
                                            id="outlined-helperText"
                                            label=""
                                            name='property_zip_code'
                                            value={form.property_zip_code}
                                            onChange={handleChange}
                                            sx={{
                                                width: '100%'
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </Box>
                <Box className='edit-box'>
                    <Grid container>
                        <Grid item
                            lg={3}
                            md={3}
                            sm={12}>
                            <h2 className="edit-box-title">
                                Create User
                            </h2>
                        </Grid>
                        <Grid item
                            xs={12}
                            md={7}>
                            <div style={{ width: "100%" }}>
                                <Grid container sx={{
                                    alignItems: 'flex-end'
                                }}>
                                    <Grid item xs={12} md={6}>
                                        <p style={{ margin: '0' }}><span style={{ color: '#3899ec', fontSize: '20px' }}>*</span> Manager Name</p>
                                        <TextField
                                            size="small"
                                            id="outlined-helperText"
                                            label=""
                                            defaultValue="Vila Lako"
                                            name='name'
                                            value={form.name}
                                            onChange={handleChange}
                                            sx={{
                                                width: '95%'
                                            }}
                                            className="full-width-input-field"
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                    <p style={{ margin: '0' }}>Manager Email</p>
                                        <TextField
                                            size="small"
                                            id="outlined-helperText"
                                            label=""
                                            name='email'
                                            value={form.email}
                                            onChange={handleChange}
                                            type="email"
                                            sx={{
                                                width: '90%'
                                            }}
                                            className="settings-phone-number full-width-input-field"
                                            required
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container sx={{
                                    alignItems: 'flex-end'
                                }}>
                                    <Grid item xs={12} md={6}>
                                        <p style={{ margin: '0' }}><span style={{ color: '#3899ec', fontSize: '20px' }}>*</span> Password</p>
                                        <TextField
                                            size="small"
                                            id="outlined-helperText"
                                            label=""
                                            name='password'
                                            value={form.password}
                                            onChange={handleChange}
                                            type="password"
                                            sx={{
                                                width: '95%'
                                            }}
                                            className="full-width-input-field"
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>

                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </Box>
                {/* <Box className='edit-box'>
                    <Grid container>
                        <Grid item
                            xs={12}
                            md={3}
                        >

                            <h2 className="edit-box-title">
                                Regional Settings
                            </h2>
                            <p className='edit-box-subtitle'>Choose the settings relevant to your region.</p>
                        </Grid>
                        <Grid item
                            lg={7}
                            md={7}
                            sm={12}>
                            <div>
                                <Grid container>
                                    <Grid item xs={12} md={8}>
                                        <p style={{ margin: '0' }}><span style={{ color: '#3899ec', fontSize: '20px' }}>*</span> Time Zone</p>
                                        <FormControl fullWidth>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                size="small"
                                                sx={{
                                                    width: '90%'
                                                }}
                                                name="property_time_zone_id"
                                                value={form.property_time_zone_id}
                                                onchange={handleChange}
                                                className="time-zone-select"
                                            >
                                                {
                                                    timeZoneData.map((item, index) => <MenuItem
                                                        key={index} id={item.id} >{item.time}</MenuItem>)
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <p style={{ margin: '0' }}>Measurement Units</p>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Square feet</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                size="small"
                                                sx={{
                                                    width: '100%'
                                                }}
                                                name="property_measurement_units"
                                                value={form.property_measurement_units}
                                                onChange={(e)=>handleChange(e)}
                                            >
                                                <MenuItem value={10}>Square feet</MenuItem>
                                                <MenuItem value={20}>Square metre</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                            </div>
                            <div>
                                <Grid container>
                                    <Grid item lg={8} md={8} sm={12}>
                                        <p style={{ margin: '0' }}>Your Weekend Nights Are:</p>
                                        <FormGroup sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between'
                                        }}>
                                            <FormControlLabel control={<Checkbox />} label="Thu" />
                                            <FormControlLabel control={<Checkbox />} label="Fri" />
                                            <FormControlLabel control={<Checkbox defaultChecked />} label="Sat" />
                                            <FormControlLabel control={<Checkbox defaultChecked />} label="Sun" />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <p style={{ margin: '0' }}><span style={{ color: '#3899ec', fontSize: '20px' }}>*</span> Primary Language</p>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">English</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                size="small"
                                                name="property_primary_language_id"
                                                value={form.property_primary_language_id}
                                                onchange={handleChange}
                                                sx={{
                                                    width: '100%'
                                                }}
                                            >
                                                <MenuItem value={10}>English</MenuItem>
                                                <MenuItem value={20}>Spanish</MenuItem>
                                                <MenuItem value={20}>Hindi</MenuItem>
                                                <MenuItem value={20}>French</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </Box> */}
                {/* <Box className='edit-box'>
                    <Grid container>
                        <Grid item
                            xs={12}
                            md={3}>
                            <h2 className="edit-box-title">
                                Length of Stay
                            </h2>
                            <p className='edit-box-subtitle'>What are your stay requirements?</p>
                        </Grid>
                        <Grid item
                            xs={12}
                            md={7}>
                            <div>
                                <Grid container>
                                    <Grid xs={12} md={4}>
                                        <p style={{ margin: '0' }}>Check-In Time</p>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">02:00 PM</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                size="small"
                                                sx={{
                                                    width: '95%'
                                                }}
                                                name="property_check_in_time"
                                                value={form.property_check_in_time}
                                                onchange={handleChange}
                                                className="full-width-input-field"
                                            >
                                                <MenuItem value={10}>02:00 PM</MenuItem>
                                                <MenuItem value={20}>03:00 PM</MenuItem>
                                                <MenuItem value={20}>04:00 PM</MenuItem>
                                                <MenuItem value={20}>05:00 PM</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <p style={{ margin: '0' }}>Check-Out Time</p>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">02:00 PM</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                size="small"
                                                name="property_check_out_time"
                                                value={form.property_check_out_time}
                                                onchange={handleChange}
                                                sx={{
                                                    width: '100%'
                                                }}
                                            >
                                                <MenuItem value={10}>02:00 PM</MenuItem>
                                                <MenuItem value={20}>03:00 PM</MenuItem>
                                                <MenuItem value={20}>04:00 PM</MenuItem>
                                                <MenuItem value={20}>05:00 PM</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid xs={12} md={2}>
                                        <p style={{ margin: '0' }}>Min Nights</p>
                                        <TextField
                                            size="small"
                                            id="outlined-number"
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="property_min_night"
                                            value={form.property_min_night}
                                            onchange={handleChange}
                                            sx={{
                                                width: '90%',
                                            }}
                                            className="full-width-input-field"
                                        />
                                    </Grid>
                                    <Grid xs={12} md={2}>
                                        <p style={{ margin: '0' }}>Max Nights</p>
                                        <TextField
                                            size="small"
                                            id="outlined-number"
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="property_max_night"
                                            value={form.property_max_night}
                                            onchange={handleChange}
                                            sx={{
                                                width: '90%',
                                            }}
                                            className="full-width-input-field"
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </Box> */}
                <div>
                    <Grid container className="edit-room-bottom">
                        <Grid xs={12} sm={6} item><p><span style={{ color: '#3899ec' }}>*</span><small> Indicates mandatory fields</small></p></Grid>
                        <Grid className='edit-room-bottom-btns' xs={12} sm={6} item sx={{ textAlign: 'end' }}>
                            <ButtonGroup
                                orientation="vertical"
                                aria-label="vertical outlined button group"
                            >
                                <Button
                                    className='room-typy-topbar-btn preview-btn'
                                    sx={{
                                        borderRadius: '20px',
                                        backgroundColor: '#fff',
                                        border: "none",
                                        padding: '6px 28px',
                                        textTransform: 'capitalize',
                                        fontSize: '16px',
                                        color: '#3899ec',
                                        marginRight: '20px'
                                    }}
                                >cancel</Button>
                            </ButtonGroup>
                            <ButtonGroup
                                orientation="vertical"
                                aria-label="vertical contained button group"
                                variant="contained"
                                sx={{ boxShadow: 'none' }}
                            >
                                <Button
                                    className='room-typy-topbar-btn create-new-btn'
                                    onClick={onSubmit}
                                    sx={{
                                        borderRadius: '20px',
                                        backgroundColor: '#3899ec',
                                        border: "none",
                                        padding: '6px 28px',
                                        textTransform: 'capitalize',
                                        fontSize: '16px'
                                    }}
                                >
                                    save
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                </div>
            </section>
        </div>
        </MuiSectionLoader>
    );
};

export default PropertySettings;