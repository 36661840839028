import { ActionTypes } from "@mui/base"
import { GiStarSattelites } from "react-icons/gi"

const initialState = false
const drawerToggle = (state=initialState, action) => {
    switch(action.type){
        case 'toggle-sidebar' : return state=action.payload;
        default : return state
    }
        
}

export default drawerToggle;