import { Box, Grid } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import '../../styles/pages/CalencerRooms.css';
import { laravelToJsDateFormat } from '../../utility/dateConversion';

const CalenderRoomItem = ({ room, setShowPopup, firstClick, secondClick, setFirstClick, setSecondClick, setClickedItems, setMouseOverItems, mouseOverItems, setShowReservedPopup, setReservedData, todayDate, setStartDate, setEndDate, roomIndex, setSelectedRowId }) => {
    const [positionLeft, setPositionLeft] = useState('')
    const blockRef = useRef([]);
    const [clickedIndex, setClickedIndex] = useState('')
    console.log("refs_101", blockRef)



    const position = (index) => {
        let leftPosition;
        // const startFrom = room.reserved[index]?.start_from;
        if (room.reserved[index]?.start_from) {
            const startFrom = room.reserved[index]?.start_from;
            const startFromArray = startFrom.split("-")
            const startFromMonth = startFromArray[1];
            const startFromDate = startFromArray[2];
            const startFromYear = startFromArray[0];

            const thisDay = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate())
            const reserveDay = new Date(startFromYear, startFromMonth - 1, startFromDate)
            // if(startFromMonth === todayDate.getMonth()){
            const diffenrence = reserveDay.getTime() - thisDay.getTime();
            const differenceInDay = diffenrence / (1000 * 3600 * 24);
            // const absoluteDifference = Math.abs(differenceInDay)
            // leftPosition = differenceInDay * 8.33
            if (differenceInDay === -1) {
                leftPosition = 8.33
            }
            else if (differenceInDay === -2) {
                leftPosition = 0
            }
            else if (differenceInDay === 0) {
                leftPosition = 8.33 * 2
            }
            else if (differenceInDay > 0) {
                leftPosition = (differenceInDay + 2) * 8.33
            } else {
                return
            }
        }


        // }
        return leftPosition
    }


    const [selectRoom, setselectRoom] = useState([]);

    const [firstDate, setFirstDate] = useState('');
    const [secondDate, setSecondDate] = useState('');
    const [selectedRoomId, setSelectedRoomId] = useState('')
    // const [startDate, setStartDate] = useState({})
    // const [endDate, setEndDate] = useState({})


    // let selectedRoomId;
    const clickSelect = (item, index, itemIndex) => {

        // if(secondClick){
        //     const startArray =  index.split("_")
        //     const startId = parseInt(`${startArray[0]}${startArray[1]}`)
        //     // startArray[0]
        //     // clear previous row 
        //     for(let j=1; j<=12; j++){
        //         // console.log("test_2nd_run")
        //         const effectedId = `${startArray[0]}_${j}`
        //         document.getElementById(effectedId).style.backgroundColor = "white";
        //     }

        // }


        setClickedIndex(index)
        setSelectedRowId(index);
        // array[3]=1


        console.log("clickSelect_event_trigger", itemIndex)
        const handleSetStartDate = (itemIndex) => {
            const initialDate = new Date(todayDate);
            if (itemIndex === 0) {
                initialDate.setDate(initialDate.getDate() - 2)
            }
            else if (itemIndex === 1) {
                initialDate.setDate(initialDate.getDate() - 1)
            } else {
                initialDate.setDate(initialDate.getDate() + itemIndex - 2)
            }
            setStartDate(initialDate)
        }

        const handleSetEndDate = (itemIndex) => {
            const initialDate = new Date(todayDate);
            if (itemIndex === 0) {
                initialDate.setDate(initialDate.getDate() - 2)
            }
            else if (itemIndex === 1) {
                initialDate.setDate(initialDate.getDate() - 1)
            } else {
                initialDate.setDate(initialDate.getDate() + itemIndex - 2)
            }
            setEndDate(initialDate)
        }



        setSelectedRoomId(room.rowId)
        if (!firstClick) {
            setFirstClick(!firstClick)
            setFirstDate(item.target)
            item.target.classList.toggle("selected");
            console.log("first node", item.target)
            handleSetStartDate(itemIndex)


        }
        if (firstClick) {

            setSecondClick(!secondClick)
            setSecondDate(item.target)
            item.target.classList.add("selected");
            item.target.classList.remove('pink')
            console.log("second node", item.target)
            handleSetEndDate(itemIndex)
            setClickedIndex('');
        }
        console.log("check_select_test", room)
        console.log("check_selectId_test", room.rowId)
    }

    // console.log("clicked_node_test", clicked)
    const mouseSelected = [];
    const OnHover = (item, uniqueId) => {
        console.log("clickedIndex", clickedIndex)

        if (firstClick && !secondClick) {
            // if(selectedRoomId===item.target.id){
            //     // item.target.classList.toggle('selected');
            //     setMouseOverItems([...mouseOverItems, item.target])
            // }
            // blockRef.current[index].style.backgroundColor = "red"
            // console.log("returned_from_ref", blockRef.current[index])

            // console.log("clickedIndex_2",clickedIndex)
            const startArray = clickedIndex.split("_")
            const startId = parseInt(`${startArray[0]}${startArray[1]}`)

            const endingArry = uniqueId.split("_")
            const endingId = parseInt(`${endingArry[0]}${endingArry[1]}`)
            // 4_5


            // 4_12

            // loop on column 
            console.log("test_101", startArray[0], startArray[1], endingArry[0], endingArry[1])
            for (let i = startArray[1]; i <= parseInt(endingArry[1]); i++) {
                console.log("inside_loop_1")
                if (startArray[0] === endingArry[0]) {
                    const effectedId = `${startArray[0]}_${i}`
                    console.log("onhover_id_2", effectedId)
                    document.getElementById(effectedId).style.backgroundColor = "#9BCCF5";  // selection color
                    // blockRef.current(effectedId).style.backgroundColor = "red";

                }

            }
            // console.log("test_2nd_run_before", parseInt(endingArry[1])+1)
            // If(parseInt(startArray[1]) < parseInt(endingArry[1])){

            // }

            if (startArray[1] <= endingArry[1]) {
                for (let j = parseInt(endingArry[1]) + 1; j <= 12; j++) {
                    // console.log("test_2nd_run")
                    if (startArray[0] === endingArry[0]) {
                        const effectedId = `${startArray[0]}_${j}`
                        document.getElementById(effectedId).style.backgroundColor = "white";
                        // blockRef.current(effectedId).style.backgroundColor = "red";

                    }
                }
            }

            // console.log("test_2nd_run_after",endingArry[1])


            // for(let i=startId+1; i<=endingId ;i++){
            //     //  console.log("returned_from_ref", blockRef)
            //      console.log("print_id_101", i)
            //     //  console.log("loop_elements_101",document.getElementById(i))
            //     // 61 - 69 , 610-612
            //     // Column - 1+10 =  11+12 = 22
            //     // 2+11 = 23-32
            //     // 3+


            //      document.getElementById(i).style.backgroundColor = "red"
            // }
        }
    }

    useEffect(() => {
        if (secondClick && firstClick) {
            setShowPopup(true)
        }
    }, [secondClick])

    // useEffect(()=>{
    //     setMouseOverItems(mouseSelected)
    // }, [mouseSelected])

    useEffect(() => {
        setClickedItems([firstDate, secondDate])
    }, [firstDate, secondDate])

    console.log("reserved_number", room.reserved)

    const [reservedRoom, setReservedRoom] = useState([])
    useEffect(() => {
        setReservedRoom(room.reserved)
    }, [room.reserved])

    const overlayMouseOver = (index) => {
        const startFrom = room.reserved[index].start_from;
        const startArray = startFrom.split('-')

        const end = room.reserved[index].check_out_date;
        const endArray = end.split('-')
        const checkInDate = new Date(startArray[0], startArray[1] - 1, startArray[2])
        const checkOutDate = new Date(endArray[0], endArray[1] - 1, endArray[2])

        // if(startFromMonth === todayDate.getMonth()){
        const diffenrence = checkOutDate.getTime() - checkInDate.getTime();
        const totalDays = (diffenrence / (1000 * 3600 * 24)) ;
        setReservedData({ ...room.reserved[index], totalDays })
        setShowReservedPopup(true)
    }

    const [minusBlock, setMinusBlock] = useState(0)
   
    const handleMinusBlock = (totalDays, index) => {
        let minusNumber;
        if (room.reserved[index]?.start_from) {

            const startFrom = room.reserved[index].start_from;
            const startFromArray = startFrom.split("-")
            const startFromMonth = startFromArray[1];
            const startFromDate = startFromArray[2];
            const startFromYear = startFromArray[0];

            const thisDay = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate())
            const reserveDay = new Date(startFromYear, startFromMonth - 1, startFromDate)
            // if(startFromMonth === todayDate.getMonth()){
            const diffenrence = reserveDay.getTime() - thisDay.getTime();
            const differenceInDay = diffenrence / (1000 * 3600 * 24);
            // const absoluteDifference = Math.abs(differenceInDay)
            // leftPosition = differenceInDay * 8.33

            if (differenceInDay === -1) {
                const a = 12 - 1
                if (totalDays <= a) {
                    minusNumber = 0
                }
                else if (totalDays > a) {
                    const b = totalDays - a
                    minusNumber = b
                }
            }
            else if (differenceInDay === -2) {
                const a = 12 - 0
                if (totalDays <= a) {
                    minusNumber = 0
                }
                else if (totalDays > a) {
                    const b = totalDays - a
                    minusNumber = b
                }
            }
            else if (differenceInDay < -2) {
                const minus = -2 - (differenceInDay)
                const a = 12 - 0
                if (totalDays <= a) {
                    minusNumber = minus
                }
                else if (totalDays > a) {
                    const b = totalDays - a
                    minusNumber = b + minus
                }
            }
            else if (differenceInDay === 0) {
                const a = 12 - 2
                if (totalDays <= a) {
                    minusNumber = 0
                }
                else if (totalDays > a) {
                    const b = totalDays - a
                    minusNumber = b
                }
            }
            else if (differenceInDay > 0) {
                const a = 12 - (differenceInDay + 2)
                if (totalDays <= a) {
                    minusNumber = 0
                }
                else if (totalDays > a) {
                    const b = totalDays - a;
                    minusNumber = b
                }
            } else {
                return
            }
        }
        return minusNumber
    }


    const countTotalDays = (start_from, check_out_date) => {
        const startArray = start_from.split('-')
        // console.log("start_array_101", startArray)
        const endArray = check_out_date.split('-')

        const CheckInDate = new Date(startArray[0], startArray[1] - 1, startArray[2])
        // console.log("start_array_101", CheckInDate)

        const checkOutDate = new Date(endArray[0], endArray[1] - 1, endArray[2])

        const diffenrence = checkOutDate.getTime() - CheckInDate.getTime();
        const differenceInDay = diffenrence / (1000 * 3600 * 24);
        console.log("start_array_101", differenceInDay)
        return differenceInDay + 1
    }
    // console.log("clicked_index_101", clickedIndex)



    // Shafiq Code 
    const [blockWidth, setBlockWidth] = useState(0)

    const getRangeBlock = () => {
        const clientWidth = document.getElementById(room.rowId).clientWidth;
        // console.log("clientWidth", clientWidth);
        const calcBlockWidth = clientWidth / 12
        // console.log("clientWidth_each", calcBlockWidth); // 85.5
        setBlockWidth(calcBlockWidth)
    }

    useEffect(() => {
        getRangeBlock()
    }, [])

    const positionTwo = (item) => {

        // find no of blank block  [date_range_start_date, chcked_in_date, chcked_out_date, date_range_end_date]
       
        const datePrevTwo = new Date(todayDate)
        const dateRangeStartFrom = new Date(new Date(datePrevTwo.setDate(datePrevTwo.getDate() - 2)).toDateString())
        

        const checkInDate = new Date(laravelToJsDateFormat(item.start_from))
        // console.log("positionTwo_js", checkInDate, checkOutDate)

        let differenceInTime = checkInDate.getTime()-dateRangeStartFrom.getTime()
        // To calculate the no. of days between two dates
        let Difference_In_Days = Math.round(differenceInTime / (1000 * 3600 * 24));
        
        

        let leftPosition = 0
        if(Difference_In_Days<0){
            leftPosition = 0
        }
        else{
            leftPosition = (blockWidth* Math.abs(Difference_In_Days))+(blockWidth/2)
        }
        console.log("leftPosition", Difference_In_Days, leftPosition, item)
        return leftPosition;
    }

    const findBlockTotalWidth = (item)=>{

        if(item){
            console.log("findBlockTotalWidth",item)
        }
        

        
        
        const datePrevTwo = new Date(todayDate)
        const dateRangeStartFrom =  new Date(new Date(datePrevTwo.setDate(datePrevTwo.getDate() - 2)).toDateString())
        const checkInDate = new Date(laravelToJsDateFormat(item.start_from))
        console.log("checkInDate", checkInDate, dateRangeStartFrom)
        let checkInTimeDifference = checkInDate.getTime()-dateRangeStartFrom.getTime()
        let checkInDayDifference = Math.round(checkInTimeDifference / (1000 * 3600 * 24));
        // checkInDayDifference +=1;
        let startDayGap = 0 
        if(checkInDayDifference<0){
            startDayGap = 0
        }
        else{
            startDayGap = (Math.abs(checkInDayDifference))+1
        }
        // console.log(item.reservation_owner, "startDayGap" , startDayGap)


        const endingDate = new Date(todayDate)        
        const dateRangeEndTo = new Date(new Date(endingDate.setDate(endingDate.getDate() + 9)).toDateString())
        console.log("dateRangeEndTo", dateRangeEndTo)        
        const checkOutDate = new Date(laravelToJsDateFormat(item.check_out_date))
        let checkOutTimeDiff = dateRangeEndTo.getTime()-checkOutDate.getTime()
        let checkOutDayDiff = Math.round(checkOutTimeDiff / (1000 * 3600 * 24));
        // checkOutDayDiff +=1;
        // console.log("differenceInDay",item.reservation_owner , checkOutDayDiff)
        let endDayGap = 0 
        if(checkOutDayDiff<0){
            endDayGap = 0
            
        }
        else{
            endDayGap = Math.abs(checkOutDayDiff)
        }

        const totalColoredDays =  (startDayGap===0  ? 12 : 13)  -(startDayGap+endDayGap)
        console.log(item.reservation_owner, `startDayGap : ${startDayGap}` ,`endDayGap : ${endDayGap}` ,`totalColoredDays : ${totalColoredDays}`,`checkInDate : ${checkInDate}`,`checkOutDate : ${checkOutDate}` , item )

        if(startDayGap>0 && endDayGap === 0  ){
            console.log("pop-101")
            return (totalColoredDays*blockWidth)-(blockWidth/2); 
        }
        else  if(startDayGap===0 && endDayGap > 0  ){
            console.log("pop-102")
            return (totalColoredDays*blockWidth)-(blockWidth/2); 
        }
        else  if(startDayGap>0 && endDayGap > 0  ){
            console.log("pop-103", (totalColoredDays*blockWidth)-blockWidth)
            return (totalColoredDays*blockWidth)-blockWidth; 
        }

        else  if(startDayGap===0 && endDayGap === 0  ){
            console.log("pop-104")
            return (totalColoredDays*blockWidth); 
        }
      


       
        

        
       
    }
    
    // Shafiq Code // 

    return (
        <Box className='calender-dates'>
            <Grid container>
                <Grid sx={{
                    borderRight: '1px solid #b6c1cd;',
                    width: '25%'
                }} item>
                    <div className='room-calender-details'>
                        <h4>{room.title}</h4>
                        <p>{room.subTitle}</p>
                    </div>
                </Grid>
                <Grid style={{ width: "75%" }}>
                    <div>
                        <Grid sx={{ position: 'relative' }} className='test111' id={room.rowId} container>

                            {
                                [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((item, index) => <Grid className='date-item-lg date-square' onClick={(item) => clickSelect(item, (`${roomIndex + 1}_${index + 1}`), index)} onMouseOver={(item) => OnHover(item, (`${roomIndex + 1}_${index + 1}`))} id={(`${roomIndex + 1}_${index + 1}`)} ref={blockRef.current[(`${roomIndex + 1}_${index + 1}`)]} value={`${index}${item + 2}`} key={index} item lg={1} md={1}>
                                    {/* <span className='add-date-icon'><AddIcon /></span> */}
                                </Grid>)
                            }
                            {
                                reservedRoom && reservedRoom.map((item, index) => <Grid width={`${findBlockTotalWidth(item)}px`} key={index} sx={{ position: 'absolute', opacity: '.7', top: '0', left: `${positionTwo(item)}px`, backgroundColor: `${item.status === "Reserved" ? "#E3963E" : item.status === "Checked In" ? "#228B22" : "#A52A2A"}` }}
                                    onClick={() => overlayMouseOver(index)}
                                    className='date-item-lg date-square overlay' item ><h6>{item.reservation_owner}</h6></Grid>)
                            }
                            {/* {
                                reservedRoom && reservedRoom.map((item, index) => <Grid width="100%" key={index} sx={{ position: 'absolute', opacity: '.7', top: '0', left: `${positionTwo(item)}px`, backgroundColor: `${item.status === "Reserved" ? "#E3963E" : item.status === "Checked In" ? "#228B22" : "#A52A2A"}` }}
                                    onClick={() => overlayMouseOver(index)}
                                    className='date-item-lg date-square overlay' item lg={countTotalDays(item.start_from, item.check_out_date) - handleMinusBlock(countTotalDays(item.start_from, item.check_out_date), index)} md={countTotalDays(item.start_from, item.check_out_date)}><h6>{item.reservation_owner}</h6></Grid>)
                            } */}
                            {/* {
                               reservedRoom && reservedRoom.map((item, index)=> <Grid width="100%" key={index} sx={{position: 'absolute', opacity: '.7', top: '0', left: `${position(index)}%`, backgroundColor:`${item.status==="Reserved" ? "#FFD87D" : item.status==="Checked In" ? "#89D684": "#FAAA7B"}`}} 
                               onClick={()=> overlayMouseOver(index)}
                            className='date-item-lg date-square overlay'item lg={countTotalDays(item.start_from, item.check_out_date) - handleMinusBlock(countTotalDays(item.start_from, item.check_out_date), index)} md={countTotalDays(item.start_from, item.check_out_date)}><h6>{item.reservation_owner}</h6></Grid>)
                            } */}
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        </Box>
    );
};

export default CalenderRoomItem;