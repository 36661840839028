export const currentProperty = (state={}, action) =>{
    switch (action.type){
        case "current_property_update" : return action.payload;
        default : return state;
    }
}
export const currentPropertyId = (state=0, action) =>{
    switch (action.type){
        case "current_property_id_update" : return action.payload;
        default : return state;
    }
}