import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { Box, Button, ButtonGroup, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, ImageListItem, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import InnerPagesTop from '../components/Dashboard/InnerPagesTop';
import '../styles/pages/EditRoom.css';
import BaseUrl from '../utility/ApiBaseUrl';


const CreateRoom = () => {
    const pageInfo = { title: 'Create Your Room', btn1: 'cancel', btn2: 'save' };
    const [bed, setBed] = useState([])
    const [amenities, setAmenities] = useState([])

    const Toast = Swal.mixin({
        toast: true,
        position: 'center',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

    const [inputAmenities, setIntupamenities] = useState([])

    const [form, setForm] = useState({
        room_name: '',
        room_units: 1,
        room_max_guests: 2,
        room_max_kids: '',
        room_bed_type_id: '',
        room_how_many: '',
        room_size: '',
        amenities: [],
        room_photo: '',
        room_description: '',
        room_per_night_price: '',
        errors:[],
    });


    useEffect(()=>{

        axios.get(BaseUrl() +'amenities').then(res =>{
            setAmenities(res.data)
            console.log('api amenities',res.data)
            console.log('api useState amenities',amenities)
        })

        axios.get(BaseUrl() +'bed').then(res =>{
            setBed(res.data)
            console.log('api beds',res.data)
        })

    },[]);

    const [amenitiesData, setAmenitiesData] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const res = await fetch('/json/amenities.json');
            const data = await res.json();
            setAmenitiesData(data);
            console.log("amenities data 101", data)
        }
        fetchData();
    }, []);

     

    const handleChange = (e) => {
        setForm({...form, [e.target.name]: e.target.value })
        // console.log(form)
     }

    //  Amenities data list
    let tem_data = [];

    let unique_data = [];

    const getAmenities = (amenitie) => {
              
        tem_data.push(amenitie.id);

        // Remove duplicates data

        tem_data.forEach((c) => {
            if (!unique_data.includes(c)) {
                unique_data.push(c);
            }
        });

        console.log('test_101', tem_data);
        console.log('unique_data ', unique_data);

        // if(tem_data.length ){
           
        //     let finddata = tem_data.find(item => item === amenitie.id);
           
        //    if(finddata){
        //     alert('yes', finddata)
        //    }
        // }
     }

     const onSubmit = (e) =>{

        let data = new FormData();
        data.append('room_name', form.room_name);
        data.append('room_units', form.room_units);
        data.append('room_max_guests', form.room_max_guests);
        data.append('room_max_kids', form.room_max_kids);
        data.append('room_bed_type_id', form.room_bed_type_id);
        data.append('room_how_many', form.room_how_many);
        data.append('room_size', form.room_size);
        data.append('amenities', unique_data);
        data.append('room_description', form.room_description);
        data.append('room_per_night_price', form.room_per_night_price);
        data.append('room_photo', document.getElementById('file').files[0])


        e.preventDefault();
        console.log(form)
        axios.post(BaseUrl() + 'room', data).then(res =>{
            console.log(res.data);

            if(res.data.msg){
                alert('Success')
            }

        }).catch(e =>{
            alert('error.')
        })
        .finally( f =>{
            
            Toast.fire({
                icon: 'success',
                title: 'Room Added'
              });
        })
     }

    return (
        <div className='edit-room'>
            <InnerPagesTop
                pageInfo={pageInfo}
            ></InnerPagesTop>
            <section>
                <Box className='edit-box'>
                    <Grid
                        container
                        sx={{
                            alignItems: 'flex-start'
                        }}
                        className="room-details-container"
                    >
                        <Grid
                            xs={12}
                            md={3}
                            item
                        >
                            <h2 className="edit-box-title">
                                Room Details
                            </h2>
                            <p className='edit-box-subtitle'>Let’s start with the basics.</p>
                        </Grid>
                        <Grid
                            xs={12}
                            md={7}
                            item
                        >
                            <div>

                                <Grid
                                    container
                                    columnSpacing={2}
                                >
                                    <Grid xs={12} md={8}>
                                        <TextField
                                            required
                                            size="small"
                                            name='room_name'
                                            value={form.room_name}
                                            onChange={handleChange}
                                            id="outlined-required"
                                            label="Name this room"
                                            defaultValue="Family Suite 101"
                                            sx={{
                                                width: '90%',
                                            }}
                                            className="name-this-room full-width-input-field"
                                        />
                                    </Grid>
                                    <Grid xs={12} md={4}>
                                        <TextField
                                            size="small"
                                            name='room_units'
                                            value={form.room_units}
                                            onChange={handleChange}
                                            id="outlined-number"
                                            label="room_units"
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            className="room_units"
                                            sx={{
                                                width: '95%',
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                            <div style={{ marginTop: '20px' }}>
                                <Grid
                                    container
                                    columnSpacing={2}
                                >
                                    <Grid xs={12} md={4}>
                                        <TextField
                                            size="small"
                                            id="outlined-number"
                                            label="Max Guests"
                                            name='room_max_guests'
                                            value={form.room_max_guests}
                                            onChange={handleChange}
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            sx={{
                                                width: '80%',
                                            }}
                                            className="max-guests"
                                        />
                                    </Grid>
                                    <Grid xs={12} md={4}>
                                        <TextField
                                            size="small"
                                            id="outlined-number"
                                            label="Max Kids"
                                            name='room_max_kids'
                                            value={form.room_max_kids}
                                            onChange={handleChange}
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            sx={{
                                                width: '80%',
                                            }}
                                            className="max-kids"
                                        />
                                    </Grid>
                                    <Grid xs={12} md={4}>
                                        <TextField
                                            size="small"
                                            id="outlined-number"
                                            label="Room Size"
                                            type="number"
                                            name='room_size'
                                            value={form.room_size}
                                            onChange={handleChange}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            sx={{
                                                width: '80%',
                                            }}
                                            className="room-size full-width-input-field"
                                            InputProps={{
                                                endAdornment: <InputAdornment position="start"><var>ft<sup>2</sup></var></InputAdornment>,
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid
                            lg={2}
                            md={2}
                            sm={12}
                            item
                        >

                        </Grid>
                    </Grid>
                </Box>
                <Box className='edit-box'>
                    <Grid
                        container
                        sx={{
                            alignItems: 'flex-start'
                        }}
                    >
                        <Grid
                            lg={3}
                            md={3}
                            sm={12}
                            item
                        >
                            <h2 className="edit-box-title">
                                Beds
                            </h2>
                            <p className='edit-box-subtitle'>List all the beds in this room.</p>
                        </Grid>
                        <Grid
                            lg={7}
                            md={7}
                            sm={12}
                            item
                        >
                            <div>

                                <Grid
                                className='bed-row-one'
                                    container
                                    columnSpacing={2}
                                    sx={{
                                        alignItems: "flex-end",
                                        marginBottom: '30px'
                                    }}
                                >
                                    <Grid lg={6} md={6} sm={6}>
                                        <p>Bed Type</p>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Single</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="bed type"
                                                name='room_bed_type_id'
                                                value={form.room_bed_type_id}
                                                onChange={handleChange}
                                                size="small"
                                                sx={{
                                                    width: '90%'
                                                }}
                                            >
                                                <MenuItem value={10}>Single</MenuItem>
                                                <MenuItem value={20}>Queen</MenuItem>
                                                <MenuItem value={30}>King</MenuItem>
                                                <MenuItem value={30}>Twin</MenuItem>
                                                <MenuItem value={30}>Super King</MenuItem>
                                            </Select>
                                        </FormControl>

                                    </Grid>
                                    <Grid lg={3} md={3} sm={3}>
                                        <p>How Many</p>
                                        <TextField
                                            size="small"
                                            id="outlined-number"
                                            type="number"
                                            name='room_how_many'
                                            value={form.room_how_many}
                                            onChange={handleChange}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            sx={{
                                                width: '95%',
                                            }}
                                        />
                                    </Grid>
                                    <Grid lg={2} md={2} sm={2}>
                                        <DeleteOutlinedIcon sx={{
                                            marginLeft: '20px',
                                            cursor: 'pointer'
                                        }} />
                                    </Grid>
                                </Grid>

                            </div>
                        </Grid>
                        <Grid
                            lg={2}
                            md={2}
                            sm={12}
                            item
                        >

                        </Grid>
                    </Grid>
                </Box>
                <Box className='edit-box'>
                    <Grid
                        container
                        sx={{
                            alignItems: 'flex-start'
                        }}
                    >
                        <Grid
                            lg={5}
                            md={5}
                            sm={12}
                            item
                        >
                            <h2 className="edit-box-title">
                            Amenities
                            </h2>
                            <p className='edit-box-subtitle'>Check all the features your room has to offer.</p>
                        </Grid>
                        <Grid
                            lg={7}
                            md={7}
                            sm={12}
                            item
                        >
                            <div>

                             <div style={{display: 'flex', flexWrap: 'wrap', gap: '8px'}}>
                                {amenities.map(item => (
                                        // <label key={item.id}>
                                        //   {item.amenities_name}
                                        //   <Checkbox name={item.amenities_name}  onChange={this.handleChange} />
                                        // </label>
                                        <div key={item.id} style={{display: 'flex', gap: '8px'}} >
                                            <input type="checkbox" onChange={() => getAmenities(item)} id="horns" name="horns"/>
                                            <label for="horns">{item.amenities_name}</label>
                                        </div>

                                        // <FormControlLabel amenitiesval={getAmenities} control={<Checkbox defaultChecked />} label={item.amenities_name} />
                                        // <p>amenities</p>
                                    ))
                                    }
                                </div>

                                <Grid
                                    container
                                >
                                    {/* ameniities new */}
                                    {

                                        <Grid container spacing={1}>
                                            {
                                                amenitiesData.map((item, index) => <Grid key={index} item xs={12} md={4}>
                                                    <FormControlLabel control={<Checkbox defaultChecked />} label={item.label} />
                                                </Grid>)
                                            }
                                        </Grid>
                                    }
                                </Grid>

                                <Grid
                                    container
                                >
                                    <Grid item lg={12} md={12} sm={12}>
                                        <FormGroup sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between'
                                        }}>

                                            <FormControlLabel control={<Checkbox defaultChecked />} label="Air Conditioning" />
                                            <FormControlLabel control={<Checkbox defaultChecked />} label="Internet (Wi-Fi)" />
                                            <FormControlLabel control={<Checkbox defaultChecked />} label="TV" />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12}>
                                        <FormGroup sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between'
                                        }}>
                                            <FormControlLabel control={<Checkbox defaultChecked />} label="Safe" />
                                            <FormControlLabel control={<Checkbox defaultChecked />} label="Minibar" />
                                            <FormControlLabel control={<Checkbox defaultChecked />} label="Shower" />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12}>
                                        <FormGroup sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between'
                                        }}>
                                            <FormControlLabel control={<Checkbox defaultChecked />} label="Kitchen" />
                                            <FormControlLabel control={<Checkbox />} label="Telephone" />
                                            <FormControlLabel control={<Checkbox />} label="Bath" />
                                        </FormGroup>
                                        <p ><button className='more-amenity-btn'>+ More amenities</button></p>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </Box>
                <Box
                    className='edit-box'
                >
                    <Grid
                        container
                        sx={{
                            alignItems: 'flex-start'
                        }}
                    >
                        <Grid
                            lg={5}
                            md={5}
                            sm={12}
                            item
                        >
                            <h2 className="edit-box-title">
                                Photos
                            </h2>
                            <p style={{
                                paddingRight: '40%'
                            }} className='edit-box-subtitle'>Showcase your rooms with high quality photos.</p>
                        </Grid>
                        <Grid
                            lg={7}
                            md={7}
                            sm={12}
                            item
                            sx={{
                                display: 'flex',
                            }}
                        >

                            <ImageListItem>
                                <img
                                    src='/images/img-gallery.jpg'
                                    alt=''
                                    loading="lazy"
                                    className='room-photo'
                                />

                            </ImageListItem>
                            <div> <span className='room-photo add-room-photo'>
                                <AddOutlinedIcon />
                            </span></div>

                            <input  required type="file" id="file" />
                        </Grid>
                    </Grid>
                    <p style={{
                        margin: '0',
                        textAlign: 'end',
                        color: '#9e9898'
                    }}><small>1/10</small></p>
                </Box>
                <Box
                    className='edit-box'
                >
                    <Grid
                        container
                        sx={{
                            alignItems: 'flex-start'
                        }}
                    >
                        <Grid
                            lg={5}
                            md={5}
                            sm={12}
                            item
                        >
                            <h2 className="edit-box-title">
                                Description
                            </h2>
                            <p style={{
                                color: '#3899ec'
                            }}>Get ideas</p>
                            <p style={{
                                paddingRight: '40%'
                            }} className='edit-box-subtitle description-subtitle'>Describe your room. What makes it special?</p>
                        </Grid>
                        <Grid
                            lg={7}
                            md={7}
                            sm={12}
                            item
                            sx={{
                                display: 'flex',
                            }}
                            className='room_description-box-container'
                        >
                            <textarea 
                            name='room_description'
                            value={form.room_description}
                            onChange={handleChange}
                            id="" 
                            style={{
                                width: '100%',
                                borderColor: '#c0b8b8',
                                borderRadius: '5px'
                            }}
                                rows="8"
                            >

                            </textarea>
                        </Grid>
                    </Grid>
                    <p style={{
                        margin: '0',
                        textAlign: 'end',
                        color: '#9e9898'
                    }}><small>0/2500</small></p>
                </Box>
                <Box
                    className='edit-box'
                >
                    <Grid
                        container
                        sx={{
                            alignItems: 'flex-start'
                        }}
                    >
                        <Grid
                            xs={12}
                            md={5}
                            item
                        >
                            <h2 className="edit-box-title">
                                <var style={{ fontStyle: 'normal' }}>Price<sub><small style={{ marginLeft: '10px' }}>(before tax)</small></sub></var>
                            </h2>
                            <p style={{
                                paddingRight: '40%'
                            }} className='edit-box-subtitle room_description-subtitle'>How do you want to charge for this room?</p>
                        </Grid>
                        <Grid
                            xs={12}
                            md={7}
                            item
                            sx={{
                                display: 'flex',
                            }}
                        >
                            <div style={{ width: '100%' }}>
                                <Grid container style={{ width: '100%' }}>
                                    <Grid xs={12} md={4}>
                                        <p>Price per night</p>
                                        <TextField
                                            size="small"
                                            name="room_per_night_price"
                                            value={form.room_per_night_price}
                                            onChange={handleChange}
                                            id="outlined-start-adornment"
                                            sx={{ width: '90%' }}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">€</InputAdornment>,
                                            }}
                                            className='full-width-input-field'
                                        />
                                    </Grid>
                                    
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                    <p style={{
                        margin: '0',
                        textAlign: 'end',
                        color: '#9e9898'
                    }}><small>0/2500</small></p>
                </Box>
                <div>
                    <Grid container className="edit-room-bottom">
                        <Grid xs={12} sm={6} item><p><span style={{ color: '#3899ec' }}>*</span><small> Indicates mandatory fields</small></p></Grid>
                        <Grid className='edit-room-bottom-btns' xs={12} sm={6} item sx={{textAlign: 'end'}}>
                            <ButtonGroup
                                orientation="vertical"
                                aria-label="vertical outlined button group"
                            >
                                <Button
                                    className='room-typy-topbar-btn preview-btn'
                                    sx={{
                                        borderRadius: '20px',
                                        backgroundColor: '#fff',
                                        border: "none",
                                        padding: '6px 28px',
                                        textTransform: 'capitalize',
                                        fontSize: '16px',
                                        color: '#3899ec',
                                        marginRight:'20px'
                                    }}
                                >cancel</Button>
                            </ButtonGroup>
                            <ButtonGroup
                                orientation="vertical"
                                aria-label="vertical contained button group"
                                variant="contained"
                                sx={{ boxShadow: 'none' }}
                            >
                                <Button onClick={onSubmit}
                                    className='room-typy-topbar-btn create-new-btn'
                                    sx={{
                                        borderRadius: '20px',
                                        backgroundColor: '#3899ec',
                                        border: "none",
                                        padding: '6px 28px',
                                        textTransform: 'capitalize',
                                        fontSize: '16px'
                                    }}
                                >
                                    save
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                </div>
            </section>
        </div>
    );
};

export default CreateRoom;