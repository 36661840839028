import { Button, CircularProgress, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from "react";
import { toast } from "react-toastify";
// import CustomAutocomplete from "../../../uiComponents/Autocomplete/CustomAutocomplete";
import responseErrorManage from "../../utility/responseErrorManage";

const axios = require("axios").default;

const CrudCreate = forwardRef(({ handleRefreshDataList, crudBaseUrl }, ref) => {
  // ref
  useImperativeHandle(ref, () => ({
    getItemDetails,
  }));
  // ref

  //                                                                            state mangement
  const [loading, setLoading] = useState(false);
  const [loadingLabel, setLoadingLabel] = useState("");
  // Form state
  const [id, setId] = useState(0);
  const [name, setName] = useState(""); // changeHere - stateName correction
  const [email, setEmail] = useState(""); // changeHere - stateName correction
  const [password, setPassword] = useState(""); // changeHere - stateName correction
  // const [image, setImage] = useState({}); // changeHere - stateName correction

  const [formType, setFormType] = useState("SAVE"); // create - post , put- update , view- details

  const [defaultValue, setDefaultValue] = useState({ label: "", value: "" });

  // image onchange hander
  // const handleChange = (e) => {
  //   console.log(e.target.files[0])
  //   setImage(e.target.files[0])
  // };
  // Form state //
  //                                                                            state mangement //
  // console.log("data from data source state",dataSource);
  //                                                                            handle management
  const handleResetFormData = () => {
    setFormType("SAVE");
    setId(0);
    setName("");
    setEmail("");
    setPassword("");
    
  };
  //                                                                            handle management //
  //                                                                            axios Req
  const getItemDetails = (reqUrl) => {
    setLoadingLabel("loading details...");
    setLoading(true);
    axios(reqUrl)
      .then((response) => {
        setFormType("UPDATE");
        setLoading(false);
        console.log(response.data);
        // mapping data with state
        setId(response.data.id);
        setName(response.data.name); // changeHere - set serverRespnose with localState porperly
        setEmail(response.data.email); // changeHere - set serverRespnose with localState porperly

        setDefaultValue({
          label: response.data.name,
          value: response.data.name,
        }); // changeHere - set serverRespnose with localState porperly

        // mapping data with state //
      })
      .catch((errors) => {
        setLoading(false);
        responseErrorManage(errors);
      });
  };
  const handleSave = () => {
    const data = new FormData();
    
    data.append("name", name);
    data.append("email", email);
    data.append("cin_id", 3000);
    data.append("password", password);

    setLoadingLabel("Saving...");
    setLoading(true);
   
    let config = {};
    if (id > 0) {
      config = {
        method: "PUT",
        url: `${crudBaseUrl}/${id}`,
        // data: { ...data },
        data: data
      };
    } else {
      config = {
        method: "POST",
        url: crudBaseUrl,
        data: data,
        // body: data
      };
    }
    //

    axios(config)
      // .post("/billing-service-head", { ...data })
      .then((response) => {
        setLoading(false);
        if (response.status >= 200 && response.status < 300) {
          toast.success(response.data.msg);
          handleResetFormData();
          handleRefreshDataList();
        }
        console.log(response.data);
      })
      .catch((errors) => {
        setLoading(false);
        responseErrorManage(errors);
      });
  };

  useEffect(() => {
    //
  }, []);
  // axiso Req //
  return (
    <>
      <Box
        sx={{
          my: 2,
          px: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        className="data-management-page-form-container"
      >
        <form className="data-management-page-form" onSubmit={() => handleSave()}>
          
          <TextField
            sx={{ mx: 1 }}
            size="small"
            label="Full Name" // changeHere - textFiledLables
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="data-management-page-form-text-field"
          ></TextField>
          <TextField
            sx={{ mx: 1 }}
            size="small"
            label="User Email" // changeHere - textFiledLables
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="data-management-page-form-text-field"
          ></TextField>
          <TextField
            sx={{ mx: 1 }}
            size="small"
            label="Password" // changeHere - textFiledLables
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="data-management-page-form-text-field"
          ></TextField>

          {/* // */}

          {loading ? (
            // <CircularProgress />
            <Box sx={{ display: "inline-flex", alignItems: "center", px: 5 }}>
              <CircularProgress size={30} sx={{ px: 2 }} />
              {loadingLabel}
            </Box>
          ) : (
            <>
              <Button
                type="submit"
                onClick={() => handleSave()}
                //   sx={{ fontSize: "10px" }}
                color="primary"
                variant="contained"
              >
                {formType}
              </Button>
              {formType === "UPDATE" && (
                <Button
                  onClick={() => handleResetFormData()}
                  sx={{ mx: 1 }}
                  color="primary"
                  // variant="contained"
                >
                  NEW ENTRY
                </Button>
              )}
            </>
          )}
        </form>
      </Box>
    </>
  );
});

export default CrudCreate;
