import { Box, Button, ButtonGroup } from "@mui/material";
import TextField from "@mui/material/TextField";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import CardDataLoader from "../../../uiComponents/CardDataLoader/CardDataLoader";
//

const axios = require("axios").default;

const CrudDataList = forwardRef(({ actionButton = { "delete": true, "edit": true }, localKeyName = "", localItemStrcture = { label: "", value: "", label_2: "" }, dataListSource, columnList, handleEdit }, ref) => {
  useImperativeHandle(ref, () => ({
    getDataList,
  }));
  // const DataList = ({ dataListSource, columnList }) => {
  // state
  const [isLoading, setIsLoading] = useState(true);
  const [dataList, setDataList] = useState([]);
  const [tableFontSize, setTableFontSize] = useState(17);
  const [confirmDeleteId, setConfirmDeleteId] = useState(0);
  // state

  // handle Managemnt

  const handleFontSizeChange = (value) => {
    setTableFontSize(value);
    localStorage.setItem("style_patientList_fontSize", value);
  };

  const handleDelete = (item) => {
    console.log("itemDetel", item, item.id);
    const reqUrl = `${dataListSource}/${item.id}`;
    console.log("req url- ", reqUrl);

    // getOrderListByPatientId(patientIdRedux);
    setIsLoading(true);
    axios
      .delete(reqUrl)
      //   .delete("dentrino-patient-info/" + item.id)
      .then((response) => {
        setIsLoading(false);
        getDataList();
        console.log(response);
        toast.info("ID: " + item.id + " delete success.");
        setConfirmDeleteId(0);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error("Something is wrong");
        console.log(error);
      });
  };
  // handle Managemnt  //

  // dataTable sectins
  const getLocalStyle = () => {
    const localFontSize = localStorage.getItem("style_patientList_fontSize");
    console.log("localFontSize-101", localFontSize, typeof localFontSize);
    typeof localFontSize === "string"
      ? setTableFontSize(localFontSize)
      : setTableFontSize(17);
  };

  const columns = [
    ...columnList,
    actionButton.edit ||   actionButton.delete ? 
    {
      name: "Action",
      selector: (row) => {
        const itemId = row.id;
        return (
          <ButtonGroup variant="outlined" aria-label="outlined button group">
            {confirmDeleteId === row.id ? (
              <>
                <Button
                  onClick={() => handleDelete(row)}
                  sx={{ fontSize: "10px" }}
                  color="error"
                  variant="contained"
                >
                  CONFIRM DEL
                </Button>
                <Button
                  sx={{ fontSize: "10px" }}
                  color="warning"
                  onClick={() => setConfirmDeleteId(0)}
                >
                  CANCEL
                </Button>
              </>
            ) : (
              <>
                {
                  actionButton.edit && <Button
                    onClick={() => handleEdit(row)}
                    color="warning"
                  >
                    EDIT
                  </Button>


                }

                {
                  actionButton.delete && <Button
                    color="error"
                    onClick={() => setConfirmDeleteId(row.id)}
                  >
                    DELTE
                  </Button>
                }

              </>
            )}
          </ButtonGroup>
        );
      },
    } : ""
  ];

  

  const customStyles = {
    rows: {
      style: {
        fontSize: tableFontSize + "px",
        // fontSize: "17px",
        minHeight: "72px", // override the row height
      },
    },
    headCells: {
      style: {
        fontSize: tableFontSize + "px",
        fontWeight: 600,
        // fontSize: "20px",
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };
  // datatable sections

  // axios
  const getDataList = () => {
    setIsLoading(true);
    axios
      .get(dataListSource)
      .then((response) => {
        setIsLoading(false);
        console.log("DATALIST - ", response.data);
        setDataList(response.data);
        storeToLocalCache(response.data)
      })
      .catch((error) => {
        if (error.response.status === 401) {
          toast.error(error.response.data.message);
        }
        console.log(
          "status code - 103 ",
          error.response.status,
          error.response.data.message
        );
        setIsLoading(false);
        console.log("error list - 102 - ", error, typeof error);
      });
  };




  const storeToLocalCache = (dataSource) => {

    const serverResponseData = dataSource;
    const newDataListForAutocomplete = [];

    console.log("1000")
    if (localItemStrcture?.label_2?.length > 1) {
      console.log("more than 1");
    }
    console.log("1000-1", localItemStrcture.label_2.length)
    // setPrescriptionMain

    serverResponseData.map((item) => {
      if (localItemStrcture.label_2.length > 1) {
        newDataListForAutocomplete.push({
          value: item[localItemStrcture.value],
          label: item[localItemStrcture.label],
          label_2: item[localItemStrcture.label_2],
        })
      } else {
        console.log("1002")
        newDataListForAutocomplete.push({
          value: item[localItemStrcture.value],
          label: item[localItemStrcture.label],
        })
      }

    }



    );
    console.log("storeToLocalCache", dataSource, newDataListForAutocomplete)
    localStorage.setItem(
      localKeyName,
      JSON.stringify(newDataListForAutocomplete)
    ); // stringify & set to local storage
  }
  // axios //
  // useEffect
  useEffect(() => {
    getLocalStyle();
    getDataList();
  }, []);
  // useEffect //

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          py: 2,
          px: 2,
        }}
      >
        <TextField
          label="Font Size"
          value={tableFontSize}
          onChange={(e) => handleFontSizeChange(e.target.value)}
          size="small"
          sx={{ width: 70, mx: 2 }}
          type="number"
        />
        <Button
          onClick={() => getDataList()}
          //   sx={{ fontSize: "10px" }}
          color="success"
          variant="contained"
        >
          REFRESSH
        </Button>
      </Box>
      <Box sx={{ position: "relative" }}>
        {isLoading && <CardDataLoader></CardDataLoader>}
        <DataTable
          columns={columns}
          data={dataList}
          direction="auto"
          fixedHeaderScrollHeight="300px"
          highlightOnHover
          pagination
          responsive
          striped
          subHeaderAlign="right"
          subHeaderWrap
          theme="dentrino"
          customStyles={customStyles}
        />
      </Box>
      {/* <Box sx={{ marginTop: 3 }}>
        <TextField
          label="Font Size"
          value={tableFontSize}
          onChange={(e) => handleFontSizeChange(e.target.value)}
          size="small"
          sx={{ width: 70 }}
          type="number"
        />
      </Box> */}
    </>
  );
});

export default CrudDataList;
