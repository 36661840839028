import Divider from "@mui/material/Divider";
import { useState } from "react";
import { AiOutlineSetting } from "react-icons/ai";
import { BiAddToQueue, BiBuildingHouse } from "react-icons/bi";
import { BsCardList, BsListUl } from "react-icons/bs";
import { FaUserCircle } from "react-icons/fa";
import { FiActivity } from "react-icons/fi";
import { GiSpookyHouse } from "react-icons/gi";
import { GoCalendar } from "react-icons/go";
import { ImUsers } from "react-icons/im";
import { MdMeetingRoom } from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";
import { useSelector } from "react-redux";
import MenuLink from "./MenuLink";


const LeftSidebar = () => {
  const authUserPermission = useSelector(state => state.authUserPermission);
  const currentPropertyId = useSelector(state => state.currentPropertyId);

  let menuList = [
    // {
    //   menuLabel: "Page 1",
    //   menuLink: "page-1",
    //   subMenu: [
    //     { subMenuLabel: "Submenu 1", subMenuLink: "page-1/subpage1" },
    //   ],
    // },
    // {
    //   menuLabel: "Page 2",
    //   menuLink: "page-2",
    //   subMenu: [
    //     { subMenuLabel: "Submenu 2", subMenuLink: "page-2/subpage2" },
    //   ],
    // },
    // {
    //   menuLabel: "ROOM Manage",
    //   menuLink: "#",
    //   subMenu: [
    //     { subMenuLabel: "Create ROOM", subMenuLink: "room-create" },
    //     { subMenuLabel: "ROOM LIST", subMenuLink: "room-list" },
    //   ],
    // },
    // { menuLabel: "ROOM LIST", menuLink: "room-types" },
    // { menuLabel: "Edit Rooms", menuLink: "edit-room" },
    /// EDIT FORM HERE
    
    { menuLabel: "Home", menuLink: "reservation-list", icon: <  BsCardList style={{ "color": "#1976D2", "fontSize": "30px" }} /> },
    { menuLabel: "New Reservation", menuLink: "reservation", icon: <  BiAddToQueue style={{ "color": "#1976D2", "fontSize": "30px" }} /> },
    { menuLabel: "Create ROOM", menuLink: "room-create", icon: <MdMeetingRoom style={{ "color": "#1976D2", "fontSize": "30px" }} /> },
    { menuLabel: "ROOM LIST", menuLink: "room-list", icon: <BsListUl style={{ "color": "#1976D2", "fontSize": "30px" }} /> },
    // { menuLabel: "Reservations", menuLink: "calender-rooms", icon: <BsListUl style={{ "color": "#1976D2", "fontSize": "30px" }} /> },
   
    
    // { menuLabel: "Property Settings", menuLink: "property-settings", icon: <  BsBookmarkCheck style={{ "color":"#1976D2", "fontSize": "30px" }} /> },
    // { menuLabel: "Calender Rooms", menuLink: "calender-rooms" },
    // { menuLabel: "User Manage", menuLink: "user-management", icon: <  AiFillEdit style={{ "color":"#1976D2", "fontSize": "30px" }} /> },
    // { menuLabel: "Property List", menuLink: "property-list", icon: <  AiFillEdit style={{ "color":"#1976D2", "fontSize": "30px" }} /> },
    // { menuLabel: "Activity log", menuLink: "activity-log", icon: <  AiFillEdit style={{ "color":"#1976D2", "fontSize": "30px" }} /> },
    // { menuLabel: "Reservation List", menuLink: "reservation-list", icon: <  AiFillEdit style={{ "color":"#1976D2", "fontSize": "30px" }} /> },


    /// EDIT FORM HERE//

  ];


  if (authUserPermission !== "admin") {
    menuList.push(
      { menuLabel: "Property Settings", menuLink: `edit-property/${currentPropertyId}`, icon: <  AiOutlineSetting style={{ "color": "#1976D2", "fontSize": "30px" }} /> },
      { menuLabel: "My Profile", menuLink: "user-profile", icon: <  FaUserCircle style={{ "color": "#1976D2", "fontSize": "30px" }} /> },
      { menuLabel: "Change Password", menuLink: "password-change", icon: <  RiLockPasswordFill style={{ "color": "#1976D2", "fontSize": "30px" }} /> }
    )


  }
  else {
    menuList.push({ menuLabel: "Manager Profile", menuLink: "user-profile", icon: <  FaUserCircle style={{ "color": "#1976D2", "fontSize": "30px" }} /> },)
  }

  const adminMenuList = [

    { menuLabel: "New Property", menuLink: "property-settings", icon: <  BiBuildingHouse style={{ "color": "#1976D2", "fontSize": "30px" }} /> },
    { menuLabel: "Property List", menuLink: "property-list", icon: <  GiSpookyHouse style={{ "color": "#1976D2", "fontSize": "30px" }} /> },
    { menuLabel: "Calender Rooms", menuLink: "calender-rooms", icon: <  GoCalendar style={{ "color": "#1976D2", "fontSize": "30px" }} /> },
    { menuLabel: "User Manage", menuLink: "user-management", icon: <  ImUsers style={{ "color": "#1976D2", "fontSize": "30px" }} /> },
    { menuLabel: "Activity log", menuLink: "activity-log", icon: <  FiActivity style={{ "color": "#1976D2", "fontSize": "30px" }} /> },
    /// EDIT FORM HERE//

  ];


  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
  const [currentToggleIndex, setCurrentToggleIndex] = useState(null);
  const submenuOpen = {
    display: "block",
  };

  const submenuClosed = {
    display: "none",
  };

  const handleSubMenuToggle = (index) => {
    console.log(index);
    setCurrentToggleIndex(index);
    setIsSubmenuOpen(!isSubmenuOpen);
  };



  return (
    <>
      {/* <Divider />
      <List>
        {[{ menuLabel: "Home", menuLink: "dashboard/home" }].map((item, index) => (
          <Link style={{ textDecoration: "none", color: '#595656' }} to={`/${item.menuLink}`} key={index}>
            <ListItem button key={index}>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>

              <ListItemText primary={item.menuLabel} />
            </ListItem>
          </Link>
        ))}
      </List> */}

      <Divider />
      {currentPropertyId > 0 &&  menuList ? <MenuLink menuList={menuList} authUserPermission={authUserPermission} /> : ""}
      
      <Divider />
      {authUserPermission === "admin" ? <MenuLink menuList={adminMenuList} authUserPermission={authUserPermission} /> : ""}

    </>
  );
};

export default LeftSidebar;
