import master from "./master";

const BaseUrl =()=>{

  // return "http://room-management-server.test/api/"
  return `${master()}/api/`
  // return "https://ss.hotel-management.rooberp.com/api/"
  
    
}

export default BaseUrl; 