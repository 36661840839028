import { Button, ButtonGroup, Grid, TextField } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { hidePageSectionLoader, showPageSectionLoader } from '../../redux/actions';
import responseErrorManage from '../../utility/responseErrorManage';
import MuiSectionLoader from '../../utilityComponents/MuiSectionLoader/MuiSectionLoader';

const PasswordChange = () => {
    const userInfo = useSelector(state => state.loggedinUserInfo);
    const [currentPassword, setCurrentPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const dispatch = useDispatch();
   
    const hanleChangePassword = () =>{

        const formDate = {
            "email" :userInfo.email,
            "old_password" : currentPassword,
            "password" : newPassword,
        }
        dispatch(showPageSectionLoader("Password is updating..."))
        axios
        .post("/users/change-password",formDate)
        .then(response=>{
            console.log("CHange Passwrod", response.data)
            // alert("Success")
            toast.success("Password has been successfully updated")
        })
        .catch(errors => {
            responseErrorManage(errors)
            // alert("error")
        })
        .finally(() =>{
            dispatch(hidePageSectionLoader())

        })
    }
  

    return (
        <>
           

                <MuiSectionLoader>

                    <div className='property-settings' style={{ width: '100%' }}>
                        <Box className='edit-box'>
                            <Grid container>
                                <Grid item
                                    xs={12}
                                    md={12}>
                                    <h2 className="edit-box-title">
                                        Password Change
                                    </h2>
                                </Grid>
                                <Grid item
                                    xs={12}
                                    md={4}>
                                    <div style={{ width: "100%", marginTop: '20px' }}>
                                        <Grid container sx={{
                                            alignItems: 'flex-end'
                                        }}>
                                            <Grid item xs={12} md={12}>
                                                <p style={{ margin: '0' }}><span style={{ color: '#3899ec', fontSize: '20px' }}>*</span> Current Password</p>
                                                <TextField
                                                    size="small"
                                                    id="outlined-helperText"
                                                    label=""
                                                    defaultValue="Vila Lako"
                                                    name='name'
                                                    value={currentPassword}
                                                    onChange={(e) => setCurrentPassword(e.target.value)}
                                                    sx={{
                                                        width: '95%'
                                                    }}
                                                    className="full-width-input-field"
                                                    required
                                                    type="password"
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <p style={{ margin: '0' }}>New Password</p>
                                                <TextField
                                                    size="small"
                                                    id="outlined-helperText"
                                                    label=""
                                                    name='email'
                                                    value={newPassword}
                                                    onChange={(e) => setNewPassword(e.target.value)}
                                                    type="password"
                                                    sx={{
                                                        width: '95%'
                                                    }}
                                                    className="settings-phone-number full-width-input-field"
                                                    required
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>
                        </Box>
                        <div>
                            <Grid container className="edit-room-bottom">
                                <Grid xs={12} sm={6} item><p><span style={{ color: '#3899ec' }}>*</span><small> Indicates mandatory fields</small></p></Grid>
                                <Grid className='edit-room-bottom-btns' xs={12} sm={6} item sx={{ textAlign: 'end' }}>
                                    <ButtonGroup
                                        orientation="vertical"
                                        aria-label="vertical outlined button group"
                                    >
                                        <Button
                                            className='room-typy-topbar-btn preview-btn'
                                            sx={{
                                                borderRadius: '20px',
                                                backgroundColor: '#fff',
                                                border: "none",
                                                padding: '6px 28px',
                                                textTransform: 'capitalize',
                                                fontSize: '16px',
                                                color: '#3899ec',
                                                marginRight: '20px'
                                            }}
                                        >cancel</Button>
                                    </ButtonGroup>
                                    <ButtonGroup
                                        orientation="vertical"
                                        aria-label="vertical contained button group"
                                        variant="contained"
                                        sx={{ boxShadow: 'none' }}
                                    >
                                        <Button
                                        onClick={()=>hanleChangePassword()}
                                            className='room-typy-topbar-btn create-new-btn'
                                            sx={{
                                                borderRadius: '20px',
                                                backgroundColor: '#3899ec',
                                                border: "none",
                                                padding: '6px 28px',
                                                textTransform: 'capitalize',
                                                fontSize: '16px'
                                            }}
                                        >
                                            save
                                        </Button>
                                    </ButtonGroup>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </MuiSectionLoader>
           

        </>

    );
};

export default PasswordChange;