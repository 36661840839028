import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
const CardDataLoader = () => {
  return (
    <Box
      sx={{
        bgcolor: "black",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        zIndex: 10,
        top: 0,
        opacity: 0.3,
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default CardDataLoader;
