import { AiOutlineCloseCircle } from "react-icons/ai";
import { Link } from "react-router-dom";

const ReservedPopup = ({ reservedData, setShowReservedPopup }) => {
    console.log("reservedData_101", reservedData)
    // console.log("reservedData_101",reservedData)
    return (
        <div className='reserved-popup'>
            <div className="reserved-popup-container">
                <div style={{ marginBottom: '5px' }} className="close-icon-reserved">
                    <span onClick={() => setShowReservedPopup(false)}><AiOutlineCloseCircle /></span>
                </div>
                <h4 style={{ marginTop: '0', marginBottom: '5px' }}>Client Name: {reservedData.reservation_owner}</h4>
                {/* <h4 style={{marginTop: '0', marginBottom: '5px'}}> {reservedData.reservation_owner}Night(s): {reservedData.reservation_owner}</h4> */}
                <h5 style={{ marginTop: '0', marginBottom: '5px' }}>Total Reservation: {reservedData.totalDays} nights</h5>
                <h5 style={{ marginTop: '0', marginBottom: '5px' }}>Reservation Status: {reservedData.status}</h5>
                <h5 style={{ marginTop: '0', marginBottom: '5px' }}>Reservation info: {reservedData.start_from} - {reservedData.check_out_date}</h5>
                {/* <h5 style={{marginTop: '0', marginBottom: '5px'}}>{reservedData.totalDays} days</h5> */}
                {/* <a href="#" >Reservation Details </a> */}
              
                <Link style={{ textDecoration: 'none' }} to={`/dashboard/edit-reservation/${reservedData.id}`}>
                   Reservation Details
                </Link>
            </div>
        </div>
    );
};

export default ReservedPopup;